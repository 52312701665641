import { loadDeferredPromise } from './load_deferred_promise';

// CSSを遅延させるとややこしいので、事前読み込み
import 'toaster-js/default.scss';

// 遅延側のトーストを呼ぶルーチン

const displayToast = (
  message: string, type: ToastTypeString, time?: number,
  onclick?: () => void
): void => {
  loadDeferredPromise.then(({displayToast}) => {
    displayToast(message, type, time, onclick);
  });
};

export { displayToast };
