// 編集用フォームのマウント

import { onBeforeRenderByController, renderReactRoot } from '../lib/common';
import { CustomerForm } from './react-components/CustomerForm';

onBeforeRenderByController('customers', body => {
  const root = body.querySelector('#customer_input_base');
  if(root) renderReactRoot(
    root as HTMLElement,
    <CustomerForm />
  );
});

if(process.env.NODE_ENV === 'development' && module.hot){
  module.hot.accept();
}
