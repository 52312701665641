import { isIos } from './is_ios';

// 遷移前のハンドリングのベースとなる部分
// Turbolinksに先回りする必要があるので要注意

// 全体でセットしておくハンドラ部分

interface CheckBodyData {
  url: string;
  title: string;
}

// ここに登録されているかどうかで条件分岐させる
// ついでに、state書き戻し用データも保管
const checkingBodies = new WeakMap<HTMLElement, CheckBodyData>();

const MSG = '保存せずに移動しますか？';

addEventListener('beforeunload', e => {
  if(!checkingBodies.has(document.body)) return;
  e.returnValue = MSG;
  return MSG;
}, false);

document.addEventListener('turbolinks:before-visit', e => {
  if(!checkingBodies.has(document.body)) return;
  if(!confirm(MSG)) {
    e.preventDefault();
    e.stopImmediatePropagation();
    return false;
  }
});

// iOSではうまく動作しないので、いったん回避
if(!isIos){
  // packsで仕掛けて、turbolinksより先回りさせる
  addEventListener('popstate', e => {
    const state = e.state;
    if(!state || !state.turbolinks) return;
    const saved = checkingBodies.get(document.body);
    if(!saved) return;
    if(!confirm(MSG)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      // stateの書き戻し
      history.pushState(state, saved.title, saved.url);
      // いったん別の値に書き換えないと、Chromeでうまく書き戻されない
      document.title = '';
      document.title = saved.title;
      return false;
    }
  });
}

const confirmPageTransition = (body: HTMLElement = document.body): void => {
  checkingBodies.set(body, {url: location.href, title: document.title});
};

const unconfirmPageTransition = (body: HTMLElement = document.body): void => {
  checkingBodies.delete(body);
};

export {
  confirmPageTransition,
  unconfirmPageTransition
};
