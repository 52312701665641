// 実際の送信処理を行うHook

import { useContext, useEffect, useState } from 'react';
import { CustomerErrorReplacer } from '../customers/react-components/CustomerForm';
import { useMutableCallback, BodyContext, loadDeferredPromise, isValidForm, makeFormData, RailsFetch, unconfirmPageTransition, turbolinksVisit, setFlash } from '../lib/common';
import { reducer } from './reducer';

const INVALID_MESSAGE = '適切な入力がされていない項目があります。もう一度ご確認ください。';
type MaybeValidator = ((customer: Customer) => CustomerError) | null;

type UseCustomerSenderParams = {
  willSend: Partial<Customer> | null;
  setWillSend: (x: null) => void;
  setSending: (x: boolean) => void;
  customer: Customer;
  errorDispatch: (changes: CustomerError | CustomerErrorReplacer) => void;
  controller?: string;
};

const useCustomerSender = (params: UseCustomerSenderParams): void => {
  const {customer, willSend, errorDispatch, setWillSend, setSending} = params;
  const controller = params.controller || '/customers';
  const isNew = typeof customer.id !== 'number';
  const actionStr = isNew ? '保存' : '更新';
  const mutableErrorDispatch = useMutableCallback(errorDispatch);
  const body = useContext(BodyContext);
  const [validate, setValidate] = useState<MaybeValidator>(null);

  useEffect(() => {
    loadDeferredPromise.then(({validateCustomer}) => {
      setValidate(() => validateCustomer);
    });
  }, []);

  useEffect(() => {
    if(!willSend) return;
    if(!validate) return;
    setWillSend(null);
    // フォーム内にinvalidなもの、requiredだけど入力されていないものがないか探索
    const form: HTMLFormElement | null = document.querySelector('form.main-form');
    if(form) {
      if(!isValidForm(form)){
        const elem: HTMLElement | null = form.querySelector(':invalid, .is-invalid');
        elem && elem.focus();
        return;
      }
    }
    const validated = validate(customer);
    if(Object.keys(validated).length) {
      alert(INVALID_MESSAGE);
      errorDispatch({_replace: true, value: validated});
      if(form) {
        const elem: HTMLElement | null = form.querySelector(':invalid, .is-invalid');
        elem && elem.focus();
      }
      return;
    }
    // ジェブ側が新規作成かつ、アカウント指定がない場合に確認
    if(customer.jeb_coating && !customer.jeb_coating.id && (
      !customer.company_account_ids || customer.company_account_ids.length === 0
    )) {
      if(!confirm('ジェブ側閲覧権限が設定されていません。続けますか？')){
        return;
      }
    }
    const data = makeFormData('customer', reducer(customer, willSend) as {});
    RailsFetch(
      controller + (isNew ? '' : '/' + customer.id) + '.json',
      isNew ?  'post' : 'patch',
      {
        data
      }
    ).then((obj) => {
      // 成功時（更新時も、とりあえずリダイレクトで済ませる）
      const customer = (obj[0] as {customer: Customer}).customer;
      unconfirmPageTransition(body);
      turbolinksVisit(controller + '/' + customer.id + '/edit', {
        replace: true,
        message: actionStr + 'が完了しました。',
        toastType: 'DONE'
      });
      const flash = (obj[0] as {flash_notice: string | null}).flash_notice;
      if(flash) {
        setFlash(flash);
      }
    }, (obj) => {
      const failureMessage = actionStr + 'に失敗しました。';
      if(!obj[0] || !obj[0].customer_errors) {
        alert(failureMessage + '\nサーバエラーが発生したか、接続できませんでした。');
        setSending(false);
        return;
      }
      alert(failureMessage);
      const rawErrors = (obj[0].customer_errors as Partial<Record<keyof CustomerError, string[]>>);
      const errors: CustomerError = {};
      Object.keys(rawErrors).forEach(key => {
        const realKey = key as keyof CustomerError;
        const item = rawErrors[realKey];
        if(item) {
          errors[realKey] = item.join(' ');
        }
      });
      mutableErrorDispatch({_replace: true, value: errors});
      setSending(false);
    });
    setSending(true);
  }, [actionStr, body, customer, errorDispatch, isNew, mutableErrorDispatch, setSending, setWillSend, validate, willSend, controller]);
};

export { useCustomerSender };
