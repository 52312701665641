// ボタンが上下するタイプのラジオボタンリスト

import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react';

import { useUniqueKey, useUncontrollable } from '../lib/common';
import { ButtonRadiosContext } from './ButtonRadiosContext';
import { ButtonRadio } from './ButtonRadio';

type ToggledClassGenerator = (value: string) => string;

interface ButtonRadiosProps {
  list: readonly ButtonRadioItem[];
  value?: string | undefined;
  defaultValue?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  toggledClass?: string | ToggledClassGenerator;
  onChangeValue?(value: string): void;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
}


const RawButtonRadios: FC<ButtonRadiosProps> = ({
  list, value, defaultValue, name, disabled, required, readOnly, toggledClass,
  onChangeValue, onChange
}) => {
  const randomName = useUniqueKey();
  // name指定がない場合に、でたらめなIDのフォームと関連付けて、
  // フォームとしての送信を無効化する
  const dummyForm = useUniqueKey();
  const [focusName, setFocusName] = useState<string | null>(null);
  const [currentValue, setValue] = useUncontrollable(
    value, defaultValue || '', onChangeValue
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if(readOnly) return;
    if(onChange) onChange(e);
    else setValue(e.target.value);
  }, [onChange, readOnly, setValue]);
  const contextValue = useMemo(() => ({
    disabled, required, readOnly, toggledClass,
    form: name ? undefined : dummyForm,
    name: name || randomName,
    setFocusName,
    onChange: handleChange
  }), [disabled, dummyForm, handleChange, name, randomName, readOnly, required, toggledClass]);



  return (
    <ButtonRadiosContext.Provider value={contextValue}>
      <div className="btn-group btn-group-toggle">
        {
          list.map(item => {
            const key = item[0], text = item[1];
            const checked = key === currentValue;
            return(
              <ButtonRadio key={key}
                value={key} text={text} checked={checked}
                focusing={focusName === key}
              />
            );
          })
        }
      </div>
    </ButtonRadiosContext.Provider>
  );
};

RawButtonRadios.defaultProps = {
  toggledClass: 'success'
};

const ButtonRadios = memo(RawButtonRadios);

export { ButtonRadios };

