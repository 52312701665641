

import { ClassFinder } from './ClassFinder';
import { getViewData, onBeforeRenderByController, renderReactRoot } from '../lib/common';


if(process.env.NODE_ENV !== 'production') {
  onBeforeRenderByController('master_records', body => {
    const viewData = getViewData(body);
    // クラスデータの回収
    // （これは一度セットすれば引き回したいので、ここで生成しておくのが最適）
    const classes = viewData.master_classes
      .map(name => ({name, master: true}))
      .concat(viewData.not_master_classes.map(name => ({name, master: false})))
      .sort((a, b) => {
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;
        return 0;
      });

    const initialClass = viewData.initial_class;
    renderReactRoot(
      body.querySelector('#class_finder'),
      <ClassFinder classes={classes}
        initialClass={initialClass}
      />
    );
  });
}

if(process.env.NODE_ENV === 'development' && module.hot){
  module.hot.accept();
}
