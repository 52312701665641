// いまポラスログインなのか判定する

let cachedResult: null | boolean = null;

const isPolusMode = (): boolean => {
  if(cachedResult === null) {
    cachedResult = document.body.classList.contains('mode-user');
  }
  return cachedResult;
};

export { isPolusMode };

