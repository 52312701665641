// body1つに対して、実行が重複しないようにラップを行う関数
// 同じbodyに戻ってくることは考えなくていいので、last1つだけ記録

const onceWithBody = (handler: BodyHandler): BodyHandler => {
  let lastBody: HTMLBodyElement | null = null;
  return (body: HTMLBodyElement): void => {
    if(lastBody === body) return;
    handler(body);
    lastBody = body;
  };
};

export { onceWithBody };
