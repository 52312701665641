// リレーションをソートするヘッダ部分

import { FC, memo } from 'react';
import { useFixedParamCallback } from '../../lib/common';
import { DummyComponent } from '../DummyComponent';

const sortTexts = { asc: '▲', desc: '▼' };

const sortStatus = (sortKey: string, currentSort: string): keyof typeof sortTexts | '' => {
  if(sortKey === currentSort) return 'asc';
  else if (currentSort === '-' + sortKey) return 'desc';
  return '';
};

const sortHeaderStyle = {cursor: 'pointer'};

type SortHeaderComponent = FC<{
  title: string;
  sortKey: string;
  currentSort: string;
  onChangeSort: (sort: string) => void;
}>;

const RawSortHeader: SortHeaderComponent = ({title, sortKey, currentSort, onChangeSort}) => {
  const stat = sortStatus(sortKey, currentSort);
  const newSortKey = (stat === 'asc' ? '-' : '') + sortKey;
  const onClick = useFixedParamCallback(onChangeSort, newSortKey);

  // Hooks定義完了

  return (
    <th style={sortHeaderStyle} onClick={onClick}>
      {title}
      {stat && sortTexts[stat]}
    </th>
  );
};

const SortHeader: SortHeaderComponent = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawSortHeader);

export { SortHeader };
