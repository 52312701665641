// 施工プロセス部分のフォーム

import { FC, memo, useCallback } from 'react';
import { humanColumnName, useKeyValDispatch, defaultValuePicker } from '../../lib/common';
import { FormColumn, FormColumnedInput, FormColumnedTextarea } from '../../react-components';
import { RepairField } from './coating/RepairField';
import { JebEmployeesMultiSelect } from '../../customers_common/jeb/JebEmployeesMultiSelect';
import headingStyles from '../headings.module.scss';
import { headerCoating } from '../header_ids';

const emptyArray: never[] = [];

const sameArray: (x: number[]) => number[] = x => x;

const RawJebCoatingCoating: FC<{
  coating: Jeb.Coating;
  dispatch(changes: Partial<Jeb.Coating>): void;
  setWillSend(changes: Partial<Customer>): void;
}> = ({coating, dispatch, setWillSend}) => {

  const handleChangeIds = useKeyValDispatch(dispatch, 'coating_member_ids', sameArray);
  const handleChangeMemberMemo = useKeyValDispatch(dispatch, 'coating_members_memo', defaultValuePicker);
  const rawIds = coating.coating_member_ids || emptyArray;
  const handleChangeBeforeCoating = useKeyValDispatch(dispatch, 'repair_before_coating', defaultValuePicker);
  const handleChangeAfterCoating = useKeyValDispatch(dispatch, 'repair_after_coating', defaultValuePicker);
  const handleChangeCoatingReport = useKeyValDispatch(dispatch, 'coating_report', defaultValuePicker);
  const handleChangeCoatingArea = useKeyValDispatch(dispatch, 'coating_area', defaultValuePicker);
  const handleClickEntranceConfirm = useCallback(() => {
    if(!confirm('入室確認を送信しますか？')) return;
    setWillSend({jeb_coating: {entrance_confirmation: true}});
  }, [setWillSend]);

  // Hooks定義完了

  return(
    <>
      <h2 className={headingStyles.jebHeading} id={headerCoating}>
        施工プロセス
        <span className="float-right">
          {
            coating.entrance_confirmation ? (
              <small>（入室確認済み）</small>
            ) : (
              <button className="btn btn-success" type="button" onClick={handleClickEntranceConfirm}>
                入室確認を送信
              </button>
            )
          }
        </span>
      </h2>
      <div className="row">
        <FormColumn lg={8} label="施工担当">
          <JebEmployeesMultiSelect
            ids={rawIds}
            onChange={handleChangeIds}
          />
        </FormColumn>
        <FormColumnedInput lg={4}
          label={humanColumnName('Jeb::Coating', 'coating_members_memo')}
          value={coating.coating_members_memo || ''}
          onChange={handleChangeMemberMemo}
          maxLength={250}
        />
      </div>
      <RepairField value={coating.repair_before_coating}
        label={humanColumnName('Jeb::Coating', 'repair_before_coating')}
        onChange={handleChangeBeforeCoating}
      />
      <RepairField value={coating.repair_after_coating}
        label={humanColumnName('Jeb::Coating', 'repair_after_coating')}
        onChange={handleChangeAfterCoating}
      />
      <div className="row">
        <FormColumnedTextarea formLabel={humanColumnName('Jeb::Coating', 'coating_report')}
          value={coating.coating_report || ''}
          onChange={handleChangeCoatingReport}
        />
      </div>
      <div className="row">
        <FormColumnedTextarea formLabel={humanColumnName('Jeb::Coating', 'coating_area')}
          value={coating.coating_area || ''}
          onChange={handleChangeCoatingArea}
        />
      </div>
    </>
  );
};

const JebCoatingCoating = memo(RawJebCoatingCoating);

export { JebCoatingCoating };
