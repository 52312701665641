// メール送信ボタンを含む行

import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CustomerValuesContext } from '../CustomerValuesContext';

const emptyObject = {};

const RawMailSendButtonRow: FC<{
  setWillSend: (changes: Partial<Customer>) => void;
  isNew?: boolean;
  mailId: number;
  mailTypeName: string;
  mailDisabledReason?: string;
}> = ({setWillSend, isNew, mailId, mailTypeName, mailDisabledReason}) => {
  const { sending } = useContext(CustomerValuesContext);
  const [savePushed, setSavePushed] = useState(false);
  const [mailPushed, setMailPushed] = useState(false);
  const handleClickSave = useCallback(() => {
    setWillSend(emptyObject);
    setSavePushed(true);
  }, [setWillSend]);

  const mailValue = useMemo(() => ({jeb_coating: {send_mail_id: mailId}}), [mailId]);
  const handleClickMail = useCallback(() => {
    setWillSend(mailValue);
    setMailPushed(true);
  }, [mailValue, setWillSend]);

  const actionName = isNew ? '保存' : '更新';

  useEffect(() => {
    if(sending) return;
    setSavePushed(false);
    setMailPushed(false);
  }, [sending]);

  // Hooks定義完了


  return(
    <div className="row">
      <div className="col-12 col-md-4 mb-3">
        <button type="button" className="btn btn-primary btn-block btn-lg"
          onClick={handleClickSave} disabled={sending}
        >
          {
            (sending && savePushed) ? '送信中…' : (actionName + 'のみ')
          }
        </button>
      </div>
      <div className="col-12 col-md-8 mb-3">
        <button type="button" className="btn btn-success btn-block btn-lg"
          onClick={handleClickMail} disabled={sending || !!mailDisabledReason}
        >
          {
            (sending && mailPushed) ? '送信中…' : (actionName + 'して' + mailTypeName + 'をメール送信')
          }
        </button>
        {
          mailDisabledReason && (
            <p className="form-text text-danger">
              { mailDisabledReason }
            </p>
          )
        }

      </div>
    </div>
  );
};

const MailSendButtonRow = memo(RawMailSendButtonRow);

export { MailSendButtonRow };
