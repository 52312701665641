// 日付入力とカラムの統合

import { FC, memo, ReactNode } from 'react';
import { CustomDatePicker, CustomDatePickerProps } from './CustomDatePicker';
import { ColProps } from 'react-bootstrap/Col';
import { useUniqueKey } from '../lib/common';
import classNames from 'classnames';
import { FormColumn } from './FormColumn';

interface FormColumnedDatePickerProps extends ColProps, CustomDatePickerProps{
  label?: ReactNode;
  error?: string | null;
}

const RawFoemColumnedDatePicker: FC<FormColumnedDatePickerProps> = props => {
  const {xs, sm, md, lg, xl, label, error, ...inputProps} = props;
  const uniqueId = useUniqueKey();
  const realId = props.id || uniqueId;
  const realClass = classNames(
    'form-control', props.className, error && 'is-invalid'
  );

  // Hooks定義完了

  return(
    <FormColumn
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} label={label}
      controlId={realId}
    >
      <CustomDatePicker {...inputProps} className={realClass} id={realId} />
      {
        error && (
          <div className="invalid-feedback">
            {error}
          </div>
        )
      }
    </FormColumn>
  );
};

const FormColumnedDatePicker = memo(RawFoemColumnedDatePicker);

export { FormColumnedDatePicker };
