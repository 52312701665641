// view-dataの回収を行うHook

import { useContext } from 'react';
import { BodyContext } from './BodyContext';
import { getViewData } from './get_view_data';

const useViewData = (): {} | null => {
  const body = useContext(BodyContext);
  // まずないとは思うけど念の為
  if(!body) return null;
  return getViewData(body);
};

export { useViewData };
