import { masterValues } from './master_values';

// 都道府県の接頭辞とマッチする正規表現
// 初期ロードの時に処理を省くため、関数を呼べば生成する形にしておく

let cached: RegExp | null = null;

const getPrefPrefixRegExp = (): RegExp => {
  if(!cached) {
    const regex = cached = new RegExp(
      '^(?:' + masterValues('Pref').map(item => item.name).join('|') + ')'
    );
    return regex;
  }
  return cached;
};

export { getPrefPrefixRegExp };
