// React外で非同期実行するタイマー関数のため、
// コールバックを保持するHook
// 返り値を使う用途は考えづらい&型が合わなくなるので廃止

import { useCallback, useEffect, useRef } from 'react';

const useMutableCallback = <T extends (...args: never[]) => unknown>(
  func: T
): (...args: Parameters<T>) => void => {
  const ref = useRef<T | null>(null);
  ref.current = func;

  // アンマウント後は止める
  useEffect(() => (): void => {
    ref.current = null;
  }, [ref]);

  const callback = useCallback<(...args: Parameters<T>) => void>((...args) => {
    const currentFunc = ref.current;
    if(!currentFunc) return;
    currentFunc(...args);
  }, []);

  return callback;
};

export { useMutableCallback };
