// ヘッダーのIDリスト

const headerBaseInformation = 'header_base_info';
const headerPreEstimate = 'header_pre_estimate';
const headerEstimate = 'header_estimate';
const headerBusinessTalk = 'header_business_talk';
const headerOrder = 'header_order';
const headerCoating = 'header_coating';


export{
  headerBaseInformation,
  headerPreEstimate,
  headerEstimate,
  headerBusinessTalk,
  headerOrder,
  headerCoating
};
