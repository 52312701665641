// テーブル表示の1行単位のコンポーネント

import { memo } from 'react';
import { RelatedTableButton } from './RelatedTableButton';
import { WbrByUnderscore } from './WbrByUnderscore';
import { DummyComponent } from './DummyComponent';

const columnClass = column => column.null ? '' : 'not-null';

const RawColumnRow = ({column, index, reflection, onRequestTable, withHumanName}) => (
  <tr className={columnClass(column)}>
    <td>
      <WbrByUnderscore text={column.name} />
      {
        reflection &&
          <RelatedTableButton tableName={reflection.class_name}
            onRequestTable={onRequestTable}
          />
      }
    </td>
    {
      withHumanName && <td>{column.human_name}</td>
    }
    <td>{column.sql_type}</td>
    <td>{
      index && (
        index.unique ? 'UNIQUE' : 'INDEX'
      )
    }</td>
    <td>{column.default + ''}</td>
    <td>{column.null ? 'yes' : 'NO'}</td>
  </tr>
);

const ColumnRow = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawColumnRow);

export { ColumnRow };
