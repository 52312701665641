// 顧客情報の検索用フォーム

import { ChangeEvent, FC, KeyboardEvent, useReducer } from 'react';
import { useViewData, turbolinksVisit, useUniqueKey, useKeyValDispatch, masterValues, defaultValuePicker, generateSearchQuery } from '../../lib/common';
import { CustomerFreeInput } from './search/CustomerFreeInput';
import { Options, CustomDatePicker } from '../../react-components';

type Q = {
  [x: string]: string | null | undefined;
};

const reducer: (store: Q, changes: Q) => Q = (store, changes) => {
  // いったんマージだけ
  return {...store, ...changes};
};

const rawProcesses = masterValues('Jeb::Process');
const processList = ([['', '']] as DropdownList).concat(
  rawProcesses.filter(item => !item.deprecated).map(item => [item.id + '', item.name])
);

const pickCheckbox = (e: ChangeEvent<HTMLInputElement>): string => e.target.checked ? '1' : '';

const CustomerSearchForm: FC = () => {
  const defaultQ = (useViewData() as {q: {[x: string]: string}}).q;
  const [q, dispatch] = useReducer(reducer, defaultQ);

  const withAccountId = useUniqueKey();
  const processId = useUniqueKey();
  const handleChangeWithAccount = useKeyValDispatch(dispatch, 'with_customer_account', pickCheckbox);
  const handleChangeProcessId =
    useKeyValDispatch(dispatch, 'jeb_coating_jeb_process_id_eq', defaultValuePicker);
  const handleChangeStartDate =
    useKeyValDispatch(dispatch, 'jeb_coating_order_coating_date_gteq', defaultValuePicker);
  const handleChangeEndDate =
    useKeyValDispatch(dispatch, 'jeb_coating_order_coating_date_lteq', defaultValuePicker);

  // Hooks定義完了

  // なにか変われば変化するので、キャッシュは無意味
  const searchUrl = ((): string => {
    const base = '/customers';
    const query = generateSearchQuery(q);
    return query ? (base + '?' + query) : base;
  })();
  const handleKeydown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if(e.key !== 'Enter') return;
    turbolinksVisit(searchUrl);
  };

  return(
    <>
      <CustomerFreeInput
        dispatch={dispatch} q={q} onKeyDown={handleKeydown}
      />
      <div className="row mb-3">
        <label className="col-12 col-sm-3 col-md-2 form-label">
          施工日
        </label>
        <div className="col-12 col-sm-9 form-inline">
          <CustomDatePicker
            value={q.jeb_coating_order_coating_date_gteq || ''}
            onChange={handleChangeStartDate}
          />
          {' から '}
          <CustomDatePicker
            value={q.jeb_coating_order_coating_date_lteq || ''}
            onChange={handleChangeEndDate}
          /> まで
        </div>
      </div>
      <div className="form-group row">
        <label className="col-12 col-sm-3 col-md-2 form-label" htmlFor={processId}>
          プロセス
        </label>
        <div className="col-12 col-sm-9 col-md-4">
          <select className="form-control" id={processId}
            value={q.jeb_coating_jeb_process_id_eq || ''}
            onChange={handleChangeProcessId}
          >
            <Options list={processList} />
          </select>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="form-check">
            <input type="checkbox" className="form-check-input"
              checked={!!q.with_customer_account}
              id={withAccountId}
              onChange={handleChangeWithAccount}
            />
            <label className="form-check-label" htmlFor={withAccountId}>
              情報共有中の案件のみ表示
            </label>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 mb-3">
          <a href={searchUrl} className="btn btn-primary btn-lg btn-block">
            検索する
          </a>
        </div>
      </div>
    </>
  );
};

export { CustomerSearchForm };
