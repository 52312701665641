// 適切にカスタマイズを施したDatePickerの生成
// （本体は遅延側に追い出し）

import { ComponentType, FC, lazy, memo, Suspense } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePickerStyle.scss';
import { loadDeferredPromise } from '../lib/common';


export interface CustomDatePickerProps{
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: Date | string | null;
  defaultValue?: Date | string;
  className?: string;
  onChange?(dateString: string, date: Date): void;
  id?: string;
}

let deferredDatePicker: ComponentType<CustomDatePickerProps> | null = null;

loadDeferredPromise.then(({CustomDatePicker}) => deferredDatePicker = CustomDatePicker);

const LazyDatePicker = lazy(
  () => loadDeferredPromise.then(({CustomDatePicker}) => ({default: CustomDatePicker}))
);

const RawCustomDatePicker: FC<CustomDatePickerProps> = (props) => {
  const RealComponent = deferredDatePicker || LazyDatePicker;

  return(
    <Suspense fallback={
      <div>
        <input type="text" className="form-control" disabled
          placeholder="ロード中…"
        />
      </div>
    }>
      <RealComponent {...props} />
    </Suspense>
  );
};

const CustomDatePicker = memo(RawCustomDatePicker);

export { CustomDatePicker };
