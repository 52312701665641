// coating_estimate_areaの配列から合計面積を求める

import { WeakCacher, roundByFraction } from '../lib/common';

// 同じ配列で何度も計算する例があるので、こちらでキャッシュ

const cacher = new WeakCacher<readonly CoatingEstimateArea[], number>();

const calculateTotalArea = (areas?: readonly CoatingEstimateArea[]): number => {
  if(!areas) return 0;
  return cacher.fetch(areas, () => roundByFraction(
    areas.reduce(
      (sum, area) => area._destroy ? sum : sum + +(area.area || 0),
      0
    )
  ));
};
export { calculateTotalArea };
