// WeakMapを使ったキャッシュルーチン

export default class WeakCacher<K extends object, V>{
  map: WeakMap<K, V>;

  constructor(){
    this.map = new WeakMap<K, V>();
  }

  fetch(key: K, callback: () => V): V{
    if(!this.map.has(key)) {
      const value = callback();
      this.map.set(key, value);
      return value;
    }
    // 直前にhasでチェックしているので問題なし
    return this.map.get(key) as V;
  }

  get(key: K): V | undefined {
    return this.map.get(key);
  }

  set(key: K, value: V): void {
    this.map.set(key, value);
  }

  has(key: K): boolean {
    return this.map.has(key);
  }
}
