// パソコン用の幅広いリスト

import { FC } from 'react';
import { CustomerInList } from './customer_with_coating_date';
import { WideRow } from './WideRow';
import { SortLink } from './SortLink';

const WideList: FC<{items: CustomerInList[]}> = ({items}) => {
  // Hooks定義完了

  return(
    <table className="table">
      <tbody>
        <tr>
          <th>
            <SortLink title="プロセス" sortKey="jeb_coating_jeb_process_id" />
          </th>
          <th>
            <SortLink title="氏名" sortKey="name" />
          </th>
          <th>
            <SortLink title="施工先" sortKey="new_address" />
          </th>
          <th>
            <SortLink title="電話番号" sortKey="telephone1" />
          </th>
          <th>
            <SortLink title="施工日" sortKey="jeb_coating_order_coating_date" />
          </th>
          <th>依頼業者</th>
          <th>情報共有</th>
        </tr>
        {
          items.map(item => (
            <WideRow customer={item} key={item.id} />
          ))
        }
      </tbody>
    </table>
  );
};

export { WideList };
