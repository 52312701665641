// グリッド幅をチェックして、その条件と一致するか出力するHooks

import { useEffect, useState } from 'react';
import { currentWidth } from './current_width';
import { onChangeGrid } from './on_change_grid';

type WidthPredicateFunction = (width: WidthName) => boolean;
type WidthPredicate = WidthName | readonly WidthName[] | WidthPredicateFunction;

const judge = (predicate: WidthPredicate, width: WidthName): boolean => {
  if(Array.isArray(predicate)) {
    return predicate.indexOf(width) !== -1;
  }
  if(typeof predicate === 'string') {
    return predicate === width;
  }
  // なぜかArray.isArrayをきれいに認識しないので型を補う
  return (predicate as WidthPredicateFunction)(width);
};

const useGridCondition = (
  predicateValue: WidthPredicate
): boolean => {
  const [isCondition, setIsCondition] = useState(
    judge(predicateValue, currentWidth(true))
  );
  useEffect(() => {
    let oldIsCondition = judge(predicateValue, currentWidth(true));
    return onChangeGrid(grid => {
      const newIsCondition = judge(predicateValue, grid);
      if(newIsCondition !== oldIsCondition) {
        setIsCondition(newIsCondition);
        oldIsCondition = newIsCondition;
      }
    });
  }, [predicateValue]);
  return isCondition;
};

export { useGridCondition };

