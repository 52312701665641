// 「関数(引数1つ)」を呼ぶだけ、というコールバックを
// 簡潔に生成するためのHook

import { useCallback } from 'react';

const useFixedParamCallback = <P, R>(
  func: (param: P) => R, param: P
): () => R => {
  return useCallback((): R => func(param), [func, param]);
};

export { useFixedParamCallback };
