// ページ内リンクの処理

import { delegate, closest } from '../lib/common';

delegate(document, 'a[href^="#"]', 'click', e => {
  const link = closest(e.target as Element, 'a') as HTMLAnchorElement;
  // ここはAttributeでないとハッシュだけが取れない
  const href = link.getAttribute('href');
  if (href === '#' || !href) {
    return;
  }
  location.href = href;
  /* ジャンプ実装がまだなので保留
    // 飛び先オブジェクトを探す
    const target = document.querySelector(href);
    if(!target) return;
    jebJump(target);
  */
  e.preventDefault();
  e.stopImmediatePropagation();
  return false;
});
