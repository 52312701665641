import { breakpoints } from './grid_widths';
import { currentWidth } from './current_width';

// bootstrapの5段階の切り替わりの時にコールバックを呼ぶ
// 使い方の変化を踏まえて、グローバルにセット・リセットするもののみ用意

type WidthHandler = (widthName: WidthName) => void;

const events = new Set<WidthHandler>();

const onChangeGrid = (listener: WidthHandler): void => {
  events.add(listener);
};

const RemoveChangeGrid = (listener: WidthHandler): void => {
  events.delete(listener);
};

const onChangeQuery = () => {
  const width = currentWidth(true);
  events.forEach(func => func(width));
};

const queries = breakpoints.map(width => `(max-width: ${width}px)`);

queries.forEach(query => {
  matchMedia(query).addListener(onChangeQuery);
});


export { onChangeGrid, RemoveChangeGrid };
