// マスターから値を取得する関数

interface MasterValuesFunc{
  (className: string): readonly ModelBase[];
  (className: 'CoatingEstimateDetailType'): readonly CoatingEstimateDetailType[];
  (className: 'CoatingEstimateType'): readonly CoatingEstimateType[];
  (className: 'CoatingEstimateUnit'): readonly CoatingEstimateUnit[];
  (className: 'CustomerAcceptanceType'): readonly CustomerAcceptanceType[];
  (className: 'Jeb::Process'): readonly Jeb.Process[];
  (className: 'Jeb::TelephoneResult'): readonly Jeb.TelephoneResult[];
  (className: 'Jeb::AppointmentPlace'): readonly Jeb.AppointmentPlace[];
  (className: 'Jeb::BusinessTalkResult'): readonly Jeb.BusinessTalkResult[];
  (className: 'jeb::Role'): readonly Jeb.Role[];
  (className: 'OrderType'): readonly OrderType[];
  (className: 'Pref'): readonly Pref[];
}

const masterValues: MasterValuesFunc = (className: string) => {
  return window._rV.dv[className] as never[];
};

export { masterValues };
