// viewDataを回収するルーチン

import WeakCacher from './weak_cacher';

// 同じビューでは同じオブジェクトを返すようにキャッシュ
const cache = new WeakCacher<HTMLElement, {} | null>();

const getViewData = (body: HTMLElement = document.body): {} | null =>{
  return cache.fetch(body, () => {
    const elem: HTMLInputElement | null = body.querySelector('input[data-view-data]');
    if(!elem) return null;
    return JSON.parse(elem.dataset.viewData || '');
  });
};

export { getViewData };
