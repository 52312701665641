// React内のエラーを記録するコンポーネント
// componentDidCatchが必要なので、クラスコンポーネントとして書く

import { Component, ReactNode } from 'react';


// 1回出ればそれで十分、ということで、全体を通じての単純なフラグ管理に
let alertShown = false;

// childrenが一定しない以上、PureComponent化はほぼ無意味
export default class ErrorLogger extends Component{
  constructor(props: {}) {
    super(props);
  }

  componentDidCatch(error: Error): void {
    // とりあえずalertする
    // TODO: システム経由での通知機能…？
    if(!alertShown){
      alertShown = true;
      alert(
        'エラーが発生しました。' +
        `発生場所や状況などをジェブのシステムチームまでご連絡いただけましたら幸いです。\n${error}`
      );
    }
    console.log(error); // eslint-disable-line no-console
  }

  render(): ReactNode {
    return this.props.children;
  }
}
