// 「トップに戻る」ボタンの設置

import { icon as faIcon } from '@fortawesome/fontawesome-svg-core';
import {faEject} from '@fortawesome/free-solid-svg-icons';
import { onRender, scrollAnimate, makeThrottleByFrame } from '../lib/common';
import styles from './to_top_style.module.scss';

const icon = faIcon(
  faEject,
  { classes: 'fa-3x' }
);

const BUTTON_ID = styles.toTop;

const button = document.createElement('span');
button.id = BUTTON_ID;
button.appendChild(icon.node[0]);

const SHOWN = styles.shown;

const setButtonToBody = (): HTMLSpanElement => {
  const alreadyExists = document.getElementById(BUTTON_ID);
  if(alreadyExists) return alreadyExists as HTMLSpanElement;
  const cloned = button.cloneNode(true) as HTMLSpanElement;
  document.body.appendChild(cloned);
  return cloned;
};

onRender(() => {
  const elem = setButtonToBody();
  elem.addEventListener('click', () => {
    scrollAnimate(0);
  }, false);
});

const handler = makeThrottleByFrame(() => {
  const btn = document.getElementById(styles.toTop);
  if(!btn) return;
  const currentShown = btn.classList.contains(SHOWN);
  if(pageYOffset > 400) {
    if(currentShown) return;
    // 先にdisplayを動かす→1フレームしてからtransition
    btn.style.display = 'block';
    requestAnimationFrame(() => {
      btn.classList.add(SHOWN);
    });
  } else {
    if(!currentShown) return;
    const onEnd: () => void = () => {
      // 消さないと透明なままタップできてしまう
      btn.style.display = 'none';
      btn.removeEventListener('transitionend', onEnd, false);
    };
    btn.classList.remove(SHOWN);
    btn.addEventListener('transitionend', onEnd, false);
  }
});

window.addEventListener('scroll', handler, false);
window.addEventListener('resize', handler, false);
