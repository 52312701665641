// ジェブ担当者のリスト
// 変更後はページ遷移からの再ロードを行うので、内部での値の更新は考えなくていい

import { promisedComponent, loadDeferredPromise } from '../../../lib/common';

const modalPromise = loadDeferredPromise.then(({JebEmployeeModal}) => ({default: JebEmployeeModal}));

const LazyEmployeeModal = promisedComponent(
  () => modalPromise
);

export { LazyEmployeeModal };
