// ヘルプのアイコン部分

import { FC, memo } from 'react';

import { MemoizedIcon } from '../lib/common';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './help-popup.module.scss';

const RawHelpPopupIcon: FC = () => {
  // Hooks定義完了

  return(
    <span className={styles.helpPopup}>
      <MemoizedIcon icon={faQuestionCircle} />
    </span>
  );
};

const HelpPopupIcon = memo(RawHelpPopupIcon);

export { HelpPopupIcon };
