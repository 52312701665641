// Bootstrapでの幅を返す関数

let width = -1;

import {nameFromWidth} from './grid_widths';

// リサイズしない限りキャッシュする
const callback: () => void = () => {
  width = -1;
  window.removeEventListener('resize', callback, false);
  window.removeEventListener('orientationchange', callback, false);
};

const currentWidth = (force?: boolean): WidthName => {
  if(width < 0 || force){
    width = window.innerWidth;
    // addEventListenerは、同じものを複数回入れても1つしか認識しない
    window.addEventListener('resize', callback, false);
    window.addEventListener('orientationchange', callback, false);
  }
  return nameFromWidth(width);
};

export { currentWidth };
