// ヘルプ用ポップアップの表示（本体は遅延）

import { FC, lazy, memo, ReactChild, Suspense } from 'react';
import { loadDeferredPromise } from '../lib/common';
import { HelpPopupIcon } from './HelpPopupIcon';
import { Placement } from 'react-bootstrap/Overlay';

const LazyHelpPopup = lazy(
  () => loadDeferredPromise.then(({HelpPopup}) => ({default: HelpPopup}))
);

const fallback = <HelpPopupIcon />;

const RawHelpPopup: FC<{
  text: ReactChild;
  placement?: Placement;
}> = (props) => {
  // Hooks定義完了
  return(
    <Suspense fallback={fallback}>
      <LazyHelpPopup {...props} />
    </Suspense>
  );
};

const HelpPopup = memo(RawHelpPopup);

export { HelpPopup };
