// ジェブ用データ部分のフォーム

import { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import { masterValues, humanColumnName, useKeyValDispatch, useViewData, useFixedParamCallback } from '../../../lib/common';
// import { JebTelephoneForm } from './JebTelephoneForm';
import { FormColumn, FormColumnedSelect } from '../../../react-components';
// import { JebPreEstimateProgresses } from '../../../customers_common/jeb/JebPreEstimateProgresses';
import { JebFiles } from '../../../customers_common/jeb/JebFiles';
// import { JebEstimate } from '../../../customers_common/jeb/JebEstimate';
// import { JebBusinessTalks } from '../../../customers_common/jeb/JebBusinessTalks';
import { JebOrderForm } from '../../../customers_common/jeb/JebOrderForm';
import { JebEmployeesMultiSelect } from '../../../customers_common/jeb/JebEmployeesMultiSelect';
import { JebCoatingCoating } from '../../../customers_common/jeb/JebCoatingCoating';
import { MailSendButtonRow } from '../../../customers_common/jeb/MailSendButtonRow';
import { JebRepairs } from '../../../customers_common/jeb/JebRepairs';
// import { CustomerSendButtonRow } from '../../../customers_common/CustomerSendButtonRow';
// import { headerEstimate } from '../../../customers_common/header_ids';
// import headingStyles from '../../../customers_common/headings.module.scss';
// import { CoatingEstimateSets } from '../CoatingEstimateSets';

const rawProcesses = masterValues('Jeb::Process');
const eventToNumber: (e: ChangeEvent<HTMLSelectElement|HTMLInputElement>) => number = e => +e.target.value;

const sameArray: (x: number[]) => number[] = x => x;

const emptyArray: never[] = [];
const overflowHidden = {overflow: 'hidden'};
const clearCompanyAccountValue = { company_account_ids: emptyArray };

const RawJebCoatingForm: FC<{
  jebCoating: Jeb.Coating;
  dispatch(changes: Partial<Customer>): void;
  errors: CustomerError;
  companyAccountIds?: number[];
  setWillSend(changes: Partial<Customer>): void;
  coatingEstimateSets?: CoatingEstimateSet[];
}> = ({jebCoating, dispatch, companyAccountIds, setWillSend}) => {
  const jebCoatingDispatch =
    useCallback<(changes: Partial<Jeb.Coating>) => void>((changes) => {
      dispatch({jeb_coating: changes});
    }, [dispatch]);

  const handleChangeProcess = useKeyValDispatch(jebCoatingDispatch, 'jeb_process_id', eventToNumber);
  const handleChangeCompanyAccountIds = useKeyValDispatch(dispatch, 'company_account_ids', sameArray);
  const isNew = !((useViewData() as {customer: Customer}).customer.id);
  const viewData = useViewData() as {
    customer: Customer;
  };
  const initialProcessId = viewData.customer.jeb_coating?.jeb_process_id;
  const availableProcesses: DropdownList = useMemo(() => rawProcesses.filter(
    item => !item.deprecated || item.id === initialProcessId
  ).map(item => [item.id + '', item.name]),
  [initialProcessId]
  );
  const handleClearCompanyIds = useFixedParamCallback(dispatch, clearCompanyAccountValue);
  // const isNarrow = useIsXsSm();

  // Hooks定義完了
  const noCompanyAccount = (!companyAccountIds || companyAccountIds.length === 0);
  const noCompanyAccountError =
    noCompanyAccount ? '「閲覧権限」でジェブアカウントを設定してください。' : '';

  /*
  const estimateMailError = jebCoating.mail_estimate_file_id ? '' :
    '送信する見積書を選択してください（なお、一度保存してからしか選択できません）。';
  */
  const orderMailError = !jebCoating.order ? '依頼プロセスを入力してください。' : (
    jebCoating.order.files && jebCoating.order.files.some(file => file.newId)
  ) ? 'ファイルが新規登録されています。いったん保存してからメール送信を行ってください。' : '';

  return(
    <>
      <h2 style={overflowHidden}>
        ジェブ案件のデータ
        {
          noCompanyAccount || (
            <button className="btn btn-danger float-right" type="button"
              onClick={handleClearCompanyIds}
            >
              閲覧権限をクリア
            </button>
          )
        }
      </h2>
      <div className="row">
        <FormColumnedSelect list={availableProcesses} md={4}
          label={humanColumnName('Jeb::Coating', 'jeb_process_id')}
          value={jebCoating.jeb_process_id || ''}
          onChange={handleChangeProcess}
        />
        <FormColumn md={8} label="閲覧権限">
          <JebEmployeesMultiSelect useCompanyAccountId
            ids={companyAccountIds || emptyArray}
            onChange={handleChangeCompanyAccountIds}
            initialIds={viewData.customer.company_account_ids}
          />
        </FormColumn>
      </div>
      {
        /*
          いったん止める
          <JebFiles files={jebCoating.files || emptyArray}
            dispatch={jebCoatingDispatch} typeId={1}
          />
          <JebTelephoneForm jebCoating={jebCoating}
            jebCoatingDispatch={jebCoatingDispatch}
            errors={errors}
          />
          <JebPreEstimateProgresses
            progresses={jebCoating.pre_estimate_progresses || emptyArray}
            jebCoatingDispatch={jebCoatingDispatch}
            memo={jebCoating.pre_estimate_memo}
          />
          <CustomerSendButtonRow setWillSend={setWillSend}
            isNew={isNew}
          />
          <h2 className={headingStyles.polusHeading} id={headerEstimate}>
            見積プロセス
          </h2>
          {
            isNarrow ? (
              <p>※ 見積書の作成は、パソコンやタブレットなどから行ってください。</p>
            ) : (
              <CoatingEstimateSets
                sets={coatingEstimateSets || emptyArray}
                dispatch={dispatch}
                setWillSend={setWillSend}
              />
            )
          }
          <h3>見積書ファイル</h3>
          <JebEstimate
            estimateFiles={jebCoating.estimate_files || emptyArray}
            dispatch={jebCoatingDispatch}
            mailEstimateFileId={jebCoating.mail_estimate_file_id}
          />
          <MailSendButtonRow setWillSend={setWillSend}
            mailDisabledReason={estimateMailError || noCompanyAccountError}
            mailId={300} mailTypeName="見積書"
            isNew={isNew}
          />
          <JebBusinessTalks
            talks={jebCoating.business_talks || emptyArray}
            resultId={jebCoating.jeb_business_talk_result_id}
            memo={jebCoating.business_talk_memo}
            jebCoatingDispatch={jebCoatingDispatch}
          />
          <JebFiles files={jebCoating.files || emptyArray}
            dispatch={jebCoatingDispatch} typeId={300}
          />
        */
      }
      <JebOrderForm
        order={jebCoating.order}
        dispatch={jebCoatingDispatch}
      />
      <MailSendButtonRow setWillSend={setWillSend}
        mailDisabledReason={orderMailError || noCompanyAccountError}
        mailId={500} mailTypeName="施工依頼"
        isNew={isNew}
      />
      <JebCoatingCoating
        coating={jebCoating}
        dispatch={jebCoatingDispatch}
        setWillSend={setWillSend}
      />
      <JebFiles files={jebCoating.files || emptyArray}
        dispatch={jebCoatingDispatch} typeId={700}
        title="施工画像などのアップロード"
      />
      <JebRepairs repairs={jebCoating.repairs || emptyArray}
        dispatch={jebCoatingDispatch}
      />
    </>
  );
};

const JebCoatingForm = memo(RawJebCoatingForm);

export { JebCoatingForm };
