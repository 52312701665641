// 検索用のパラメーター変換

const generateOutputKey = (rootKey: string | null | undefined, key: string): string => {
  return encodeURIComponent(
    rootKey ? (rootKey + '[' + key + ']') : key
  );
};

const generateSearchQuery = (
  q: {[x: string]: string | string[] | null | undefined}, rootKey = 'q'
): string => {
  if(!q) return '';
  const baseArr = Object.keys(q).reduce((currentArr, key) => {
    const outputKey = generateOutputKey(rootKey, key);
    const value = q[key];
    if(!Array.isArray(value)) {
      if(value) currentArr.push(outputKey + '=' + encodeURIComponent(value));
      return currentArr;
    }
    if(value.length > 0) {
      value.forEach(oneValue =>
        currentArr.push(outputKey + '%5B%5D=' + encodeURIComponent(oneValue))
      );
    }
    return currentArr;
  }, [] as string[]);

  return baseArr.join('&');
};

export { generateSearchQuery };
