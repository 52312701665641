// 呼び出しのたびにユニークなキーを生成する


const base = 'jbit-key-' + Date.now() + '-';

let counter = 0;

const generateUniqueKey = (): string => {
  return base + (counter ++);
};

export { generateUniqueKey };
