// コンポーネントのライフサイクルを通して使える、uniqueKeyの生成用Hook
// （useRenewableKeyを流用）

import { useRenewableKey } from './use_renewable_key';

const useUniqueKey = (): string => {
  const {0: key} = useRenewableKey();
  return key;
};

export { useUniqueKey };
