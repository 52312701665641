// 関連するテーブルに飛ぶためのボタン

import { FC, memo } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useFixedParamCallback, MemoizedIcon, noop } from '../lib/common';
import { DummyComponent } from './DummyComponent';

type RelatedTableButtonComponent = FC<{
  tableName: string;
  onRequestTable?: (tableName: string) => void;
}>;

const RawRelatedTableButton: RelatedTableButtonComponent = ({tableName, onRequestTable}) => {

  const handleClick = useFixedParamCallback(onRequestTable || noop, tableName);

  // Hooks定義完了

  return (
    <button className="btn btn-outline-secondary float-right" type="button"
      title="関連テーブルを表示"
      onClick={handleClick}
    >
      <MemoizedIcon icon={faLink} />
    </button>
  );
};

const RelatedTableButton: RelatedTableButtonComponent = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawRelatedTableButton);

export { RelatedTableButton };
