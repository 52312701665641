// エラーロガーやBodyProviderなどでラッピングする

import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { BodyContext } from './BodyContext';
import { closest } from './closest';
import ErrorLogger from './ErrorLogger';

const BodyProvider: FC<{container: HTMLElement}> = ({container, children}) => {
  const [body, setBody] = useState(
    // bodyがない状態にはならない
    () => closest(container, 'body') as HTMLBodyElement
  );

  useEffect(() => {
    if(!('turbolinksPermanent' in container.dataset)) return;
    const main = (): void => {
      const newBody = closest(container, 'body');
      if(!newBody) return;
      setBody(newBody);
    };
    // 親要素が変わったのはこのタイミングで取れる
    document.addEventListener('turbolinks:render', main, false);
    return (): void => document.removeEventListener('turbolinks:render', main, false);
  }, [container]);

  // Hooks定義完了

  return(
    <BodyContext.Provider value={body}>
      {children}
    </BodyContext.Provider>
  );
};

const wrapBaseComponent = (
  reactElement: ReactNode,
  container: HTMLElement
): ReactElement => {
  return (
    <ErrorLogger>
      <BodyProvider container={container}>
        {reactElement}
      </BodyProvider>
    </ErrorLogger>
  );
};

export { wrapBaseComponent };
