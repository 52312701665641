import { nestedMerge, matchedIndex } from '../../lib/common';

const TARGETS: {[key in ReorderParam]: (pos: number, length: number) => number} = {
  up: (pos , _) => pos - 1,
  down: (pos, _) => pos + 1,
  top: (_, _2) => 0,
  bottom: (_, length) => length - 1
};

type MergeFunc<T> = (a: T, b: T) => T;

const defaultMerge = <T>(a: T, b: T): T => {
  return {...a, ...b};
};

const sortReorderMerger = <T extends SortChangeable & NestedBase>(
  store: readonly T[], changes: Partial<T>, mergeFunc: MergeFunc<T> = defaultMerge
): T[] => {
  const {...rest} = changes;
  const _reorder: ReorderParam | undefined = changes._reorder;
  const base = nestedMerge<T>(store, rest as T, mergeFunc);
  if(!_reorder) return base;
  // ソートしてから順番入れ替えを行う
  // 削除済みのものを混ぜ込むとややこしいので分離
  const availableItems = base.filter(item => !item._destroy);
  const destroyedItems = base.filter(item => item._destroy);
  availableItems.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0));
  const pos = matchedIndex(availableItems, rest);
  const targetPos = TARGETS[_reorder](pos, availableItems.length);
  if(pos >= 0 && targetPos >= 0 && targetPos < availableItems.length && pos !== targetPos) {
    const dir = pos < targetPos ? 1 : -1;
    const temp: T = availableItems[pos];
    for(let i = pos; i !== targetPos; i += dir){
      availableItems[i] = availableItems[i + dir];
    }
    availableItems[targetPos] = temp;
  }
  return availableItems.concat(destroyedItems).map((item, index) => {
    const sort_order = index + 1;
    if(sort_order === item.sort_order) return item;
    return {...item, sort_order};
  });
};

export { sortReorderMerger };
