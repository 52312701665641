// 依頼書の日付入力部分

import { FC } from 'react';
import { useKeyValDispatch, defaultValuePicker, humanColumnName } from '../../../lib/common';
import { FormColumnedDatePicker } from '../../../react-components';

// order全部を投げてくる構造の関係もあるので、いったんメモ化は省略して実装

const OrderDateColumn: FC<{
  order: Jeb.Order;
  column: keyof Jeb.Order;
  dispatch(changes: Partial<Jeb.Order>): void;
  required?: boolean;
  readOnly?: boolean;
}> = ({order, column, dispatch, required, readOnly}) => {
  const handleChange = useKeyValDispatch(dispatch, column, defaultValuePicker);

  return(
    <FormColumnedDatePicker sm={6} md={3}
      label={humanColumnName('Jeb::Order', column as string)}
      value={(order[column] || '') + ''}
      onChange={handleChange}
      required={required}
      readOnly={readOnly}
    />
  );
};

export { OrderDateColumn };
