import { abortableGetJSON, AbortObject } from './abortable_get_json';

// リクエストをキャッシュするルーチン
// まとめてabortも可能

const abortedObj = {aborted: true};

export default class CachedJSONRequester{
  results: {[x: string]: unknown};
  aborted: boolean;
  requests: AbortObject[];

  constructor() {
    this.results = {};
    this.aborted = false;
    this.requests = [];
  }

  get(url: string): Promise<unknown> {
    if(this.aborted) return Promise.reject(abortedObj);
    if(this.results[url]) return Promise.resolve(this.results[url]);
    return new Promise((resolve, reject) => {
      const success = (data: unknown): void => {
        if(this.aborted) reject(abortedObj);
        this.results[url] = data;
        resolve(data);
      };
      const error = (response: string, status: string, xhr: XMLHttpRequest): void => {
        if(xhr.readyState === 0 || this.aborted) {
          reject(abortedObj);
          return;
        }
        reject({message: 'error:' + response, status});
      };
      this.requests.push(abortableGetJSON(url, success, error));
    });
  }

  abortAll(): void {
    this.aborted = true;
    this.requests.forEach(item => item.abort());
  }

  clear(): void {
    this.results = {};
  }
}
