// ファイル入力の画像部分
// （childrenを取る形にしておく）

import { FC, ReactChild, useMemo } from 'react';
import style from './files/files-style.module.scss';

const FileImage: FC<{
  thumbnail: string;
  children?: ReactChild;
}> = ({thumbnail, children}) => {
  const thumbnailStyle = useMemo(() => {
    return { backgroundImage: 'url(' + thumbnail + ')' };
  }, [thumbnail]);

  // Hooks定義完了

  return(
    <div className={'card-image-top ' + style.fileImage}
      style={thumbnailStyle}
    >
      { children }
    </div>
  );
};

export { FileImage };
