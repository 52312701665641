// HTMLの読み込みが終わった段階（DOMContentLoadedより先かも）で実行するハンドラ

import { onReady } from './on_ready';

const promise = new Promise<void>(resolve => {
  onReady(resolve);
  requestAnimationFrame(function loop(){
    if(document.getElementById('body_end_marker')) {
      resolve();
      return;
    }
    // resolveを複数回実行しても無害なので、
    // 条件判定を省略
    requestAnimationFrame(loop);
  });
});

const onHTMLLoaded = (func: () => void): void => {
  promise.then(func);
};

export { onHTMLLoaded };
