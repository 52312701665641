// 複数選択のドロップダウンコンポーネント

import { FC, memo, useCallback, useMemo } from 'react';
import Select, { ValueType, OptionTypeBase, OptionsType, Styles } from 'react-select';

const emptyArray: never[] = [];

// z-indexを引き上げる（#9）
const styles: Styles<OptionTypeBase, true> = {
  menu: styles => ({ ...styles, zIndex: 999 })
};

// リストに依存した配列を外側で管理しなくて済むように対応

const RawMultiSelect: FC<{
  value: string[];
  onChange: (value: string[]) => void;
  // 複数対応は後で整備する
  options: OptionsType<OptionTypeBase>;
  placeholder?: string;
}> = ({value, onChange, options, placeholder}) => {
  const optionsByValue = useMemo(() => {
    const ret: {[x: string]: OptionTypeBase} = {};
    options.forEach(item => ret[item.value] = item);
    return ret;
  }, [options]);
  const applyingValue = useMemo(
    () => value.map(key => optionsByValue[key]),
    [optionsByValue, value]
  );

  const handleChange = useCallback((obj: ValueType<OptionTypeBase, true>) => {
    // 複数選択で使うので、このパターンしか来ない
    const rawValue = obj as OptionTypeBase[] | null;
    const values = rawValue ? rawValue.map(item => item.value) : emptyArray;
    onChange(values);
  }, [onChange]);
  // Hooks定義完了

  return(
    <Select isMulti options={options} placeholder={placeholder} isSearchable={false}
      onChange={handleChange} closeMenuOnSelect={false}
      value={applyingValue} styles={styles}
    />
  );
};

const MultiSelect = memo(RawMultiSelect);

export { MultiSelect };
