// 本体のDOMに書かれていない、Reactのモーダルなど、
// ドキュメントの位置に依存しないコンポーネント）を生成して表示する

import { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { renderReactRoot } from './render_react_root';
import { wrapBaseComponent } from './wrap_base_component';

const getBaseElement = (base?: HTMLElement): HTMLElement => {
  // 使いまわし可能な場合（bodyになることはない）
  if(base && base.tagName.toLowerCase() !== 'body') return base;
  // bodyの中に用意する場合
  const elem = document.createElement('div');
  (base || document.body).appendChild(elem);
  return elem;
};

const createReactModal = (
  reactComponent: ReactElement,
  base: HTMLElement = document.body
): HTMLElement => {
  const rootElement = getBaseElement(base);
  if(rootElement !== base) {
    // エレメントの削除まで含めて登録
    const callback = (): void => {
      rootElement.parentNode?.removeChild(rootElement);
    };
    renderReactRoot(rootElement, reactComponent, callback);
  } else {
    // 削除時のイベントはセットしているという前提で、
    // Componentを外して再マウント
    ReactDOM.unmountComponentAtNode(rootElement);
    ReactDOM.render(
      wrapBaseComponent(reactComponent, rootElement),
      rootElement
    );
  }
  return rootElement;
};

export { createReactModal };
