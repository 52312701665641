// closest実装
// （中途半端なものか直接prototypeを書き換えるのかしかないので、自前で実装）
// https://developer.mozilla.org/ja/docs/Web/API/Element/closest#Polyfill
// matchesはネイティブのを使用
// jQuery、DOMと揃えて自分自身からスタート

interface ClosestFunction{
  <K extends keyof HTMLElementTagNameMap>(elem: Element, selector: K): HTMLElementTagNameMap[K] | null;
  (elem: Element, selector: string): Element | null;
}

const nativeMatches =
  Element.prototype.matches ||
  Element.prototype.webkitMatchesSelector;


const polyfilled: ClosestFunction =
(elem: Element, selector: string): Element | null => {
  let node: Node | null = elem;
  // if (!document.documentElement.contains(elem)) return null;
  do {
    if (nativeMatches.call(node, selector)) return node as Element;
    // SVG要素の場合、IE11ではparentElementを取れないので、
    // parentNodeを取るしかない
    node = node.parentNode;
  } while (node !== null && node !== document);
  return null;
};

const native = (elem: Element, selector: string): Element | null => elem.closest(selector);

const closest: ClosestFunction = ('closest' in Element.prototype) ? native : polyfilled;

export { closest };
