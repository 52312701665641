// dispatch({[column]: value})という形のコールバックを生成するHooks

import { useCallback } from 'react';

const useKeyValDispatch = <T, K extends keyof T, E>(
  dispatch: (changes: Partial<T>) => void,
  column: K,
  convertor: (event: E) => T[K]
): (event: E) => void => {
  return useCallback(e => {
    const value = convertor(e);
    const obj: Partial<T> = {};
    obj[column] = value;
    dispatch(obj);
  }, [column, convertor, dispatch]);
};

export { useKeyValDispatch };
