// 表示用のカラム名取得用のルーチン

const humanColumnName = (
  modelName: string, columnName: string
): string | null => {
  const columns = window._rV.cn[modelName];
  if(!columns) return null;
  const rawName = columns[columnName];
  return rawName === undefined ? null : rawName;
};

export { humanColumnName };
