// フッター部分に固定表示させる枠
// <body>直下に入れる必要があるので、ポータルを活用

import { BodyContext, oneBeforeTransition } from '../lib/common';
import { FC, useContext } from 'react';
import ReactDOM from 'react-dom';
import styles from './footer_fixed_frame.module.scss';

const FooterFixedFrame: FC = ({children}) => {
  const body = useContext(BodyContext);
  const root = document.createElement('div');
  body.appendChild(root);
  oneBeforeTransition(() => body.removeChild(root));

  return ReactDOM.createPortal(
    <div id={styles.fixedFrame}>
      {children}
    </div>,
    root
  );
};

export { FooterFixedFrame };

