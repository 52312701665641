// 桁数を指定した四捨五入

const roundByFraction = (value: number, fractionDigits = 2): number => {
  // 無限大やNaNは除外（0も問題なし）
  if(!value || !isFinite(value)) return value;
  const base = Math.pow(10, fractionDigits);
  return Math.round(value * base) / base;
};

export { roundByFraction };
