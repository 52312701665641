// CSS3でスクロールをアニメーションさせる

const scrollAnimate = (
  position: number, duration = 400, callback?: () => void
): void => {
  const rootElement = document.querySelector('body > .container') as HTMLElement;
  const styleRoot = rootElement.style;
  // スクロール不能な場合は抜ける
  const maximumScroll = rootElement.scrollHeight - window.innerHeight;
  if(maximumScroll <= 0) {
    if(callback) setTimeout(callback, 0);
    return;
  }
  const onDone: (e: TransitionEvent) => void = (e) => {
    // バブリングしたものは弾く
    if(e.target !== rootElement) return;
    styleRoot.transition = styleRoot.transform = 'initial';
    window.scrollTo(0, position);
    rootElement.removeEventListener('transitionend', onDone, false);
    if(callback) callback();
  };
  const scrollDistance = window.pageYOffset - Math.min(
    position,
    maximumScroll
  );
  rootElement.addEventListener('transitionend', onDone, false);
  styleRoot.transition = `transform ${duration}ms ease-in-out`;
  styleRoot.transform = 'translate3d(0, ' + scrollDistance + 'px, 0)';
};

export { scrollAnimate };
