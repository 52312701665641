// 業者側ページの編集フォーム
// （とりあえずジェブだけ）

import { FC, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { BodyContext, confirmPageTransition } from '../../../lib/common';
import { reducer } from '../../../customers_common/reducer';
import { CustomerValuesContext } from '../../../customers_common/CustomerValuesContext';
import { CustomerBaseData } from '../../../customers_common/CustomerBaseData';
import { CompaniesJebCoatingForm } from './jeb/CompanyJebCoatingForm';
import { useCustomerSender } from '../../../customers_common/use_customer_sender';
import { useInitialCustomer } from '../../../customers_common/use_inital_customer';
import { CustomerSendButtonRow } from '../../../customers_common/CustomerSendButtonRow';
import { FooterFixedFrame } from '../../../react-components';
import { CustomerSectionLinks } from '../../../customers_common/CustomerSectionLinks';
import { MessagesButton } from '../../../customers_common/MessagesButton';

export interface CustomerErrorReplacer {
  _replace: boolean;
  value: CustomerError;
}

const errorReducer = (store: CustomerError, changes: CustomerError | CustomerErrorReplacer): CustomerError => {
  if('_replace' in changes) return changes.value as CustomerError;
  return {...store, ...changes};
};

const CompanyCustomerForm: FC = () => {
  const initialCustomer = useInitialCustomer();
  const body = useContext(BodyContext);
  const [customer, rawDispatch] = useReducer(reducer, initialCustomer);

  // 送信ボタンを多数設置するので、上位で一括管理
  const [sending, setSending] = useState(false);
  const [willSend, setWillSend] =
    useState<Partial<Customer> | null>(null);

  // initialCustomerが書き換わった時（=ページ遷移時）にはcustomerをリセット
  const firstRef = useRef(true);
  useEffect(() => {
    if(firstRef.current) {
      firstRef.current = false;
      return;
    }
    rawDispatch({_replace: initialCustomer});
    setSending(false);
  }, [initialCustomer]);

  const dispatch: typeof rawDispatch = useCallback((changes) => {
    rawDispatch(changes);
    confirmPageTransition(body);
  }, [body]);

  // 初期表示の際にエラーとなることは普通ないので、空の値で初期化
  const [errors, errorDispatch] = useReducer(errorReducer, {});

  useCustomerSender({willSend, setWillSend, setSending, errorDispatch, customer, controller: '/companies/customers'});
  const contextValue = useMemo(() => ({
    dispatch, errors, errorDispatch, sending, setSending
  }), [dispatch, errorDispatch, errors, sending]);

  // Hooks定義完了



  return(
    <CustomerValuesContext.Provider value={contextValue}>
      <CustomerSectionLinks />
      <form className="main-form">
        <h2>基本情報（変更不可）</h2>
        <CustomerBaseData customer={customer} readOnly />
        {
          customer.jeb_coating && (
            <CompaniesJebCoatingForm
              jebCoating={customer.jeb_coating}
              dispatch={dispatch}
              errors={errors}
              companyAccountIds={customer.company_account_ids}
              setWillSend={setWillSend}
            />
          )
        }
      </form>
      <FooterFixedFrame>
        <div className="container">
          <CustomerSendButtonRow setWillSend={setWillSend}
          />
        </div>
      </FooterFixedFrame>
      <MessagesButton />
    </CustomerValuesContext.Provider>
  );
};

export { CompanyCustomerForm };
