// 送信ボタンを含んだ行

import { FC, useContext } from 'react';
import { useFixedParamCallback } from '../lib/common';
import { CustomerValuesContext } from './CustomerValuesContext';

const emptyObject = {};

const CustomerSendButtonRow: FC<{
  setWillSend: (changes: Partial<Customer>) => void;
  isNew?: boolean;
}> = ({setWillSend, isNew}) => {
  const { sending } = useContext(CustomerValuesContext);
  const actionStr = isNew ? '保存' : '更新';
  const handleClick = useFixedParamCallback(setWillSend, emptyObject);

  // Hooks定義完了

  return(
    <div className="row">
      <div className="col-12 col-md-8 offset-md-2">
        <button type="button" className="btn btn-primary btn-block btn-lg"
          onClick={handleClick} disabled={sending}
        >
          {
            sending ? '送信中…' : (actionStr + 'する')
          }
        </button>
      </div>
    </div>
  );
};

export { CustomerSendButtonRow };
