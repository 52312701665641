// 顧客情報の登録・編集用フォーム

import { FC, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { confirmPageTransition, BodyContext } from '../../lib/common';
import { JebCoatingForm } from './jeb/JebCoatingForm';
import { CustomerBaseData } from '../../customers_common/CustomerBaseData';
import { reducer } from '../../customers_common/reducer';
import { useCustomerSender } from '../../customers_common/use_customer_sender';
import { CustomerSendButtonRow } from '../../customers_common/CustomerSendButtonRow';
import { useInitialCustomer } from '../../customers_common/use_inital_customer';
import { FooterFixedFrame } from '../../react-components';
import { CustomerSectionLinks } from '../../customers_common/CustomerSectionLinks';
import { CustomerValuesContext } from '../../customers_common/CustomerValuesContext';
import { MessagesButton } from '../../customers_common/MessagesButton';

export interface CustomerErrorReplacer {
  _replace: boolean;
  value: CustomerError;
}

const errorReducer = (store: CustomerError, changes: CustomerError | CustomerErrorReplacer): CustomerError => {
  if('_replace' in changes) return changes.value as CustomerError;
  return {...store, ...changes};
};

const JEB_BASE_DATA = {jeb_process_id: 180};

const CustomerForm: FC = () => {
  const initialCustomer = useInitialCustomer();
  const body = useContext(BodyContext);
  const [customer, rawDispatch] = useReducer(reducer, initialCustomer);

  // 送信ボタンを多数設置するので、上位で一括管理
  const [sending, setSending] = useState(false);
  const [willSend, setWillSend] =
    useState<Partial<Customer> | null>(null);

  // initialCustomerが書き換わった時（=ページ遷移時）にはcustomerをリセット
  const firstRef = useRef(true);
  useEffect(() => {
    if(firstRef.current) {
      firstRef.current = false;
      return;
    }
    rawDispatch({_replace: initialCustomer});
    setSending(false);
  }, [initialCustomer]);

  const dispatch: typeof rawDispatch = useCallback((changes) => {
    rawDispatch(changes);
    confirmPageTransition(body);
  }, [body]);

  // 初期表示の際にエラーとなることは普通ないので、空の値で初期化
  const [errors, errorDispatch] = useReducer(errorReducer, {});

  const handleClickAddJeb = useCallback(() => {
    dispatch({jeb_coating: JEB_BASE_DATA});
  }, [dispatch]);

  useCustomerSender({willSend, setWillSend, setSending, errorDispatch, customer});

  const contextValue = useMemo(() => ({
    dispatch, errors, errorDispatch, sending, setSending
  }), [dispatch, errorDispatch, errors, sending]);

  // Hooks定義完了

  const isNew = typeof customer.id !== 'number';

  return(
    <CustomerValuesContext.Provider value={contextValue}>
      {
        customer.jeb_coating && (
          <CustomerSectionLinks />
        )
      }
      <form className="main-form">
        <h2>
          基本情報
          {
            !customer.jeb_coating && (
              <button className="btn btn-success float-right"
                type="button"
                onClick={handleClickAddJeb}
              >
                フロアコーティング依頼
              </button>
            )
          }
        </h2>
        <CustomerBaseData customer={customer} />
        {
          customer.jeb_coating && (
            <JebCoatingForm
              jebCoating={customer.jeb_coating}
              dispatch={dispatch}
              errors={errors}
              companyAccountIds={customer.company_account_ids}
              setWillSend={setWillSend}
              coatingEstimateSets={customer.coating_estimate_sets}
            />
          )
        }
        <CustomerSendButtonRow setWillSend={setWillSend}
          isNew={isNew}
        />
      </form>
      <FooterFixedFrame>
        <div className="container">
          <CustomerSendButtonRow setWillSend={setWillSend}
            isNew={isNew}
          />
        </div>
      </FooterFixedFrame>
      <MessagesButton />
    </CustomerValuesContext.Provider>
  );
};

export { CustomerForm };
