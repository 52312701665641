// 表画面にセットされた段階で再描画を行うAutosizeTextarea

import { ChangeEventHandler, FC, memo, useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import autosize from 'autosize';
import { useTurbolinksRendered } from '../lib/common';

export interface WrappedAutosizeTextareaProps extends TextareaAutosize.Props{
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const RawAutosizeTextarea: FC<WrappedAutosizeTextareaProps> = props => {
  const areaRef = useRef<HTMLTextAreaElement | null>(null);

  useTurbolinksRendered(() => {
    const elem = areaRef.current;
    elem && autosize.update(elem);
  });

  return (
    <TextareaAutosize {...props} ref={areaRef} />
  );
};

const AutosizeTextarea = memo(RawAutosizeTextarea);

export { AutosizeTextarea };
