// 詳細行の生成

import { toCommaSeparated, generateUniqueKey, roundByFraction } from '../../lib/common';
import { reduceArea, reduceFormula } from './area_reduction';

const emptyArray: never[] = [];

const NORMAL_TYPE_ID = 100;
const EMPHASIZED_TYPE_ID = 200;
const ADDITIONAL_TYPE_ID = 300;

const SQUARE_METER_UNIT_ID = 100;
const SET_UNIT_ID = 900;

const POLUS_DISCOUNT = {
  title: 'ポラス物件割引', coating_estimate_detail_type_id: EMPHASIZED_TYPE_ID,
  quantity: '1', coating_estimate_unit_id: SET_UNIT_ID
} as const;

const emptyObject = {};

// まずは値だけ書き換えて対応
type DetailParameters = {
  unitPrice: number;
  leastArea: number;
  reduceThreshold: number;
  title: string;
}

const ParametersByTypeId: {[x: number]: DetailParameters} = {
  100: {
    unitPrice: 7000,
    leastArea: 30,
    reduceThreshold: 50,
    title: 'EPCOAT'
  },
  200: {
    unitPrice: 4200,
    leastArea: 30,
    reduceThreshold: 60,
    title: 'シリコンコーティング'
  },
  300: {
    unitPrice: 6000,
    leastArea: 30,
    reduceThreshold: +Infinity, // 無限大まですっ飛ばして減積を無効化
    title: 'ハードプロテクト'
  }
};

// IDと順番は後から生成する
const generateRows = (area: string, typeId?: number): Partial<CoatingEstimateDetail>[] => {
  const areaVal = +area || 0;
  if(!typeId) return [];
  const parameters = ParametersByTypeId[typeId];
  if(!parameters) return [];
  const {title, unitPrice, leastArea, reduceThreshold} = parameters;
  const reducedArea = reduceArea(areaVal, reduceThreshold);
  const isReduced = areaVal > reducedArea;

  return [
    {
      title, coating_estimate_detail_type_id: ADDITIONAL_TYPE_ID, quantity: area,
      coating_estimate_unit_id: SQUARE_METER_UNIT_ID
    },
    {
      title: '延床面積',
      coating_estimate_detail_type_id: isReduced ? ADDITIONAL_TYPE_ID : NORMAL_TYPE_ID,
      quantity: area,
      coating_estimate_unit_id: SQUARE_METER_UNIT_ID,
      unit_price: areaVal >= leastArea ? unitPrice : null,
      price: Math.round(Math.max(areaVal, leastArea) * unitPrice),
      description: leastArea + '㎡まで一律￥' + toCommaSeparated(leastArea * unitPrice) + (
        areaVal >= leastArea ?
          ' + ' + roundByFraction(areaVal - leastArea) + '㎡ × ￥' + toCommaSeparated(unitPrice) :
          ''
      ) + (isReduced ? '  キャンペーン適用前延床面積、通常価格' : '')
    },
    (
      isReduced ? {
        title: 'キャンペーン適用後延床面積',
        coating_estimate_detail_type_id: EMPHASIZED_TYPE_ID,
        quantity: reducedArea + '',
        coating_estimate_unit_id: SQUARE_METER_UNIT_ID,
        price: Math.round(reducedArea * unitPrice),
        description: reduceFormula(areaVal, reduceThreshold)
      } : emptyObject
    ),
    POLUS_DISCOUNT
  ].filter(item => item !== emptyObject);
};

const generateDetails = (
  totalArea: string, typeId?: number, currentDetails?: CoatingEstimateDetail[]
): CoatingEstimateDetail[] => {
  const destroys: CoatingEstimateDetail[] = (currentDetails || emptyArray).filter(item => item.id).map(
    item => ({id: item.id, _destroy: 1})
  );
  const rows = generateRows(totalArea, typeId);
  return destroys.concat(rows.map((item, i) => ({...item, newId: generateUniqueKey(), sort_order: i + 1})));
};

export { generateDetails };
