// cachedJSONRequesterを参照するためのeffect

import { useEffect, useRef } from 'react';
import CachedJSONRequester from './cached_json_requester';

const useJSONRequester = (): CachedJSONRequester => {
  const ref = useRef(new CachedJSONRequester);

  const requester = ref.current;

  useEffect(() => {

    return (): void => requester.abortAll();
  }, [requester]);

  return requester;
};

export { useJSONRequester };
