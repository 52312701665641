// ジェブ担当者のリスト
// 変更後はページ遷移からの再ロードを行うので、内部での値の更新は考えなくていい

import { FC, useMemo, useState } from 'react';
import { useViewData, defaultCompare, sortOrOriginal, useFixedParamCallback } from '../../../lib/common';
import { LazyEmployeeModal } from './LazyEmployeeModal';
import { JebEmployee } from './JebEmployee';
import { SortHeader } from '../../../react-components';

type CompareFunc = (x: Jeb.Employee, y: Jeb.Employee) => number;

const specialSorts: {[x: string]: CompareFunc} = {
  email(x, y){
    return defaultCompare(x.company_account.email, y.company_account.email);
  },
  role(x, y){
    const roleCount = (e: Jeb.Employee): number => {
      if(e.deprecated) return 0;
      return e.role_ids?.length ? 2 : 1;
    };
    return roleCount(x) - roleCount(y);
  }
};

const sortCriterium = (sortKey: string): CompareFunc => {
  const isDesc = sortKey[0] === '-';
  const sortKeyOnly = isDesc ? sortKey.slice(1) : sortKey;
  const coef = isDesc ? -1 : 1;

  if(sortKeyOnly in specialSorts) {
    return (x, y): number => specialSorts[sortKeyOnly](x, y) * coef;
  }

  // ひとまず、指定列を抽出して比較する関数を作る（特殊ケースは後で対応）
  return (x: any, y: any): number => defaultCompare(x[sortKeyOnly], y[sortKeyOnly]) * coef;
};

const sortEmployees = (
  employees: readonly Jeb.Employee[], sortKey: string
): readonly Jeb.Employee[] => {

  return sortOrOriginal(employees, sortCriterium(sortKey));
};

const emptyObject = {};

const JebEmployees: FC = () => {
  const viewData = useViewData() as {
    jeb_employees: readonly Jeb.Employee[];
    is_admin: boolean;
  };
  const employees = viewData.jeb_employees;
  const isAdmin = viewData.is_admin;
  const [open, setOpen] = useState(false);
  const [sortKey, setSortKey] = useState('short_name');

  const sortedEmployees = useMemo(() => {
    return sortEmployees(employees, sortKey);
  }, [employees, sortKey]);

  const handleCreate = useFixedParamCallback(setOpen, true);

  // Hooks定義完了

  return(
    <table className="table">
      <tbody>
        <tr>
          <SortHeader
            title="氏名" sortKey="name" currentSortKey={sortKey}
            setSortKey={setSortKey}
          />
          <SortHeader
            title="メールアドレス" sortKey="email" currentSortKey={sortKey}
            setSortKey={setSortKey}
          />
          <SortHeader
            title="短縮名" sortKey="short_name" currentSortKey={sortKey}
            setSortKey={setSortKey}
          />
          <SortHeader
            title="権限 / 状態" sortKey="role" currentSortKey={sortKey}
            setSortKey={setSortKey} defaultDesc
          />
          {
            isAdmin && (
              <th>
                <button className="btn btn-success float-right" type="button"
                  onClick={handleCreate}
                >
                  追加
                </button>
                <LazyEmployeeModal open={open}
                  onChangeOpen={setOpen}
                  defaultEmployee={emptyObject}
                />
              </th>
            )
          }

        </tr>
        {
          sortedEmployees.map(e => (
            <JebEmployee key={e.id} employee={e} />
          ))
        }
        {
          employees.length === 0 && (
            <tr>
              <td colSpan={isAdmin ? 6 : 5} className="text-center">
                登録はありません。
              </td>
            </tr>
          )
        }
      </tbody>
    </table>
  );
};

export { JebEmployees };
