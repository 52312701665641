// Turbolinksによるページ遷移を行う
// ついでに、遷移後にトーストを出す機能も実装

import { loadDeferredPromise } from './load_deferred_promise';
import { setFlash } from './flash';

interface VisitOptions{
  replace?: boolean;
  message?: string;
  toastType?: ToastTypeString;
}

const turbolinksVisit = (
  url: string, options: VisitOptions = {}
): void => {
  const {replace, message, toastType} = options;
  loadDeferredPromise.then(({Turbolinks}) => {
    Turbolinks.visit(url, { action: replace ? 'replace' : 'advance' });
    if(message) {
      setFlash(message, toastType);
    }
  });
};

export { turbolinksVisit };
