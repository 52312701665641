// リレーションのテーブルを作るコンポーネント

import { memo, useMemo, useState } from 'react';
import { sortOrOriginal } from '../lib/common';
import { DummyComponent } from './DummyComponent';
import { SortHeader } from './reflection_table/SortHeader';
import { ReflectionRow } from './reflection_table/ReflectionRow';

function RawReflectionsTable(props) {
  const [currentSort, handleChangeSort] = useState('name');
  const {reflections, onRequestTable, title} = props;

  const sortedReflections = useMemo(() => {
    let column = currentSort, isDesc = false;
    if(currentSort[0] === '-') {
      column = currentSort.substr(1);
      isDesc = true;
    }
    return sortOrOriginal(reflections, (a, b) => {
      const aName = a[column], bName = b[column];
      if(aName < bName) return isDesc ? 1 : -1;
      if(aName > bName) return isDesc ? -1 : 1;
      return 0;
    });
  }, [reflections, currentSort]);

  // Hooks定義完了

  if(reflections.length === 0) return null;
  return (
    <>
      <h3 className="jeb">{title}</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <SortHeader title="リレーション名" sortKey="name"
              currentSort={currentSort} onChangeSort={handleChangeSort}
            />
            <SortHeader title="種類" sortKey="reflection_type"
              currentSort={currentSort} onChangeSort={handleChangeSort}
            />
            <SortHeader title="相手先クラス" sortKey="class_name"
              currentSort={currentSort} onChangeSort={handleChangeSort}
            />
            <SortHeader title="キー列" sortKey="foreign_key"
              currentSort={currentSort} onChangeSort={handleChangeSort}
            />
          </tr>
          {
            sortedReflections.map(ref =>
              <ReflectionRow key={ref.name + '-' + ref.class_name}
                reflection={ref} onRequestTable={onRequestTable}
              />
            )
          }
        </tbody>
      </table>
    </>
  );
}

const ReflectionsTable = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawReflectionsTable);

export { ReflectionsTable };
