// イベント引数または文字列のところから値を取得する関数

const defaultValuePicker = (
  e: string | {target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement}
): string => {
  if(typeof e === 'string') return e;
  return e.target.value;
};

export { defaultValuePicker };
