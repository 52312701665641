// 改行ごとに<br>を入れるコンポーネント

import { FC, memo } from 'react';
import { JoinedArray } from './JoinedArray';

const RawSeparateBr: FC<{text: string}> = ({text}) => {
  const arr = (text || '').split('\n');
  return <JoinedArray array={arr} joiner={<br />} />;
};

const SeparateBr = memo(RawSeparateBr);

export { SeparateBr };
