// nestedな配列から、指定したid/newIdのインデックスを探す
// なければ-1

const isKeyMatch = (obj1: NestedBase, obj2: NestedBase): boolean => {
  // idとnewIdのどちらかはあることが前提
  if('id' in obj1) {
    return obj1.id === obj2.id;
  }
  return obj1.newId === obj2.newId;
};

const matchedIndex = <T extends NestedBase>(arr: readonly T[], key: NestedBase): number => {
  for(let i = 0; i < arr.length; ++i) {
    if(isKeyMatch(arr[i], key)) return i;
  }
  return -1;
};

export { matchedIndex };
