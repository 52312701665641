import { onHTMLLoadedByClasses } from './on_html_loaded_by_classes';

// コントローラー（とアクション）を指定してハンドラを登録する
// 途中の引数を省略できるようにするため、オーバーロード

interface OnBeforeRenderByControllerFunc{
  (controller: string, action: string, handler: BodyHandler): void;
  (controller: string, handler: BodyHandler): void;
}

const onBeforeRenderByController: OnBeforeRenderByControllerFunc = (
  controller: string, action: string | BodyHandler, handler?: BodyHandler
) => {
  // オーバーロードの関係上、handlerが来ないときのactionはBodyHandler
  const realHandler: BodyHandler = handler || action as BodyHandler;
  const classNames = ['controller-' + controller];
  if(typeof action === 'string') classNames.push('action-' + action);
  onHTMLLoadedByClasses(classNames, realHandler);
};


export { onBeforeRenderByController };
