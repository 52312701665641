// 顧客リスト（ビューからの値の回収とPC/モバイルの振り分け）

import { FC } from 'react';
import { useViewData, useIsXsSmMd } from '../../lib/common';
import { WideList } from './list/WideList';
import { CustomerInList } from './list/customer_with_coating_date';
import { NarrowList } from './list/NarrowList';

const CustomersList: FC = () => {
  const {items} = (useViewData() as {items: CustomerInList[]});
  const isNarrow = useIsXsSmMd();

  // Hooks定義完了

  const Component = isNarrow ? NarrowList : WideList;

  return <Component items={items} />;
};

export { CustomersList };
