// abortできる関数を返すgetJSON（ujs使用）

import { loadDeferredPromise } from './load_deferred_promise';

export type AbortObject = {
  abort(): void;
};

const abortableGetJSON = (
  url: string,
  success: (response: any, statusText: string, xhr: XMLHttpRequest) => unknown,
  error: (response: any, statusText: string, xhr: XMLHttpRequest) => unknown,
  complete?: (xhr: XMLHttpRequest, statusText: string) => unknown
): AbortObject => {
  let xhr: XMLHttpRequest | undefined;
  loadDeferredPromise.then(({Rails}) => {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      beforeSend (xhr2) {
        xhr = xhr2;
        return true;
      },
      success, error, complete
    });
  });
  return {
    abort(): void {
      if(xhr) xhr.abort();
    }
  };
};

export { abortableGetJSON };
