// 顧客リストのマウント

import { onBeforeRenderByController, renderReactRoot } from '../lib/common';
import { CustomersList } from './react-components/CustomersList';

onBeforeRenderByController('customers', 'index', body => {
  const root = body.querySelector('#customers_list_base');
  if(root) renderReactRoot(
    root as HTMLElement,
    <CustomersList />
  );
});

if(process.env.NODE_ENV === 'development' && module.hot){
  module.hot.accept();
}
