// ファイル追加用の枠

import { ChangeEvent, FC, memo, useCallback } from 'react';
import uploadThumbnail from './files/upload-button.png';
import { FileImage } from './FileImage';
import style from './files/files-style.module.scss';
import { useRenewableKey, defaultCheckContentType } from '../lib/common';

const RawFileAppendFrame: FC<{
  onAppend?: (file: File) => void;
  checkContentType?: (contentType: string) => boolean;
  multiple?: boolean;
  background?: string;
}> = ({onAppend, multiple, checkContentType, background}) => {
  const [key, renewKey] = useRenewableKey();
  const isValidContentType = checkContentType || defaultCheckContentType;

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    let rejectedCount = 0;
    if(onAppend && files && files.length > 0){
      for(let i = 0; i < files.length; ++i) {
        if(isValidContentType(files[i].type)) {
          onAppend(files[i]);
        } else {
          rejectedCount++;
        }
      }
      if(rejectedCount > 0) {
        alert('不適当な種類のファイルは除外しています。');
      }
    }
    renewKey();
  }, [isValidContentType, onAppend, renewKey]);

  // Hooks定義完了

  return(
    <div className="col mb-4">
      <div className="card">
        <FileImage thumbnail={background || uploadThumbnail}>
          <input type="file" multiple={multiple}
            onChange={handleChange}
            key={key}
            className={style.newFileInput}
          />
        </FileImage>
      </div>
    </div>
  );
};

const FileAppendFrame = memo(RawFileAppendFrame);

export { FileAppendFrame };
