// FileからobjectURLを生成する
// 破棄する都合があるので、stateを立てる

import { useEffect, useState } from 'react';

const useObjectURL = (file: File | null | undefined): string => {
  const [blobUrl, setBlobUrl] = useState('');

  // ブロブに変換
  useEffect(() => {
    // あとからfileを消した場合に、リセットする必要がある
    setBlobUrl(file ? URL.createObjectURL(file) : '');
  }, [file]);

  // 解放処理
  useEffect(() => {
    if(!blobUrl) return;
    return (): void => URL.revokeObjectURL(blobUrl);
  }, [blobUrl]);

  return blobUrl;
};

export { useObjectURL };
