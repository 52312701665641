import { onceWithBody } from './once_with_body';
import { onHTMLLoaded } from './on_html_loaded';

// 見るべきクラスを指定して、それによってロード前にハンドラを実行する

const isSuitableClasses = (body: HTMLElement, classNames: readonly string[]): boolean =>{
  return classNames.every(className => body.classList.contains(className));
};

const onHTMLLoadedByClasses = (
  classNames: readonly string[], handler: BodyHandler
): void => {
  const wrappedHandler = onceWithBody(body => {
    if(!isSuitableClasses(body, classNames)) return;
    handler(body);
  });

  onHTMLLoaded(() => wrappedHandler(document.body as HTMLBodyElement));
  document.addEventListener('turbolinks:before-render', e => {
    wrappedHandler((e as TurbolinksBeforeRenderEvent).data.newBody);
  }, false);
};

export { onHTMLLoadedByClasses };
