// ファイルにdescriptionを追加するコンポーネント

import { memo, ReactElement } from 'react';
import { useKeyValDispatch, defaultValuePicker, noop } from '../lib/common';

type FileDescriptionComponentParam<T extends NestedBase & {description?: string}> = {
  file: T;
  dispatch?: (changes: Partial<T>) => void;
};


const RawFileDescription = <T extends NestedBase & {description?: string}>(
  props: FileDescriptionComponentParam<T>
): ReactElement => {
  const {file, dispatch} = props;
  const handleChangeDescription = useKeyValDispatch(dispatch || noop, 'description', defaultValuePicker);

  // Hooks定義完了

  return(
    <input type="text" className="form-control"
      value={file.description || ''}
      onChange={handleChangeDescription}
    />
  );
};

const FileDescription = memo(RawFileDescription);

export { FileDescription };

