// nested objectの元配列とchangeのマージ

import { matchedIndex } from './matched_index';

type MergeFunc<T> = (a: T, b: T) => T;

const defaultMerge = <T>(a: T, b: T): T => {
  return {...a, ...b};
};

const nestedMerge = <T extends NestedBase>(
  store: readonly T[], change: T, mergeFunc: MergeFunc<T> = defaultMerge
): T[] => {
  // どうせ元配列を返すことはないので、まずはコピー
  const ret = store.concat();
  const index = matchedIndex(ret, change);
  if(index !== -1) {
    ret[index] = mergeFunc(ret[index], change);
  } else {
    ret.push(change);
  }
  // 新規作成のもので削除すべきものは、この時点で削ってしまう
  return ret.filter(item => item.id || !item._destroy);
};

export { nestedMerge };
