import { roundByFraction } from '../../lib/common';

// 半分減積処理
const reduceArea = (area: number, threshold: number): number => {
  if(area <= threshold) return area;
  // TODO: 0.01㎡の端数をどうするかは要調整
  return roundByFraction((area + threshold) / 2);
};

// 減積の式を表示する関数
const reduceFormula = (area: number, threshold: number): string => {
  const reducedArea = reduceArea(area, threshold);
  return [
    threshold,
    '㎡超過分は床面積半分減積 （ ',
    area,
    ' － ',
    threshold,
    ' ）÷ 2 ＋ ',
    threshold,
    ' ＝ ',
    reducedArea
  ].join('');
};

export { reduceArea, reduceFormula };
