// クラス一覧のリストボックスを表示するコンポーネント

import { memo } from 'react';
import classNames from 'classnames';
import { useConvertedSetter } from '../lib/common';
import { DummyComponent } from './DummyComponent';

const pickDataName = e => e.target.dataset.name;

function RawClassList({
  list, displayingItem, handleRequestTable, handleFetch
}) {

  const handleClickList = useConvertedSetter(handleRequestTable, pickDataName);
  const handleListHover = useConvertedSetter(handleFetch, pickDataName);

  // Hooks定義完了

  const listClass = (name, master) => {
    return classNames({
      master,
      active: name === displayingItem
    });
  };

  return(
    <div className="col-sm-3">
      <ul className="classes-frame">
        {list.map(
          ({name, master}) =>
            <li key={name} className={listClass(name, master)}
              data-name={name} onClick={handleClickList}
              onMouseEnter={handleListHover}
            >
              {name}
              {master ? '★' : ''}
            </li>
        )}
      </ul>
    </div>
  );
}

const ClassList = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawClassList);

export { ClassList };
