// propsを変換して内側のコンポーネントに伝えるコンポーネントを生成する関数

import { ComponentType, FC } from 'react';
import { getDisplayName } from './get_display_name';

const propConvertor = <T, U>(
  Component: ComponentType<T>,
  convertFunc: (originalProps: U) => T
): FC<U> => {
  const converted: FC<U> = props => {
    const convertedProps = convertFunc(props);
    return <Component {...convertedProps} />;
  };

  if(process.env.NODE_ENV !== 'production') {
    converted.displayName = `Converted(${
      getDisplayName(Component as any) || 'Unknown'
    })`;
  }

  return converted;
};

export { propConvertor };
