// テーブルの中身を表示するコンポーネント

import { memo } from 'react';
import { ColumnsTable } from './ColumnsTable';
import { ReflectionsTable } from './ReflectionsTable';
import { WbrByUnderscore } from './WbrByUnderscore';
import { DummyComponent } from './DummyComponent';
import { SuperClassInfo } from './table_detail/SuperClassInfo';


const EXCLUDE_COLUMNS = ['created_at', 'updated_at'];

const contentRowClass = item => item.deprecated ? 'deprecated' : '';

const ContentRow = ({columnNames, item}) => (
  <tr className={contentRowClass(item)}>
    {
      columnNames.map(col => <td key={col}>{item[col] + ''}</td>)
    }
  </tr>
);

const Contents = ({columns, contents}) => {
  if(contents.length === 0) return null;
  const columnNames =
    columns.map(item => item.name).filter(
      item => EXCLUDE_COLUMNS.indexOf(item) === -1
    );
  return(
    <>
      <h3 className="jeb">テーブルの内容（タイムスタンプは除く）</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            {
              columnNames.map(col => <th key={col}>{col}</th>)
            }
          </tr>
          {
            contents.map(
              item => <ContentRow key={item.id} columnNames={columnNames} item={item} />
            )
          }
        </tbody>
      </table>
    </>
  );
};

const MultiColumnIndexes = ({indexes}) => {
  // 複数列にかかるものだけフィルタリング
  const multiIndexes = indexes.filter(({columns}) => columns.length > 1);
  if(multiIndexes.length === 0) return null;
  return (
    <>
      <h3 className="jeb">複合インデックス</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>名前</th>
            <th>列</th>
            <th>UNIQUE</th>
          </tr>
          {
            multiIndexes.map(item => (
              <tr key={item.name}>
                <td>
                  <WbrByUnderscore text={item.name} />
                </td>
                <td>{item.columns.join(', ')}</td>
                <td>{item.unique && 'UNIQUE'}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
};

function RawTableDetail(props) {
  const {data, onRequestTable} = props;
  if(!data) return <p>ロード中…</p>;
  if(data.error) return (
    <p className="text-danger">{data.error}</p>
  );
  return (
    <>
      <h2>
        {data.name}
        <SuperClassInfo
          onRequestTable={onRequestTable}
          superclass={data.superclass}
        />
      </h2>
      <Contents columns={data.columns} contents={data.contents}
      />
      <ColumnsTable columns={data.columns} reflections={data.reflections}
        onRequestTable={onRequestTable} indexes={data.indexes}
      />
      <MultiColumnIndexes indexes={data.indexes} />
      <ReflectionsTable reflections={data.reflections}
        onRequestTable={onRequestTable}
        title="このテーブルからのリレーション"
      />
      <ReflectionsTable reflections={data.reverse_relations}
        onRequestTable={onRequestTable}
        title="このテーブルへのリレーション"
      />
    </>
  );
}

const TableDetail = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawTableDetail);

export { TableDetail };
