// .col-**-nから<AutosizeTextatra>まで一貫して作成するコンポーネント

import { FC, memo, ReactNode } from 'react';
import { AutosizeTextarea, WrappedAutosizeTextareaProps } from './AutosizeTextarea';
import { ColProps } from 'react-bootstrap/Col';
import { FormColumn } from './FormColumn';
import classNames from 'classnames';
import { useUniqueKey } from '../lib/common';

interface FormColumnedTextAreaProps extends Omit<ColProps, 'as'>,  Omit<WrappedAutosizeTextareaProps, 'as'>{
  formLabel?: ReactNode;
  error?: string | null;
}

const RawFormColumnedTextarea: FC<FormColumnedTextAreaProps> = props => {
  const {xs, sm, md, lg, xl, formLabel, error, ...inputProps} = props;
  const uniqueId = useUniqueKey();
  const realId = props.id || uniqueId;
  const realClass = classNames(
    'form-control', props.className, error && 'is-invalid'
  );

  return(
    <FormColumn
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} label={formLabel}
      controlId={realId}
    >
      <AutosizeTextarea {...inputProps}
        className={realClass} id={realId}
      />
      {
        error && (
          <div className="invalid-feedback">
            {error}
          </div>
        )
      }
    </FormColumn>
  );
};

RawFormColumnedTextarea.defaultProps = {
  rows: 3
};

const FormColumnedTextarea = memo(RawFormColumnedTextarea);

export { FormColumnedTextarea };

