// 見積書関連のreducer

import { nestedMerge, masterValues, taxedPrice, untaxedPrice, roundByFraction } from '../../lib/common';
import { sortReorderMerger } from './sort_reorder_merger';
import fromEntries from 'fromentries';
import { generateDetails } from './generate_details';
import { calculateTotalArea } from '../calculate_total_area';

const emptyArray = [] as const;

const DEFAULT_TAX_PERCENTAGE = '10.0';

// null、undefined、空文字列でないかをチェック（0だけ注意）
const isNumberParsable = (val: string | number | null | undefined): val is string | number => !!(val || val === 0);

// 「式」のID値
const SET_UNIT_ID = 900;

const estimateDetailReducer = (store: CoatingEstimateDetail, changes: Partial<CoatingEstimateDetail>): CoatingEstimateDetail => {
  const base = {...store, ...changes};
  // 「1式」の場合の処理
  if(changes.coating_estimate_unit_id === SET_UNIT_ID) {
    base.quantity = '1';
    base.unit_price = null;
  } else if('price' in changes) {
    // 合計金額を触った場合の処理
    base.unit_price = null;
  } else if(('quantity' in changes) || ('unit_price' in changes)) {
    // 単価・数量の変更時（両方入力されている場合のみ）
    if(isNumberParsable(base.quantity) && isNumberParsable(base.unit_price)) {
      // 面積は0.01㎡単位なので、いったん100倍の四捨五入で整数として正確な結果を出してから再度四捨五入
      base.price = Math.round(
        roundByFraction(+base.quantity * +base.unit_price)
      );
    }
  }
  return base;
};

const exclusionsByTypeId = fromEntries(
  masterValues('CoatingEstimateDetailType').map(item => [item.id, item.exclude_from_total])
);

const estimateReducer = (store: CoatingEstimate, changes: Partial<CoatingEstimate>): CoatingEstimate => {
  if(changes.coating_estimate_areas) {
    return {
      ...store,
      coating_estimate_areas: sortReorderMerger(
        store.coating_estimate_areas || emptyArray,
        changes.coating_estimate_areas[0]
      )
    };
  }
  if(changes.coating_estimate_details || changes._generateDetails) {
    const newDetails = changes.coating_estimate_details ? sortReorderMerger(
      store.coating_estimate_details || emptyArray,
      changes.coating_estimate_details[0],
      estimateDetailReducer
    ) : generateDetails(
      calculateTotalArea(store.coating_estimate_areas) + '',
      store.coating_estimate_type_id,
      store.coating_estimate_details
    );
    // ここで合計金額の算出
    // 削除済みのもの、合計反映しないものは除外
    const priceAvailables = newDetails.filter(
      item => !item._destroy && !exclusionsByTypeId[item.coating_estimate_detail_type_id + '']
    );
    const taxPercentage = +(store.tax_percentage || DEFAULT_TAX_PERCENTAGE) / 100;
    const untaxedTotal = priceAvailables.reduce((total, row) => total + (row.price || 0), 0);
    const baseTaxedTotal = taxedPrice(untaxedTotal, taxPercentage);
    const taxed_total = Math.floor(baseTaxedTotal / 1000) * 1000;
    const tax = taxed_total - untaxedPrice(taxed_total, taxPercentage);

    return {
      ...store,
      coating_estimate_details: newDetails,
      taxed_total, tax
    };
  }

  return {...store, ...changes};
};

const estimateSetReducer = (store: CoatingEstimateSet, changes: Partial<CoatingEstimateSet>): CoatingEstimateSet => {
  if(changes.coating_estimates) {
    return {
      ...store,
      coating_estimates: nestedMerge(
        store.coating_estimates || emptyArray,
        changes.coating_estimates[0],
        estimateReducer
      )
    };
  }
  return {...store, ...changes};
};

export default estimateSetReducer;
