// セクションへのリンク用コンポーネント

import { FC, memo } from 'react';
import { headerOrder, headerCoating } from './header_ids';

const RawCustomerSectionLinks: FC = () => {
  return(
    <div className="row mb-3">
      <div className="col">
        <a href={'#' + headerOrder} className="btn btn-block btn-outline-secondary">
          依頼
        </a>
      </div>
      <div className="col">
        <a href={'#' + headerCoating} className="btn btn-block btn-outline-secondary">
          施工
        </a>
      </div>
      <div className="col"></div>
    </div>
  );
};

const CustomerSectionLinks = memo(RawCustomerSectionLinks);

export { CustomerSectionLinks };
