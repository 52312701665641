// その他ファイル1枚分のコンポーネント（汎用のFileFrameを経由）

import { FC, useCallback } from 'react';
import { FileFrame, FileDescription } from '../../../react-components';
import { useNestedIdentifier } from '../../../lib/common';

const JebFileFrame: FC<{
  file: Jeb.File;
  dispatch?: (changes: Partial<Jeb.Coating>) => void;
}> = ({file, dispatch}) => {
  const idObject = useNestedIdentifier(file);
  const itemDispatch = useCallback((item: Partial<Jeb.File>) => {
    dispatch && dispatch({files: [{...item, ...idObject}]});
  }, [dispatch, idObject]);

  return(
    <FileFrame
      file={file}
      dispatch={itemDispatch}
      metadataComponent={FileDescription}
    />
  );
};

export { JebFileFrame };
