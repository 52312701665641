// ファイルアップロード部分のコンポーネント

import { FC, memo, useCallback } from 'react';
import { FileAppendFrame } from '../../react-components';
import { JebFileFrame } from './files/JebFileFrame';
import { generateUniqueKey, arrayFirstCache, propConvertor } from '../../lib/common';

type JebFileProps = {
  files: readonly Jeb.File[];
  dispatch(changes: Partial<Jeb.Coating>): void;
  typeId: number;
  title?: string;
};

const RawJebFiles: FC<JebFileProps> = ({files, dispatch, typeId, title}) => {
  const handleAppend = useCallback((file: File) => {
    dispatch({files: [{file, newId: generateUniqueKey(), jeb_file_type_id: typeId}]});
  }, [dispatch, typeId]);

  // Hooks定義完了

  return(
    <>
      <h3>{title || '関連ファイル'}</h3>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mb-3">
        {
          files.map(obj => (
            <JebFileFrame key={obj.id || obj.newId} file={obj} dispatch={dispatch} />
          ))
        }
        <FileAppendFrame onAppend={handleAppend} multiple />
      </div>
    </>
  );
};

const convertFunc: (props: JebFileProps) => JebFileProps = ({files, ...rest}) => ({
  ...rest,
  files: arrayFirstCache(
    files.filter(file => !file._destroy && file.jeb_file_type_id === rest.typeId)
  )
});

// files全体が変わらないときには、外側でメモ化可能

const JebFiles = memo(propConvertor(
  memo(RawJebFiles),
  convertFunc
));

export { JebFiles };
