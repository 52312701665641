// ラベル付きのform-columnの作成

import { FC, ReactNode } from 'react';
import FormGroup, { FormGroupProps } from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Col, { ColProps } from 'react-bootstrap/Col';

interface FormColumnProps extends ColProps, FormGroupProps{
  label?: ReactNode;
}

const FormColumn: FC<FormColumnProps> = (props) => {
  const {label, ...rest} = props;

  return(
    <FormGroup as={Col} {...rest} >
      {label != null && (
        <FormLabel>
          {label}
        </FormLabel>
      )}
      {props.children}
    </FormGroup>
  );
};

export { FormColumn };
