// ジェブへの施工依頼を編集するコンポーネント
// 値がundefinedの場合は何も書き出さない（必須もかけない）ことに注意

import { FC, memo, useCallback } from 'react';
import { useKeyValDispatch, masterValues, pickNumberFromEvent, humanColumnName, defaultValuePicker, generateUniqueKey } from '../../lib/common';
import { FormColumnedSelect, FormColumnedInput, FormColumnedTextarea, FileAppendFrame } from '../../react-components';
import { OrderDateColumn } from './order/OrderDateColumn';
import { OrderWaxRow } from './order/OrderWaxRow';
import { JebOrderFileFrame } from './order/JebOrderFileFrame';
import headingStyles from '../headings.module.scss';
import { headerOrder } from '../header_ids';

// デフォルトの値
const initialOrder: Jeb.Order = {with_wax: false};

const emptyList = [['', '']] as DropdownList;

const customerAcceptanceTypes = emptyList.concat(
  masterValues('CustomerAcceptanceType').map(item => [item.id + '', item.name])
);

const orderTypes = emptyList.concat(
  masterValues('OrderType').map(item => [item.id + '', item.name])
);

const RawJebOrderForm: FC<{
  order: Jeb.Order | undefined;
  dispatch(changes: Partial<Jeb.Coating>): void;
  readOnly?: boolean;
}> = ({order, dispatch, readOnly}) => {

  const isNew = !order;
  const currentOrder = order || initialOrder;
  // 初期化時だけ上書きするような処理が必要
  const itemDispatch = useCallback((value: Partial<Jeb.Order>) => {
    const order = isNew ? {...initialOrder, ...value} : value;
    dispatch({order});
  }, [isNew, dispatch]);

  const handleChangeAcceptanceId = useKeyValDispatch(itemDispatch, 'customer_acceptance_type_id', pickNumberFromEvent);
  const handleChangeOrderType = useKeyValDispatch(itemDispatch, 'order_type_id', pickNumberFromEvent);
  const handleChangeKeyNumber = useKeyValDispatch(itemDispatch, 'construction_key_number', defaultValuePicker);
  const handleChangeKeyPlace = useKeyValDispatch(itemDispatch, 'construction_key_place', defaultValuePicker);
  const handleChangeFloorMaterial = useKeyValDispatch(itemDispatch, 'floor_material', defaultValuePicker);
  const handleChangeDescription = useKeyValDispatch(itemDispatch, 'description', defaultValuePicker);
  const handleChangeMemo = useKeyValDispatch(itemDispatch, 'memo', defaultValuePicker);
  const handleAppend = useCallback((file: File) => {
    itemDispatch({files: [{file, newId: generateUniqueKey()}]});
  }, [itemDispatch]);

  // Hooks定義完了

  const availableFiles = (currentOrder.files || []).filter(item => !item._destroy);

  return(
    <>
      <h2 className={headingStyles.polusHeading} id={headerOrder}>
        依頼プロセス
        { readOnly && '（変更不可）' }
      </h2>
      <div className="row">
        <OrderDateColumn
          column="accepted_date" order={currentOrder} dispatch={itemDispatch}
          readOnly={readOnly}
        />
        <OrderDateColumn
          column="order_date" order={currentOrder} dispatch={itemDispatch}
          readOnly={readOnly}
        />
        <FormColumnedSelect sm={6} md={3}
          label={humanColumnName('Jeb::Order', 'customer_acceptance_type_id')}
          list={customerAcceptanceTypes}
          value={(currentOrder.customer_acceptance_type_id || '') + ''}
          onChange={handleChangeAcceptanceId}
          required={!isNew && !readOnly}
          readOnly={readOnly}
        />
        <FormColumnedSelect sm={6} md={3}
          label={humanColumnName('Jeb::Order', 'order_type_id')}
          list={orderTypes}
          value={(currentOrder.order_type_id || '') + ''}
          onChange={handleChangeOrderType}
          required={!isNew && !readOnly}
          readOnly={readOnly}
        />
      </div>
      <div className="row">
        <OrderDateColumn
          column="coating_date" order={currentOrder} dispatch={itemDispatch}
          required={!isNew && !readOnly} readOnly={readOnly}
        />
        <FormColumnedInput sm={6} md={3}
          label={humanColumnName('Jeb::Order', 'construction_key_number')}
          value={currentOrder.construction_key_number || ''}
          onChange={handleChangeKeyNumber}
          readOnly={readOnly}
        />
        <FormColumnedInput md={6}
          label={humanColumnName('Jeb::Order', 'construction_key_place')}
          value={currentOrder.construction_key_place || ''}
          onChange={handleChangeKeyPlace}
          readOnly={readOnly}
        />
        <FormColumnedInput md={6}
          label={humanColumnName('Jeb::Order', 'floor_material')}
          value={currentOrder.floor_material || ''}
          onChange={handleChangeFloorMaterial}
          required={!isNew}
          readOnly={readOnly}
        />
      </div>
      <OrderWaxRow dispatch={itemDispatch} required={!isNew}
        value={currentOrder.with_wax}
        readOnly={readOnly}
      />
      <div className="row">
        <FormColumnedTextarea
          formLabel={humanColumnName('Jeb::Order', 'description')}
          value={currentOrder.description || ''}
          required={!isNew && !readOnly}
          onChange={handleChangeDescription}
          readOnly={readOnly}
        />
      </div>
      <div className="row">
        <FormColumnedTextarea
          formLabel={humanColumnName('Jeb::Order', 'memo')}
          value={currentOrder.memo || ''}
          onChange={handleChangeMemo}
          readOnly={readOnly}
        />
      </div>
      <h3>申込書などのアップロード</h3>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mb-3">
        {
          availableFiles.map(obj => (
            <JebOrderFileFrame key={obj.id || obj.newId} file={obj} dispatch={readOnly ? undefined : itemDispatch} />
          ))
        }
        {
          readOnly || (
            <FileAppendFrame onAppend={handleAppend} multiple />
          )
        }
      </div>
    </>
  );
};

const JebOrderForm = memo(RawJebOrderForm);

export { JebOrderForm };
