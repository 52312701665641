// 日付オブジェクトの文字列化

import padStart from 'pad-start';

const dateToString = (date: Date): string => {
  return [
    padStart(date.getFullYear() + '', 4, '0'),
    padStart((date.getMonth() + 1) + '', 2, '0'),
    padStart(date.getDate() + '', 2, '0')
  ].join('/');
};

export { dateToString };
