// 仮環境に入った場合、最初1回だけメッセージを表示する

const STORAGE_KEY = 'cloud-order-temp-checked';

const TEMP_SERVER = 'temp.cloud-order.com';

document.addEventListener('DOMContentLoaded', () => {
  if(location.host !== TEMP_SERVER) return;
  if(sessionStorage.getItem(STORAGE_KEY)) return;
  sessionStorage.setItem(STORAGE_KEY, 'true');
  if(!confirm('この環境は、クラウドオーダーのサーバ検証用です。予告なくバージョン変更・停止することがあります。\n\n続けますか？\n（「いいえ」あるいは「キャンセル」を選択すると、本番環境に移動します）')){
    location.href = 'https://www.cloud-order.com';
  }
});
