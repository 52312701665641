// アンダーバーごとにwbrで区切った文言を生成するコンポーネント

import { FC, memo } from 'react';
import { JoinedArray } from '../react-components';

const RawWbrByUnderscore: FC<{text: string | null | undefined | false}> = ({text}) => {
  if(!text) return null;
  // アンダーバーの直後で区切る
  // グローバルな正規表現で、全部まとめてマッチ
  const arr = text.match(/([^_]*_|[^_]+$)/g);
  return <JoinedArray array={arr as string[]} joiner={<wbr />} />;
};

const WbrByUnderscore =   process.env.NODE_ENV === 'production' ?
  (): null => null:
  memo(RawWbrByUnderscore);

export { WbrByUnderscore };
