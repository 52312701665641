// 税込み額の計算（補正あり）

import { taxedBase } from './taxed_base';

const taxedPrice = (untaxed: number, tax_ratio: number): number => {
  const base = taxedBase(untaxed, tax_ratio);
  if ((base % 10) !== 1)
    return base;
  const last = taxedBase(untaxed - 1, tax_ratio);
  return (base - last === 1) ? base : base - 1;
};

export { taxedPrice };
