// テーブル全体をクリックしてリンクできるようにする制御

import { closest, turbolinksVisit, onReady, delegate } from '../lib/common';

const ACTIVE_ELEMENTS = ['a', 'label', 'input', 'textarea', 'button', 'select'];
const ACTIVE_ELEMENTS_SELECTOR = ACTIVE_ELEMENTS.join(',');

// divもサポート
const TABLE_TAGS = ['tr', 'td', 'th', 'thead', 'tfoot', 'tbody', 'table', 'div'];
const selector = TABLE_TAGS.map(str => str + '[data-link-to]').join(',');

// それ自体が反応する要素、あるいはその内側だった場合には、除外する
const validTarget: (target: Element) => boolean = target => {
  return !closest(target, ACTIVE_ELEMENTS_SELECTOR);
};

const visit: (elem: HTMLElement) => void = elem => {
  const url = elem.dataset.linkTo as string;
  if(elem.dataset.newWindow) {
    open(url);
  }
  else {
    turbolinksVisit(url);
  }
};

onReady(() => {
  // thisを取りたいので、functionで書くしかない
  delegate(document, selector, 'click', function(e){
    if(!validTarget(e.target as Element)) return;
    visit(this as HTMLElement);
  });

  delegate(document, selector, 'mousedown', function(raw){
    const e = raw as MouseEvent;
    // センタークリックの場合のみ
    if(e.button !== 1) return;
    if(!validTarget(e.target as Element)) return;
    open((this as HTMLElement).dataset.linkTo);
  });
});
