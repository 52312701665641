import { useCallback, useState } from 'react';

// UncontrolledとControlledで、同じように値とセッターを取得できるHook
const useUncontrollable = <T>(
  value: T | undefined,
  defaultValue: T,
  setValue: ((value: T) => unknown) | undefined
): [T, (value: T) => unknown] => {
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);
  const setter = useCallback(newValue => {
    setValue !== undefined ? setValue(newValue) : setUncontrolledValue(newValue);
  }, [setValue]);
  const currentValue = value != undefined ? value : uncontrolledValue;
  return [currentValue, setter];
};

export { useUncontrollable };
