// Turbolinksの調整

import styles from './turbolinks-loading-style.module.scss';

const LOADING_CLASS = styles.loading;

document.addEventListener(
  'turbolinks:visit',
  () => {
    document.body.classList.add(LOADING_CLASS);
  },
  false
);

document.addEventListener(
  'turbolinks:load',
  () => {
    document.body.classList.remove(LOADING_CLASS);
  },
  false
);

// ES6 Moduleとして認識させるためのダミー
export default 0;
