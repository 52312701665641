// 配列を指定したものでjoinするコンポーネント

import { Fragment, memo, ReactElement, ReactNode } from 'react';

type JoinerProps = {
  array: ReactNode[];
  joiner: ReactNode;
};

const RawJoinedArray: (props: JoinerProps) => ReactElement =
({array, joiner}) => (
  <>
    {
      array.map((row, i) => (
        <Fragment key={`${i}:${row}`}>
          { i > 0 && joiner}
          {row}
        </Fragment>
      ))
    }
  </>
);

const JoinedArray = memo(RawJoinedArray);

export { JoinedArray };
