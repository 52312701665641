// 値の変換関数を呼んで、それを経由してsetterを呼び出すHooks

import { useCallback } from 'react';

const useConvertedSetter = <T, U>(
  setter: (value: T) => void,
  converter: (originalValue: U) => T
): (value: U) => void => {
  return useCallback(val => setter(converter(val)), [converter, setter]);
};

export { useConvertedSetter };
