// 操作パネル部分のコンポーネント

import { ChangeEvent, FC, memo } from 'react';
import { DummyComponent } from './DummyComponent';

type ControlPanelComponent = FC<{
  value: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
}>;

const RawControlPanel: ControlPanelComponent = ({value, onChange}) => (
  <h3 className="jeb form-inline">
    クラスを検索&#x3000;
    <input type="text" className="form-control"
      value={value}
      placeholder="文字単位の部分一致で検索"
      onChange={onChange}
    />
  </h3>
);

const ControlPanel: ControlPanelComponent = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawControlPanel);

export { ControlPanel };
