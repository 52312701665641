import { closest } from './closest';

// $().onのように、バブリングを利用して上位エレメントにイベント処理をぶら下げる

const delegate = (
  element: EventTarget, selector: string, eventType: string,
  handler: (this: Element, e: Event) => boolean | void
): void => {
  element.addEventListener(eventType, e => {
    const target = closest(e.target as Element, selector);
    if(target instanceof Element && handler.call(target, e) === false) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
};

export { delegate };
