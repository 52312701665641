// メッセージポップアップの表示用ボタン

import { FC, memo, useCallback, useState } from 'react';
import styles from './messages-button.module.scss';
import { MemoizedIcon, promisedComponent, loadDeferredPromise, useViewData } from '../lib/common';
import { faComments } from '@fortawesome/free-regular-svg-icons';

const modalPromise = loadDeferredPromise.then(({InterCompanyMessagesModal}) => ({default: InterCompanyMessagesModal}));

const LazyModal = promisedComponent(
  () => modalPromise
);

const RawMessagesButton: FC = () => {
  const rawViewData = useViewData() as {
    customer: Customer,
    messages_count: number,
    unread_count: number
  };
  const customerId = rawViewData.customer.id;
  const [show, setShow] = useState(false);
  const allCount = rawViewData.messages_count;
  const [unread, setUnread] = useState(rawViewData.unread_count);

  const handleClick = useCallback(() => {
    setShow(true);
    setUnread(0);
  }, []);
  // Hooks定義完了

  if(!customerId) return null;

  return(
    <>
      <span className={styles.messagesButton} onClick={handleClick}>
        <MemoizedIcon icon={faComments} className={styles.icon} size="3x" />

        {
          allCount > 0 && (
            <span className={styles.allCount}>{allCount}</span>
          )
        }
        {
          unread > 0 && (
            <div className={styles.unread}>未読: {unread}</div>
          )
        }
      </span>
      <LazyModal show={show} setShow={setShow} customerId={customerId} />
    </>
  );
};

const MessagesButton = memo(RawMessagesButton);

export { MessagesButton };
