// 幅広いリストの1行

import { FC, memo } from 'react';
import { CustomerInList } from './customer_with_coating_date';
import { JebProcessLabel } from './JebProcessLabel';
import { formatCoatingDate } from './format_coating_date';

const RawWideRow: FC<{customer: CustomerInList}> = ({customer}) => {
  // Hooks定義完了

  const editUrl = '/customers/' + customer.id + '/edit';
  const jebCoating = customer.jeb_coating;

  return(
    <tr data-link-to={editUrl}>
      <td><JebProcessLabel id={jebCoating?.jeb_process_id} /></td>
      <td><a href={editUrl}>{customer.name}</a></td>
      <td>{customer.new_address}</td>
      <td>{customer.telephone1}</td>
      <td>{formatCoatingDate(customer.coating_date)}</td>
      <td>{jebCoating ? 'ジェブ' : '未定'}</td>
      <td>{customer.company_account_ids?.length ? 'あり' : 'なし'  }</td>
    </tr>
  );
};

const WideRow = memo(RawWideRow);

export { WideRow };
