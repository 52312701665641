// ジェブ担当者1行分

import { FC, memo, useMemo, useState } from 'react';
import { useFixedParamCallback, useViewData } from '../../../lib/common';
import { LazyEmployeeModal } from './LazyEmployeeModal';

const RawJebEmployee: FC<{employee: Jeb.Employee}> = ({employee}) => {
  const [open, setOpen] = useState(false);
  const isAdmin = (useViewData() as {is_admin: boolean}).is_admin;

  const handleEdit = useFixedParamCallback(setOpen, true);

  const roleIds = employee.role_ids;
  const {deprecated} = employee;
  const roleText = useMemo(() => {
    if(deprecated) return '退職・無効';
    if(!roleIds) return '不明';
    if(roleIds.length === 0) return '一般';
    // とりあえず管理者しかいないので、仮組み
    return '管理者';
  }, [roleIds, deprecated]);

  // Hooks定義完了

  return(
    <tr>
      <td>{employee.name}</td>
      <td>{employee.company_account.email}</td>
      <td>{employee.short_name}</td>
      <td>{roleText}</td>
      {
        isAdmin && (
          <td>
            <button className="btn btn-primary float-right" type="button"
              onClick={handleEdit}
            >
              編集
            </button>
            <LazyEmployeeModal open={open}
              onChangeOpen={setOpen}
              defaultEmployee={employee}
            />
          </td>
        )
      }
    </tr>
  );
};

const JebEmployee = memo(RawJebEmployee);

export { JebEmployee };
