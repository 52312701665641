// displayNameを取得する関数

import {isMemo} from 'react-is';
import { ComponentType, MemoExoticComponent } from 'react';

const getDisplayNameDev = (Component: ComponentType): string => {
  const {displayName} = Component;
  if(displayName) return displayName;
  // メモ化のときは中身を処理する
  // isMemoはコンポーネントではなく、JSX要素を調べる関数
  if(isMemo(<Component />)){
    const baseName = getDisplayNameDev((Component as MemoExoticComponent<any>).type);
    if(!baseName) return 'Memo(Unknown)';
    // Raw***の命名規則を尊重する形で実装
    const match = /^Raw(.+)$/.exec(baseName);
    if(match) return match[1];
    return `Memo(${baseName})`;
  }
  return Component.name;
};

const dummy = (): string => '';

const getDisplayName =
  process.env.NODE_ENV === 'production' ? dummy : getDisplayNameDev;

export { getDisplayName };
