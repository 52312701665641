// 表示中にはpingを送る仕組み

import { RailsFetch } from '../lib/common';

const PING_URL = '/api/notification_ping';

// 有効なIDは0より大きい
let timerId = 0;

// const TOAST_TIME = 30 * 1000;

const onExpired: () => void = () => {
  // TODO: dirty時の処理
  location.reload();
};

document.addEventListener('DOMContentLoaded', () => {
  timerId = window.setInterval(() => {
    // いまのところ、thenで受け取った値を処理する必要性はない
    RailsFetch(PING_URL).catch((val) => {
      const xhr = (val as [unknown, string, XMLHttpRequest])[2];
      if(xhr.status === 403) onExpired();
    });
  }, 60 * 1000);
}, false);
