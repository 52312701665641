import { memo, useCallback, useMemo, useState } from 'react';

const BELONGS_TO_CLASS_NAME = 'BelongsToReflection';

import { ColumnRow } from './ColumnRow';
import { sortOrOriginal } from '../lib/common';
import { DummyComponent } from './DummyComponent';

// リスト類のキャッシュ機構

const baseSortFunc = (a, b) => {
  if(a.name < b.name) return -1;
  if(a.name > b.name) return 1;
  return 0;
};

const sortFunctions = {
  asc: baseSortFunc,
  desc: (a, b) => baseSortFunc(b, a)
};

const nextSorts = {
  none: 'asc',
  asc: 'desc',
  desc: 'none'
};

const sortIndicators = { asc: '▲', desc: '▼' };

const getReflectionsHash = (reflections) => {
  if(!reflections) return {};
  const reflectionHash = {};
  reflections.filter(
    ({reflection_type}) => reflection_type === BELONGS_TO_CLASS_NAME
  ).forEach(item => reflectionHash[item.foreign_key] = item);
  return reflectionHash;
};

const getSimpleIndexes = (indexes) => {
  if(!indexes) return {};
  const simpleIndexes = {};
  indexes.forEach(({columns, unique}) => {
    if(columns.length > 1) return;
    simpleIndexes[columns[0]] = {unique};
  });
  return simpleIndexes;
};

function RawColumnsTable({
  onRequestTable, reflections, indexes, columns
}){
  const [sort, setSort] = useState('none');
  const handleClickColumnName = useCallback(
    () => setSort(sort => nextSorts[sort]), []
  );

  const sorter = sortFunctions[sort];

  const sortedArr = useMemo(() => {
    if(!sorter) return columns;
    return sortOrOriginal(columns, sorter);
  }, [sorter, columns]);

  const reflectionHash = useMemo(
    () => getReflectionsHash(reflections),
    [reflections]
  );
  const simpleIndexes = useMemo(() => getSimpleIndexes(indexes), [indexes]);

  // Hooks 定義完了

  const anyHumanNames = columns.some(col => col.human_name);
  return (
    <>
      <h3 className="jeb">テーブル定義</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th onClick={handleClickColumnName}
              style={{cursor: 'pointer'}}
            >
              列名
              { sortIndicators[sort] }
            </th>
            {
              anyHumanNames && <th>和名</th>
            }
            <th>SQL型</th>
            <th>index</th>
            <th>初期値</th>
            <th>NULL</th>
          </tr>
          {
            sortedArr.map(
              item => (
                <ColumnRow key={item.name} column={item}
                  reflection={reflectionHash[item.name]}
                  index={simpleIndexes[item.name]}
                  onRequestTable={onRequestTable}
                  withHumanName={anyHumanNames}
                />
              )
            )
          }
        </tbody>
      </table>
    </>
  );
}

const ColumnsTable = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawColumnsTable);

export { ColumnsTable };
