// ポラスアカウント一覧のコンポーネント

import { FC, useMemo, useState } from 'react';
import { useViewData, useFixedParamCallback, defaultCompare, sortOrOriginal } from '../../lib/common';
import { UserRow } from './UserRow';
import { PromisedModal } from './PromisedModal';
import { SortHeader } from '../../react-components';

type CompareFunc = (x: User, y: User) => number;

const specialSorts: {[x: string]: CompareFunc} = {
  role(x, y){
    const roleCount = (e: User): number => {
      if(e.deprecated) return 0;
      return e.role_ids?.length ? 2 : 1;
    };
    return roleCount(x) - roleCount(y);
  }
};

const sortCriterium = (sortKey: string): CompareFunc => {
  const isDesc = sortKey[0] === '-';
  const sortKeyOnly = isDesc ? sortKey.slice(1) : sortKey;
  const coef = isDesc ? -1 : 1;

  if(sortKeyOnly in specialSorts) {
    return (x, y): number => specialSorts[sortKeyOnly](x, y) * coef;
  }

  // ひとまず、指定列を抽出して比較する関数を作る（特殊ケースは後で対応）
  return (x: any, y: any): number => defaultCompare(x[sortKeyOnly], y[sortKeyOnly]) * coef;
};

const sortUsers = (
  users: readonly User[], sortKey: string
): readonly User[] => {

  return sortOrOriginal(users, sortCriterium(sortKey));
};

const Users: FC = () => {
  // ひとまずページ更新で処理させるので、
  // いったんはそのままUsersを描画するだけに
  const viewData = useViewData() as {users: User[]; is_admin: boolean};
  const {users} = viewData;
  const [open, setOpen] = useState(false);
  const handleClickAdd = useFixedParamCallback(setOpen, true);
  const [sortKey, setSortKey] = useState('name');
  const sortedUsers = useMemo(() => sortUsers(users, sortKey), [users, sortKey]);

  // Hooks定義完了

  return(
    <table className="table">
      <tbody>
        <tr>
          <SortHeader sortKey="name"
            currentSortKey={sortKey}
            setSortKey={setSortKey}
            title="氏名"
          />
          <SortHeader sortKey="email"
            currentSortKey={sortKey}
            setSortKey={setSortKey}
            title="メールアドレス"
          />
          <SortHeader sortKey="telephone"
            currentSortKey={sortKey}
            setSortKey={setSortKey}
            title="電話番号"
          />
          <SortHeader sortKey="role"
            currentSortKey={sortKey}
            setSortKey={setSortKey}
            title="権限/状態"
          />
          <th className="text-right">
            {
              viewData.is_admin && (
                <button className="btn btn-success" type="button"
                  onClick={handleClickAdd}
                >
                  追加
                </button>
              )
            }
            <PromisedModal open={open} onChangeOpen={setOpen} />
          </th>
        </tr>
        {
          sortedUsers.map(user => (
            <UserRow user={user} key={user.id} />
          ))
        }
      </tbody>
    </table>
  );
};

export { Users };
