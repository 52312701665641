import { onBeforeRenderByController, renderReactRoot } from '../../lib/common';
import { CompanyCustomerForm } from './react_components/CompaniesCustomerForm';

onBeforeRenderByController('customers', body => {
  const root = body.querySelector('#companies_customer_input_base');
  if(root) renderReactRoot(
    root as HTMLElement,
    <CompanyCustomerForm />
  );
});

if(process.env.NODE_ENV === 'development' && module.hot){
  module.hot.accept();
}
