// deferredのロードを一元化する関数

import { promise as onReadyPromise } from './on_ready';

// Turbolinksより先回りできることを保証
import './confirm_page_transition';

// 毎回Promiseを作るのは無駄なので削減
// onReadyまで遅らせる
// そして、遅延でスタートするルーチンもあるので最初から動かす
const loadDeferredPromise = onReadyPromise.then(
  () => import(/* webpackChunkName: "deferred" */ '../../deferred/index')
);

export { loadDeferredPromise };
