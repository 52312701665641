import { CSSProperties, FC, memo, ReactNode, useCallback } from 'react';

const cursorPointer: CSSProperties = {cursor: 'pointer', userSelect: 'none'};

const RawSortHeader: FC<{
  sortKey: string;
  currentSortKey: string;
  setSortKey: (newKey: string) => void;
  title: ReactNode;
  defaultDesc?: boolean;
}> = ({sortKey, setSortKey, currentSortKey, title, defaultDesc}) => {

  const isDesc = currentSortKey[0] === '-';
  const currentKeyOnly = isDesc ? currentSortKey.slice(1) : currentSortKey;
  const isActive = currentKeyOnly === sortKey;

  const handleClick = useCallback(() => {
    const prefix = (isActive ? !isDesc : defaultDesc) ? '-' : '';
    setSortKey(prefix + sortKey);
  }, [defaultDesc, isActive, isDesc, setSortKey, sortKey]);

  // Hooks定義完了

  return(
    <th onClick={handleClick} style={cursorPointer}>
      {title}
      {
        isActive && (
          ' ' + (isDesc ? '▼' : '▲')
        )
      }
    </th>
  );
};

const SortHeader = memo(RawSortHeader);

export { SortHeader };
