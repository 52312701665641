// ボタン式ラジオボタンの1つ分

import { FC, memo, useContext, useMemo } from 'react';
import { useFixedParamCallback } from '../lib/common';
import { ButtonRadiosContext } from './ButtonRadiosContext';


interface ButtonRadioProps{
  value: string;
  text: string;
  checked: boolean;
  focusing: boolean;
}

const RawButtonRadio: FC<ButtonRadioProps> = ({
  value, text, checked, focusing
}) => {
  const {setFocusName, toggledClass, ...commonProps} = useContext(ButtonRadiosContext);
  const {disabled} = commonProps;
  const labelClass = useMemo(() => {
    const initial = 'btn active btn-';
    const checkedBase = initial + (
      typeof toggledClass === 'function' ? toggledClass(value) : toggledClass
    );
    const base = checked ? checkedBase :
      'btn btn-outline-success';
    const withFocus = focusing ? base + ' focus' : base;
    return disabled ? withFocus + ' disabled' : withFocus;
  }, [checked, disabled, focusing, toggledClass, value]);
  const handleFocus = useFixedParamCallback(setFocusName, value);
  const handleBlur = useFixedParamCallback(setFocusName, null);

  return(
    <label className={labelClass}
      onFocus={handleFocus} onBlur={handleBlur}
    >
      <input type="radio"
        value={value}
        checked={checked}
        {...commonProps}
      />
      {text}
    </label>
  );
};

const ButtonRadio = memo(RawButtonRadio);

export { ButtonRadio };
