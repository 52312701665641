import { ChangeEvent, createContext } from 'react';
import { noop } from '../lib/common';


type ToggledClassGenerator = (value: string) => string;

interface ButtonRadiosContextType {
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  name: string;
  toggledClass?: string | ToggledClassGenerator;
  form?: string;
  setFocusName(name: string | null): void;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

const ButtonRadiosContext = createContext<ButtonRadiosContextType>({
  name: '',
  setFocusName: noop,
  onChange: noop
});

export { ButtonRadiosContext };
