// Reactのルートタグをセットする（Turbolinksに合わせて外す機能あり）

import ReactDOM from 'react-dom';
import { renderReactWithTurbolinks } from './render_react_with_turbolinks';
import { wrapBaseComponent } from './wrap_base_component';

const permanentIds: {[x: string]: boolean} = {};

const renderReactRoot = (
  container: HTMLElement,
  reactElement: React.ReactElement,
  onUnmounted? : () => unknown
): void => {
  const element = wrapBaseComponent(reactElement, container);
  if('turbolinksPermanent' in container.dataset) {
    const id = container.id;
    // permanentな場合

    // すでにマウントされていれば抜ける
    // Hot Replaceがかかっている場合の特殊措置を追加
    if(permanentIds[id] && (
      process.env.NODE_ENV === 'development' && module.hot && !document.body.contains(container)
    )) return;
    // before-renderで両方のbodyを比較して、なければ撤去
    // TODO: あとからセットした時に、二重追加にならないような対応
    document.addEventListener('turbolinks:before-render', (e) => {
      const newBody = e.data.newBody;
      if(newBody.querySelector('#' + id)) return;
      ReactDOM.unmountComponentAtNode(container);
      permanentIds[id] = false;
    });
    ReactDOM.render(element, container);
    permanentIds[id] = true;
  } else {
    renderReactWithTurbolinks(container, element, onUnmounted);
  }
};

export { renderReactRoot };
