import { fixedThumbnail } from './fixed_thumbnail';

// ファイルが適切なcontent-typeなのかの検証（デフォルト関数）

const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

const defaultCheckContentType = (contentType: string): boolean => {
  return !!(~IMAGE_TYPES.indexOf(contentType) || fixedThumbnail(contentType));
};

export { defaultCheckContentType };

