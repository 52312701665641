// ページ遷移前にワンショットで実行するハンドラ

const oneBeforeTransition = (func: () => unknown): void => {
  const handler = (): void => {
    func();
    document.removeEventListener('turbolinks:before-cache', handler, false);
    document.removeEventListener('turbolinks:before-render', handler, false);
  };
  document.addEventListener('turbolinks:before-cache', handler, false);
  document.addEventListener('turbolinks:before-render', handler, false);
};

export { oneBeforeTransition };
