// ページ遷移後に表示するフラッシュメッセージの設定

const FLASH_KEY = 'polus-flash-jfdlkh';

type FlashItem = {
  message: string;
  toastType?: ToastTypeString;
};


const getFlashes = (): FlashItem[] => {
  const current: FlashItem[] = JSON.parse(sessionStorage.getItem(FLASH_KEY) || '[]');
  return current;
};

const setFlash = (message: string, toastType?: ToastTypeString): void => {
  const current = getFlashes();
  current.push({message, toastType});
  sessionStorage.setItem(FLASH_KEY, JSON.stringify(current));
};

const clearFlash = (): void => {
  sessionStorage.setItem(FLASH_KEY, '[]');
};

export {
  clearFlash,
  getFlashes,
  setFlash
};
