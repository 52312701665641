// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require('@rails/activestorage').start();
// require('channels');

// 最初にBootstrapを読ませたいので先頭に移動
import '../src/application.scss';

// NODE_ENVの違うものは吹き飛ばしたいので、requireで処理
if(process.env.NODE_ENV === 'development') {
  require('../src/environment/development.scss');
}
if(process.env.NODE_ENV === 'staging') {
  require('../src/environment/staging.scss');
}

import '../application/data-link-to';
import '../application/hash_link';
import '../application/hmr-monitor';
import '../application/notification_ping';
import '../application/show_flash';
import '../application/temp_check';
import '../application/to_top_button';

import { loadDeferredPromise } from '../lib/common';

loadDeferredPromise.then(({Turbolinks, Rails}) => {
  Rails.start();
  Turbolinks.start();
});

import '../application/turbolinks-loading';

// ひとまず、使うものをマウントする形にしておく
import '../customers';
import '../companies/customers/mount_edit_form';
import '../jeb/employees/mount';
import '../master_records/mount';
import '../users/mount';

import '../images/navbar/logo.svg';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
