// 税抜額の計算（補正あり）

import { taxedPrice } from './taxed_price';

const untaxedPrice = (taxed: number, tax_ratio: number): number => {
  const base = Math.round(taxed / (1 + tax_ratio));
  if (taxed % 10)
    return base;
  if (taxedPrice(base, tax_ratio) === +taxed)
    return base;
  return base + 1;
};

export { untaxedPrice };
