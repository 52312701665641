import { nestedMerge } from '../../lib/common';
const emptyArray: never[] = [];

const orderReducer = (store: Jeb.Order | undefined, changes: Jeb.Order): Jeb.Order => {
  const original = store || {};
  if(changes.files) {
    return {
      ...store,
      files: nestedMerge(
        original.files || emptyArray, changes.files[0]
      )
    };
  }
  return {...store, ...changes};
};

const SUB_ITEMS = [
  'pre_estimate_progresses', 'telephone_processes', 'files', 'business_talks', 'repairs'
] as const;

const jebCoatingReducer = (store: Jeb.Coating, changes: Jeb.Coating): Jeb.Coating => {
  for(let i = 0; i < SUB_ITEMS.length; ++i) {
    const key = SUB_ITEMS[i];
    const change = changes[key];
    if(change) {
      return {
        ...store,
        [key]: nestedMerge(
          store[key] || emptyArray, change[0]
        )
      };
    }
  }
  if(changes.order) {
    return{
      ...store,
      order: orderReducer(store.order, changes.order)
    };
  }
  if(changes.estimate_files){
    // 選択中の送信ファイルを削除する場合、送信するIDもクリアする
    const newFiles = changes.estimate_files[0];
    const additional = (
      store.mail_estimate_file_id &&
      newFiles._destroy &&
      newFiles.id === store.mail_estimate_file_id
    ) ? {mail_estimate_file_id : null} : {};
    return {
      ...store,
      estimate_files: nestedMerge(
        store.estimate_files || emptyArray, newFiles
      ),
      ...additional
    };
  }
  return {...store, ...changes};
};

export { jebCoatingReducer };
