// 画面描画されたタイミングに発動するHooks

import { useContext, useLayoutEffect } from 'react';
import { BodyContext } from './BodyContext';
import { useMutableCallback } from './use_mutable_callback';

const TURBOLINKS_RENDER = 'turbolinks:render';

const useTurbolinksRendered = (func: () => void): void => {
  const mountedBody = useContext(BodyContext);
  const callback = useMutableCallback(func);

  useLayoutEffect(() => {
    const handler = (): void => {
      callback();
      // 相互参照が必要なので、どちらかを先に書くしかない
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      unmount();
    };
    const unmount = (): void => {
      document.removeEventListener(TURBOLINKS_RENDER, handler, false);
    };
    if(mountedBody === document.body) {
      callback();
    } else {
      document.addEventListener(TURBOLINKS_RENDER, handler, false);
      return unmount;
    }
  }, [callback, mountedBody]);
};

export { useTurbolinksRendered };
