// フォームバリデーションをチェックする関数

const INVALID_MESSAGE = '適切な入力がされていない項目があります。もう一度ご確認ください。';

const isValidForm = (form: HTMLFormElement): boolean => {
  const invalid: HTMLElement | null = form.querySelector(':invalid');
  if(invalid) {
    alert(INVALID_MESSAGE);
    // TODO: ジャンプを実装したら置き換え
    invalid.focus();
    return false;
  }
  const requiredList = form.querySelectorAll(':required:enabled');
  let blank: (HTMLElement | null) = null;
  [].some.call(
    requiredList,
    (elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
      // readonlyはここで弾く（selectで拾ってもundefinedなので無視）
      if((elem as HTMLInputElement | HTMLTextAreaElement).readOnly) return;
      if(/^\s*$/.test(elem.value)) {
        blank = elem;
        return true;
      }
    }
  );
  if(blank) {
    alert(INVALID_MESSAGE);
    // TODO: ジャンプを実装したら置き換え
    // なぜかblankがnull推定されてしまう
    (blank as HTMLElement).focus();
    return false;
  }
  return true;
};

export { isValidForm };
