// 全体から共通して使うライブラリ

import { arrayFirstCache } from './common/array_first_cache';
import { BodyContext } from './common/BodyContext';
import { checkPresent } from './common/check_present';
import { clearFlash, getFlashes, setFlash } from './common/flash';
import { closest } from './common/closest';
import { confirmPageTransition, unconfirmPageTransition } from './common/confirm_page_transition';
import { createReactModal } from './common/create_react_modal';
import { dateToString } from './common/date_to_string';
import { defaultCheckContentType } from './common/default_check_content_type';
import { defaultCompare } from './common/default_compare';
import { defaultValuePicker } from './common/default_value_picker';
import { delegate } from './common/delegate';
import { displayToast } from './common/display_toast';
import { fixedThumbnail } from './common/fixed_thumbnail';
import { generateSearchQuery } from './common/generate_search_query';
import { generateUniqueKey } from './common/generate_unique_key';
import { getPrefPrefixRegExp } from './common/get_pref_prefix_regexp';
import { getViewData } from './common/get_view_data';
import { humanColumnName } from './common/human_column_name';
import { identity } from './common/identity';
import interCompanyMessagesStyles from './common/inter_company_messages.module.scss';
import { isIos } from './common/is_ios';
import { isPolusMode } from './common/is_polus_mode';
import { isValidForm } from './common/is_valid_form';
import { loadDeferredPromise } from './common/load_deferred_promise';
import { makeFormData } from './common/make_form_data';
import { makeThrottleByFrame } from './common/make_throttle_by_frame';
import { masterValues } from './common/master_values';
import { matchedIndex } from './common/matched_index';
import { nestedMerge } from './common/nested_merge';
import { noop } from './common/noop';
import { onBeforeRenderByController } from './common/on_before_render_by_controller';
import { oneBeforeTransition } from './common/one_before_transition';
import { onHTMLLoadedByClasses } from './common/on_html_loaded_by_classes';
import { onReady } from './common/on_ready';
import { onRender } from './common/on_render';
import { pickNumberFromEvent } from './common/pick_number_from_event';
import { promisedComponent } from './common/promised_component';
import { propConvertor } from './common/prop_convertor';
import { RailsFetch } from './common/rails_fetch';
import { renderReactRoot } from './common/render_react_root';
import { roundByFraction } from './common/round_by_fraction';
import { scrollAnimate } from './common/scroll_animate';
import { sortOrOriginal } from './common/sort_or_original';
import { taxedPrice } from './common/taxed_price';
import { telephoneInputType } from './common/telephone_input_type';
import { toCommaSeparated } from './common/to_comma_separated';
import { turbolinksVisit } from './common/turbolinks_visit';
import { untaxedPrice } from './common/untaxed_price';
import { useConfirmedDestroy } from './common/use_confirmed_destroy';
import { useConvertedSetter } from './common/use_converted_setter';
import { useFixedParamCallback } from './common/use_fixed_param_callback';
import { useGridCondition } from './common/use_grid_condition';
import { useIsXsSm } from './common/use_is_xs_sm';
import { useIsXsSmMd } from './common/use_is_xs_sm_md';
import { useJSONRequester } from './common/use_json_requester';
import { useKeyValDispatch } from './common/use_key_val_dispatch';
import { useMutableCallback } from './common/use_mutable_callback';
import { useNestedIdentifier } from './common/use_nested_identifier';
import { useObjectURL } from './common/use_object_url';
import { useRenewableKey } from './common/use_renewable_key';
import { useTurbolinksRendered } from './common/use_turbolinks_rendered';
import { useUncontrollable } from './common/use_uncontrollable';
import { useUniqueKey } from './common/use_unique_key';
import { useViewData } from './common/use_view_data';
import WeakCacher from './common/weak_cacher';
import { widthStyleObject } from './common/width_style_object';

import customIcons from './common/custom_icons.json';

import { Icon, config } from '@fortawesome/fontawesome-svg-core';
import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const addrZipIcon = customIcons[0] as Icon;
const zipAddrIcon = customIcons[1] as Icon;
const polusIcon = customIcons[2] as Icon;

config.showMissingIcons = false;
const MemoizedIcon = memo(FontAwesomeIcon);

export {
  addrZipIcon,
  arrayFirstCache,
  BodyContext,
  checkPresent,
  clearFlash,
  closest,
  confirmPageTransition,
  createReactModal,
  dateToString,
  defaultCheckContentType,
  defaultCompare,
  defaultValuePicker,
  delegate,
  displayToast,
  fixedThumbnail,
  generateSearchQuery,
  generateUniqueKey,
  getFlashes,
  getPrefPrefixRegExp,
  getViewData,
  humanColumnName,
  identity,
  interCompanyMessagesStyles,
  isIos,
  isPolusMode,
  isValidForm,
  loadDeferredPromise,
  makeFormData,
  makeThrottleByFrame,
  masterValues,
  matchedIndex,
  MemoizedIcon,
  nestedMerge,
  noop,
  onBeforeRenderByController,
  oneBeforeTransition,
  onHTMLLoadedByClasses,
  onReady,
  onRender,
  pickNumberFromEvent,
  polusIcon,
  promisedComponent,
  propConvertor,
  RailsFetch,
  renderReactRoot,
  roundByFraction,
  scrollAnimate,
  setFlash,
  sortOrOriginal,
  taxedPrice,
  telephoneInputType,
  toCommaSeparated,
  turbolinksVisit,
  unconfirmPageTransition,
  untaxedPrice,
  useConfirmedDestroy,
  useConvertedSetter,
  useFixedParamCallback,
  useGridCondition,
  useIsXsSm,
  useIsXsSmMd,
  useJSONRequester,
  useKeyValDispatch,
  useMutableCallback,
  useNestedIdentifier,
  useObjectURL,
  useRenewableKey,
  useTurbolinksRendered,
  useUncontrollable,
  useUniqueKey,
  useViewData,
  WeakCacher,
  widthStyleObject,
  zipAddrIcon
};
