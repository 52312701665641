// 1ファイル表示用のフレーム（汎用版）

import { ComponentType, memo, ReactElement } from 'react';
import { fixedThumbnail, useObjectURL, useConfirmedDestroy, noop } from '../lib/common';
import { FileImage } from './FileImage';
import style from './files/files-style.module.scss';
import deleteButton from './files/upload-delete-button.svg';

type FileFrameComponentParam<T extends FileBase & NestedBase> = {
  file: T;
  dispatch?: (changes: Partial<T>) => void;
  metadataComponent?: ComponentType<{
    file: T;
    dispatch?: (changes: Partial<T>) => void;
  }>;
};

const RawFileFrame = <T extends NestedBase & FileBase>(props: FileFrameComponentParam<T>): ReactElement => {
  const {file, dispatch} = props;

  const thumbUrl = file.urls && file.urls.thumb_url;
  const fixedThumbnailPicture = fixedThumbnail(
    (file.urls ? file.urls.content_type : (file.file && file.file.type)) || ''
  );
  const objectUrl = useObjectURL(file.file);
  const handleClickDestroy = useConfirmedDestroy(dispatch as () => void || noop);
  const MetadataComponent = props.metadataComponent;

  // Hooks定義完了

  return(
    <div className="col mb-4">
      <div className="card">
        <a href={(file.urls ? file.urls.url : objectUrl) || '#'} target="_blank" rel="noopener noreferrer">
          <FileImage thumbnail={thumbUrl || fixedThumbnailPicture || objectUrl} />
        </a>
        {
          dispatch && (
            <img src={deleteButton} className={style.deleteButton}
              onClick={handleClickDestroy}
            />
          )
        }
        {
          MetadataComponent && (
            <div className={style.fileMetadata}>
              <MetadataComponent
                file={file}
                dispatch={dispatch}
              />
            </div>
          )
        }

      </div>
    </div>
  );

};

const FileFrame = memo(RawFileFrame);

export { FileFrame };
