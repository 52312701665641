// readyハンドラ
// DOMContentLoadedに対して、
// 「実行済みのときの動作」と「セットすれば消える」を実現

// Promiseとして制御
// （Promiseならもともと非同期実行＆後からの追加にも対応）
const promise = new Promise<void>(resolve => {
  const main = (): void => {
    document.removeEventListener('DOMContentLoaded', main, false);
    resolve();
  };
  document.addEventListener('DOMContentLoaded', main, false);
});

// 外部から直接Promiseを使いたい場合に備えて用意
export { promise };

// 登録関数
const onReady = (handler: () => void): void => {
  promise.then(handler);
};

export { onReady };
