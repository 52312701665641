// 発注ファイル1枚分のコンポーネント（汎用のFileFrameを経由）

import { FC, useCallback } from 'react';
import { useNestedIdentifier } from '../../../lib/common';
import { FileFrame, FileDescription } from '../../../react-components';

const JebOrderFileFrame: FC<{
  file: Jeb.OrderFile;
  dispatch?: (changes: Partial<Jeb.Order>) => void;
}> = ({file, dispatch}) => {
  const idObject = useNestedIdentifier(file);
  const itemDispatch = useCallback((item: Partial<Jeb.OrderFile>) => {
    dispatch && dispatch({files: [{...item, ...idObject}]});
  }, [dispatch, idObject]);

  return(
    <FileFrame
      file={file}
      dispatch={dispatch ? itemDispatch : undefined}
      metadataComponent={FileDescription}
    />
  );
};

export { JebOrderFileFrame };
