interface FormDataGeneratableObject{
  [x: string]: FormDataGeneratable;
}

type OneFormData = string | number | boolean | null | undefined | FileList | File;

export type FormDataGeneratable =
  OneFormData | OneFormData[] |
  FormDataGeneratableObject | FormDataGeneratableObject[];

const withBracket: (str: string | number) => string = x => '[' + x + ']';

const setObjectArray = (
  baseKey: string, arr: FormDataGeneratableObject[], formData: FormData
): void => {
  arr.forEach((value, i) => {
    const currentBase = baseKey + withBracket(i);
    if(value._destroy) {
      // _destroyでidがない場合はパス
      if('id' in value) {
        formData.append(currentBase + '[id]', value.id  + '');
        formData.append(currentBase + '[_destroy]', '1');
      }
    } else {
      // 再帰的に呼び合うので、無効にするしかない
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setObject(currentBase, value, formData);
    }
  });
};

// スカラー値の処理を行う関数
const setScalar = (key: string, value: OneFormData, formData: FormData): void => {
  if(value instanceof FileList) {
    // FileListとはなっているけど、1個だけしかファイルがないのが前提
    formData.append(key, value[0]);
    return;
  }
  if(value instanceof File) {
    formData.append(key, value);
    return;
  }
  if(value !== undefined) {
    // スカラー値（undefinedはキーごと除外）
    // nullは空文字列に変換
    const applyingValue = value === null ? '' : value + '';
    formData.append(key, applyingValue);
  }
};

const isObjectArray = (
  arr: OneFormData[] | FormDataGeneratableObject[]
): arr is FormDataGeneratableObject[] => {
  return typeof arr[0] === 'object' && !!arr[0] && !(arr[0] instanceof FileList);
};

const setObject = (
  baseKey: string, obj: FormDataGeneratableObject, formData: FormData
): void => {
  Object.keys(obj).forEach(key => {
    // newIdキーは除外
    if(key === 'newId') return;
    const value = obj[key];
    if(Array.isArray(value)) {
      if(value.length === 0) {
        formData.append(baseKey + withBracket(key) + '[]', '');
      } else if(isObjectArray(value)) {
        // オブジェクト配列
        setObjectArray(
          baseKey + withBracket(key + '_attributes'),
          value,
          formData
        );
      } else {
        const currentKey = baseKey + withBracket(key) + '[]';
        // スカラー配列
        value.forEach(item => {
          setScalar(currentKey, item, formData);
        });
      }
    } else if(value instanceof FileList || value instanceof File || !value || typeof value !== 'object') {
      // スカラー値
      setScalar(baseKey + withBracket(key), value, formData);
    } else {
      // has_one
      setObject(
        baseKey + withBracket(key + '_attributes'),
        value,
        formData
      );
    }
  });
};

const makeFormData = (
  baseKey: string, obj: FormDataGeneratableObject, formData?: FormData
): FormData => {
  const activeFormData = formData || new FormData;
  setObject(baseKey, obj, activeFormData);
  return activeFormData;
};

export { makeFormData };
