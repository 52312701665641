import { jebCoatingReducer } from './reducers/jeb_coating_reducer';
import { nestedMerge } from '../lib/common';
import estimateSetReducer from './reducers/estimate_set_reducer';

const emptyArray = [] as const;

const emptyObject = {};

// 案件ページのreducer
type CustomerReplacer = {
  _replace: Customer;
};

const reducer = (store: Customer, changes: Partial<Customer> | CustomerReplacer): Customer => {
  if('_replace' in changes) return changes._replace;
  if(changes.jeb_coating) {
    return {...store, jeb_coating: jebCoatingReducer(store.jeb_coating || emptyObject, changes.jeb_coating)};
  }
  if(changes.coating_estimate_sets) {
    return {
      ...store,
      coating_estimate_sets: nestedMerge(
        store.coating_estimate_sets || emptyArray,
        changes.coating_estimate_sets[0],
        estimateSetReducer
      )
    };
  }
  return {...store, ...changes};
};

export { reducer };
