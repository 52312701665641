// 配列の先頭オブジェクトをWeakMapのキーにする、
// 同じ配列インスタンスを作るためのキャッシュ

const cache = new WeakMap<object, never[]>();

const emptyArray: never[] = [];

const checkExists = <T extends object>(arr: readonly T[]): readonly T[] | false => {
  const current = cache.get(arr[0]);
  if(!current) return false;
  if(current.length !== arr.length) return false;
  if(arr.some((item, index) => item !== current[index])) return false;
  return current;
};

const arrayFirstCache = <T extends object>(arr: readonly T[]): readonly T[] => {
  if(arr.length === 0) return emptyArray;
  const current = checkExists(arr);
  if(current) return current;
  // WeakCacheがreadonlyを取れないのを無視させる
  cache.set(arr[0], arr as never[]);
  return arr;
};


export { arrayFirstCache };
