// 遅延させたモーダルオブジェクト

import { loadDeferredPromise, promisedComponent } from '../../lib/common';

const modalPromise = loadDeferredPromise.then(({UserEditModal}) => ({default: UserEditModal}));

const PromisedModal = promisedComponent(
  () => modalPromise
);

export { PromisedModal };
