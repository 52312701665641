// ポラスアカウント1行分のコンポーネント

import { FC, memo, useState } from 'react';
import { PromisedModal } from './PromisedModal';
import { useFixedParamCallback, useViewData } from '../../lib/common';

const RawUserRow: FC<{
  user: User;
}> = ({user}) => {
  const [open, setOpen] = useState(false);
  const handleClickEdit = useFixedParamCallback(setOpen, true);
  const viewData = useViewData() as {current_user_id: number; is_admin: boolean};

  // とりあえず管理者しかない前提
  const stateStr = user.deprecated ? '無効' : (
    user.role_ids && user.role_ids.length ? '管理者' : '一般'
  );

  // Hooks定義完了

  return(
    <tr>
      <td>{ user.name }</td>
      <td>{ user.email }</td>
      <td>{ user.telephone }</td>
      <td>{ stateStr }</td>
      <td className="text-right">
        {
          (viewData.is_admin || viewData.current_user_id === user.id) && (
            <button className="btn btn-primary" type="button"
              onClick={handleClickEdit}
            >
              編集
            </button>
          )
        }
        <PromisedModal open={open} onChangeOpen={setOpen}
          defaultUser={user}
        />
      </td>
    </tr>
  );
};

const UserRow = memo(RawUserRow);

export { UserRow };
