// 編集用フォームのマウント

import { onBeforeRenderByController, renderReactRoot } from '../../lib/common';
import { JebEmployees } from './react-components/JebEmployees';

onBeforeRenderByController('employees', body => {
  const root = body.querySelector('#jeb_employees_base');
  if(root) renderReactRoot(
    root as HTMLElement,
    <JebEmployees />
  );
});

if(process.env.NODE_ENV === 'development' && module.hot){
  module.hot.accept();
}
