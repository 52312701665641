// 関数を、フレームごとに1回実行する形に変換する

type callback = () => void;

const makeThrottleByFrame = (func: callback): callback => {
  let event_id: number | null = null;
  const main = () => {
    event_id = null;
    func();
  };
  return () => {
    if(event_id) cancelAnimationFrame(event_id);
    event_id = requestAnimationFrame(main);
  };
};

export { makeThrottleByFrame };
