// 補修の1項目単位のコンポーネント

import { ChangeEvent, FC, memo, useCallback } from 'react';
import { defaultValuePicker, humanColumnName, useConfirmedDestroy, useKeyValDispatch, useNestedIdentifier } from '../../../lib/common';
import { FormColumn, FormColumnedDatePicker, FormColumnedInput, FormColumnedTextarea } from '../../../react-components';
import { JebEmployeeDropdown } from '../JebEmployeeDropdown';

const eventToNumber: (e: ChangeEvent<HTMLSelectElement|HTMLInputElement>) => number = e => +e.target.value;

const RawJebRepair: FC<{
  repair: Jeb.Repair;
  jebCoatingDispatch(changes: Partial<Jeb.Coating>): void;
  required?: boolean;
}> = ({repair, jebCoatingDispatch, required}) => {

  const idObject = useNestedIdentifier(repair);
  const itemDispatch: (changes: Partial<Jeb.Repair>) => void =
    useCallback(changes => {
      const realChanges = {...changes, ...idObject};
      jebCoatingDispatch({repairs: [realChanges]});
    }, [jebCoatingDispatch, idObject]);
  const handleChangeMemo = useKeyValDispatch(itemDispatch, 'memo', defaultValuePicker);
  const handleChangeRecordDate = useKeyValDispatch(itemDispatch, 'record_date', defaultValuePicker);
  const handleChangeActionDate = useKeyValDispatch(itemDispatch, 'action_date', defaultValuePicker);
  const handleChangeRepairPlace = useKeyValDispatch(itemDispatch, 'repair_place', defaultValuePicker);
  const handleChangeEmployeeId = useKeyValDispatch(itemDispatch, 'jeb_employee_id', eventToNumber);
  const handleDelete = useConfirmedDestroy(itemDispatch);

  // Hooks定義完了

  return(
    <tr>
      <td>
        <div className="row">
          <FormColumnedDatePicker sm={6} md={3}
            label={humanColumnName('Jeb::Repair', 'record_date')}
            required={required}
            value={repair.record_date || ''}
            onChange={handleChangeRecordDate}
          />
          <FormColumnedDatePicker sm={6} md={3}
            label={humanColumnName('Jeb::Repair', 'action_date')}
            value={repair.action_date || ''}
            onChange={handleChangeActionDate}
          />
          <FormColumn sm={6} md={3} label={humanColumnName('Jeb::Repair', 'jeb_employee_id')}>
            <JebEmployeeDropdown
              value={repair.jeb_employee_id || ''}
              onChange={handleChangeEmployeeId}
            />
          </FormColumn>
          <FormColumn sm={6} md={3} label={'　'}>
            <div className="text-right">
              <button className="btn btn-danger" type="button"
                onClick={handleDelete}
              >
                削除
              </button>
            </div>
          </FormColumn>
        </div>
        <div className="row">
          <FormColumnedInput label={humanColumnName('Jeb::Repair', 'repair_place')}
            value={repair.repair_place || ''}
            onChange={handleChangeRepairPlace}
          />
        </div>
        <div className="row">
          <FormColumnedTextarea
            formLabel={humanColumnName('Jeb::Repair', 'memo')}
            value={repair.memo || ''}
            onChange={handleChangeMemo}
          />
        </div>
      </td>
    </tr>
  );
};

const JebRepair = memo(RawJebRepair);

export { JebRepair };
