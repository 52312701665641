// ネストモデルからidとnewIdだけピックアップするHook

import { useMemo } from 'react';

const useNestedIdentifier = (obj: NestedBase): NestedBase => {
  const {id, newId} = obj;
  return useMemo(() => {
    // データがないものはキーも残さないように処置
    const ret: NestedBase = {};
    if(id) ret.id = id;
    if(newId) ret.newId = newId;
    return ret;
  }, [id, newId]);
};

export { useNestedIdentifier };
