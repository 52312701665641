// 初期描画と、turbolinksでのrenderのタイミングで動作するコールバック

import { onReady } from './on_ready';

const onRender = (callback: () => void): void => {
  onReady(callback);
  document.addEventListener('turbolinks:render', callback, false);
};

export { onRender };
