// セッション単位でセットされたフラッシュを表示

import { getFlashes, displayToast, clearFlash, onRender } from '../lib/common';

const ELEMENT_ID = 'flash_notice';

onRender(() => {
  const flashes = getFlashes();
  flashes.forEach(({message, toastType}) => {
    displayToast(message, toastType || 'INFO');
  });
  clearFlash();
  const elem = document.getElementById(ELEMENT_ID) as HTMLInputElement | null;
  if(elem) {
    displayToast(elem.value, 'DONE');
    const parent = elem.parentNode;
    parent && parent.removeChild(elem);
  }
});
