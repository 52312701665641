// 更新可能なキーを生成するHooks

import { useCallback, useState } from 'react';
import { generateUniqueKey } from './generate_unique_key';

const useRenewableKey = (): [string, () => void] => {
  const [key, setKey] = useState(() => generateUniqueKey());
  const renewKey = useCallback(() => {
    setKey(generateUniqueKey());
  }, []);
  return [key, renewKey];
};

export { useRenewableKey };
