// .col-**-nから<option>まで一貫して作成するコンポーネント

import { FC, InputHTMLAttributes, memo, ReactNode } from 'react';
import { ColProps } from 'react-bootstrap/Col';
import { useUniqueKey } from '../lib/common';
import { FormColumn } from './FormColumn';
import classNames from 'classnames';
import { Options } from './Options';

type WithoutListType = Omit<InputHTMLAttributes<HTMLSelectElement>, 'list'>

interface FormColumnedSelectProps extends ColProps, WithoutListType{
  label?: ReactNode;
  error?: string | null;
  list: DropdownList;
}

const RawFormColumnedSelect: FC<FormColumnedSelectProps> = props => {
  const {xs, sm, md, lg, xl, label, error, list, ...inputProps} = props;
  const uniqueId = useUniqueKey();
  const realId = props.id || uniqueId;
  const realClass = classNames(
    'form-control', props.className, error && 'is-invalid'
  );

  return(
    <FormColumn
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} label={label}
      controlId={realId}
    >
      <select {...inputProps} className={realClass} id={realId}>
        <Options list={list} />
      </select>
      {
        error && (
          <div className="invalid-feedback">
            {error}
          </div>
        )
      }
    </FormColumn>
  );
};

const FormColumnedSelect = memo(RawFormColumnedSelect);

export { FormColumnedSelect };
