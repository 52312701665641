// ジェブ従業員の複数選択用コンポーネント

import { FC, memo, useCallback, useMemo } from 'react';
import { useViewData } from '../../lib/common';
import { MultiSelect } from '../../react-components';

const RawJebEmployeesMultiSelect: FC<{
  ids: number[];
  onChange: (value: number[]) => void;
  useCompanyAccountId?: boolean;
  initialIds?: number[];
}> = ({
  ids, onChange, useCompanyAccountId, initialIds
}) => {
  const viewData = useViewData() as {jeb_employees: Jeb.Employee[]};
  const rawEmployees = viewData.jeb_employees;

  // TODO: ジェブ以外の場合の考慮
  const availableList = useMemo(() => {
    const additionalIds = initialIds || [];
    return rawEmployees.filter(item => {
      if(!item.deprecated) return true;
      const id = (useCompanyAccountId ? item.company_account_id : item.id) as number;
      return additionalIds.indexOf(id) !== -1;
    }).map(item => ({
      value: (useCompanyAccountId ? item.company_account_id : item.id) + '', label: item.name
    }));
  }, [initialIds, rawEmployees, useCompanyAccountId]);
  const handleChangeIds = useCallback((value: string[]) => {
    const ids = value.map(Number);
    onChange(ids);
  }, [onChange]);
  const applyingValue = useMemo(() => ids.map(String), [ids]);

  // Hooks定義完了

  return(
    <MultiSelect onChange={handleChangeIds}
      options={availableList} value={applyingValue}
      placeholder="複数選択できます"
    />
  );
};

const JebEmployeesMultiSelect = memo(RawJebEmployeesMultiSelect);

export { JebEmployeesMultiSelect };
