// グリッドシステムのリミット幅と名前を管理するモジュール

// 幅に端数が出うるので、小数で取る（Bootstrap 4のコードと統一）
const breakpoints = [575.98, 767.98, 991.98, 1199.98];
const widthNames: WidthName[] = ['xs', 'sm', 'md', 'lg', 'xl'];
const nameFromWidth = (width: number) => {
  for(let i = 0; i < breakpoints.length; ++i) {
    if(width < breakpoints[i]) return widthNames[i];
  }
  return widthNames[widthNames.length - 1];
};

export { breakpoints, widthNames, nameFromWidth};
