// モバイルでの幅が狭いリスト

import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { CustomerInList } from './customer_with_coating_date';
import { NarrowItem } from './NarrowItem';
import { useViewData, turbolinksVisit, generateSearchQuery } from '../../../lib/common';
import { Options } from '../../../react-components';

const style = {display: 'inline-block', width: 'auto'};

const baseList: DropdownList = [
  ['', ''],
  ['jeb_coating_jeb_process_id', 'プロセス'],
  ['name', '氏名'],
  ['new_address', '施工先'],
  ['telephone1', '電話番号'],
  ['jeb_coating_order_coating_date', '施工日']
];

const NarrowList: FC<{items: CustomerInList[]}> = ({items}) => {
  const q = (useViewData
  () as {q: {[x: string]: string | null | undefined}}).q;
  const s = q.s || '';
  const arr = s.split(' ');
  const currentSort = arr[0];
  const activeList = useMemo(() => {
    const ret: DropdownList = [];
    baseList.forEach(item => {
      const key = item[0];
      if(!key){
        ret.push(item);
        return;
      }
      if(key !== currentSort) {
        ret.push([key + ' asc', item[1] as string]);
        return;
      }
      ret.push([key + ' asc', item[1] + ' ▲']);
      ret.push([key + ' desc', item[1] + ' ▼']);
    });
    return ret;
  }, [currentSort]);
  const handleChangeSort = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target;
    turbolinksVisit('/customers?' + generateSearchQuery({...q, s: value}) + '#list_h2');
  }, [q]);

  // Hooks定義完了

  return (
    <>
      <div className="row">
        <div className="col-12 text-right">
          {'並び順: '}
          <select className="form-control" style={style}
            value={s || ''} onChange={handleChangeSort}
          >
            <Options list={activeList} />
          </select>
        </div>
      </div>
      {items.map(item => (<NarrowItem customer={item} key={item.id} />))}
    </>
  );
};

export { NarrowList };
