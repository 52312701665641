// 確認してから削除のdispatchを行うHook

import { useCallback } from 'react';

const DESTROY_ITEM = {_destroy: 1};

const useConfirmedDestroy = (
  dispatch: (changes: typeof DESTROY_ITEM) => void
): () => void => {
  return useCallback(() => {
    if(!confirm('本当に削除しますか？')) return;
    dispatch(DESTROY_ITEM);
  }, [dispatch]);
};

export { useConfirmedDestroy };
