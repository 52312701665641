import { ChangeEvent, FC, KeyboardEvent, useCallback } from 'react';
import { useKeyValDispatch, defaultValuePicker, useUniqueKey, useGridCondition } from '../../../lib/common';
import { ButtonRadios } from '../../../react-components';

type Q = {
  [x: string]: string | null | undefined;
};


const conditions: ButtonRadioItems = [
  ['', '全件表示'],
  ['name_cont', '氏名'],
  ['new_address_cont', '施工先'],
  ['telephone1_or_telephone2_cont', '電話番号']
];

const freeInputBase: Q = {};
conditions.forEach(item => item[0] && (freeInputBase[item[0]] = null));

// activeKey, activeValueを抜き出さないとメモ化の意味がない
const CustomerFreeInput: FC<{
  dispatch: (changes: Q) => void;
  q: Q;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}> = ({dispatch, q, onKeyDown}) => {
  // 単体の値はメモ化できるけど、オブジェクトが変わるので追随できない
  const {k: activeName, v: activeValue} = ((): {k: string; v: string} => {
    const ret = {k: '', v: ''};
    Object.keys(freeInputBase).some(key => {
      const val = q[key];
      if(val || val === '') {
        ret.k = key;
        ret.v = val;
        return true;
      }
    });
    return ret;
  })();
  const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newKey = e.target.value;
    const addition: Q = newKey ? {[newKey]: activeValue} : {};
    dispatch({...freeInputBase, ...addition});
  }, [dispatch, activeValue]);
  const handleChangeValue = useKeyValDispatch(dispatch, activeName, defaultValuePicker);
  const valueId = useUniqueKey();
  const isXl = useGridCondition('xl');

  // Hooks定義完了

  return(
    <div className="form-group row">
      <label className="col-12 col-sm-3 col-md-2 form-label">
        条件選択
      </label>
      <div className={'col-12 col-sm-9 col-xl-4' + (isXl ? '' : ' mb-3')}>
        <ButtonRadios list={conditions}
          value={activeName}
          onChange={handleChangeName}
        />
      </div>
      <label className="col-12 col-sm-3 col-md-2 form-label"
        htmlFor={valueId}
      >
        値を入力
      </label>
      <div className="col-12 col-sm-9 col-md-10 col-xl-4">
        <input type="text" className="form-control"
          id={valueId} value={activeValue}
          disabled={!activeName}
          onChange={handleChangeValue}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
};

export { CustomerFreeInput };
