// Promiseから、
// 「Promiseがresolveするまでは別なものを表示する」コンポーネントを作る
// （主に、「ユーザーインタラクションまで表示しないので、遅延ロードにしている」コンポーネント用）

import { ComponentType, FC, lazy, PropsWithoutRef, ReactNode, Suspense } from 'react';

const promisedComponent = <P, > (
  promiseFunc: () => Promise<{default: ComponentType<P>}>,
  fallback: (ReactNode | null) = null
): FC<PropsWithoutRef<P>> => {
  const LazyComponent = lazy(promiseFunc);
  const PromisedComponent: FC<PropsWithoutRef<P>> = props => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
  return PromisedComponent;
};

export { promisedComponent };
