// md幅以下かどうかをチェックするHooks

import { useGridCondition } from './use_grid_condition';

const conditionArr = ['xs', 'sm', 'md'] as const;

const useIsXsSmMd = (): boolean => {
  return useGridCondition(conditionArr);
};

export { useIsXsSmMd };
