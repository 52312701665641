import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TableDetail } from './TableDetail';
import { ClassList } from './ClassList';
import { ControlPanel } from './ControlPanel';
import { useMutableCallback, turbolinksVisit, useViewData, useJSONRequester } from '../lib/common';
import { DummyComponent } from './DummyComponent';

const normalize = str => (str + '').toLowerCase().replace(/[^a-z]/g, '');

const PRELOAD_THRESHOLD = 10;

function RawClassFinder({classes}) {
  const getter = useJSONRequester();

  const [searchText, setSearchText] = useState('');

  const normalizedText = normalize(searchText);

  const [classData, rawSetClassData] = useState({});

  const displayingItem = useViewData().initial_class;
  const fetchingPromiseRef = useRef({});

  const setClassData = useMutableCallback((className, obj) => {
    rawSetClassData((data) => ({...data, [className]: obj}));
  });

  const fetchClass = useCallback((className) => {
    const promises = fetchingPromiseRef.current;
    if(promises[className]) return;
    promises[className] = getter.get(`/api/master_records/${className}.json`).then(json => {
      setClassData(className, json);
    }).catch(() => {
      setClassData(className, {
        error:
          '読み込みでエラーが発生しました。リロードなどを行って、再度お試しください。'
      });
    });
  }, [getter, setClassData]);

  // リレーションのある箇所のプリロード
  useEffect(() => {
    if(!displayingItem) return;
    const data = classData[displayingItem];
    if(!data || data.error) {
      fetchClass(displayingItem);
      return;
    }
    data.reflections.forEach(({class_name}) => {
      fetchClass(class_name);
    });
    data.reverse_relations.forEach(({class_name}) => {
      fetchClass(class_name);
    });
  }, [displayingItem, classData, fetchClass]);

  const displayingClasses = useMemo(() => {
    if(!normalizedText) return classes;
    // 飛び飛びなものにもヒットするように、正規表現化する
    const regexp = new RegExp(normalizedText.split('').join('.*'));
    return classes.filter(klass => {
      return regexp.test(normalize(klass.name));
    });
  }, [classes, normalizedText]);

  // リストが少ない時のプリロード
  useEffect(() => {
    if(displayingClasses.length > PRELOAD_THRESHOLD) return;
    displayingClasses.forEach(({name}) => fetchClass(name));
  }, [displayingClasses, fetchClass]);

  const handleChangeText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const handleRequestTable = useMutableCallback((newDisplayingItem) => {
    turbolinksVisit('/master_records/' + newDisplayingItem);
    if(classData[newDisplayingItem]) return;
    fetchClass(newDisplayingItem);
  });

  const selectedItem = useMemo(() => {
    if(!displayingItem) return null;
    return classData[displayingItem];
  }, [classData, displayingItem]);

  // Hooks定義完了

  return(
    <div>
      <ControlPanel
        value={searchText}
        onChange={handleChangeText}
      />
      <div className="row">
        <ClassList
          list={displayingClasses}
          displayingItem={displayingItem}
          handleFetch={fetchClass}
          handleRequestTable={handleRequestTable}
        />
        <div className="col-sm-9">
          <div className="detail-frame">
            {
              displayingItem ?
                <TableDetail data={selectedItem}
                  onRequestTable={handleRequestTable}
                /> :
                <p>クラスを選択してください。</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const ClassFinder = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  RawClassFinder;

export { ClassFinder };
