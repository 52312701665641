import { loadDeferredPromise } from './load_deferred_promise';

// Rails-ujs経由（CSRF対応済み）でfetchを行う

interface RailsFetchOptions{
  data?: string | Document | BodyInit;
  dataType?: string;
}

const RailsFetch = (
  url: string, type?: string, options: RailsFetchOptions = {}
): Promise<[unknown, string, XMLHttpRequest]> => {
  return loadDeferredPromise.then(({Rails}) => {
    const promise = new Promise<[unknown, string, XMLHttpRequest]>((resolve, reject) => {
      Rails.ajax({
        url,
        type: type || 'get',
        data: options.data,
        dataType: options.dataType,
        success(response, statusText, xhr){
          resolve([response, statusText, xhr]);
        },
        error(response, statusText, xhr){
          reject([response, statusText, xhr]);
        }
      });
    });
    return promise;
  });
};

export { RailsFetch };
