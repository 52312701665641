// ポラスアカウント一覧のマウント

import { onBeforeRenderByController, renderReactRoot } from '../lib/common';
import { Users } from './react-components/Users';

onBeforeRenderByController('users', body => {
  const root = body.querySelector('#users_base');
  if(root) renderReactRoot(
    root as HTMLElement,
    <Users />
  );
});

if(process.env.NODE_ENV === 'development' && module.hot){
  module.hot.accept();
}
