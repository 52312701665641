// <option>や<optgroup>のリストを配列から生成するコンポーネント

import { FC, memo } from 'react';

const RawOptionItem: FC<{item: OptionItemData}> = ({item}) => {
  return (
    <option value={item[0]} {...item[2]}>{item[1]}</option>
  );
};

const OptionItem = memo(RawOptionItem);

const RawOptGroupItem: FC<{group: OptionGroupItemData}> = ({group}) => {
  return(
    <optgroup label={group[0]}>
      {
        group[1].map(item => (
          <OptionItem key={item[0]} item={item} />
        ))
      }
    </optgroup>
  );
};

const OptGroupItem = memo(RawOptGroupItem);

const isOptionItemData = (
  item: OptionItemData | OptionGroupItemData
): item is OptionItemData => {
  return typeof item[1] === 'string';
};

const RawOptions: FC<{list: DropdownList}> = ({list}) => {
  return (
    // 本来は不要だけど、TypeScriptの都合上いったんラッピングせざるを得ない
    <>
      {
        list.map(item => {
          if(isOptionItemData(item)) {
            return (
              <OptionItem key={'o:' + item[0]} item={item} />
            );
          } else {
            return(
              <OptGroupItem key={'g:' + item[0]} group={item} />
            );
          }
        })
      }
    </>
  );
};

const Options = memo(RawOptions);

export { Options };
