import { defaultCompare } from './default_compare';

// ソート後にもとの順序と変化がないなら、ソートせずにそのまま返す
// ※ Array.prototypeを破壊したような場合については、
// 正しく動作しないことが考えられます

const hasOwn = Object.prototype.hasOwnProperty;


const sortOrOriginal = <T>(
  arr: readonly T[],
  compare: (x: T, y: T) => number = defaultCompare
): readonly T[] => {
  // 1個以下の場合は、ソートしても変化しようがない
  if(arr.length <= 1) return arr;
  // ソートしなくて大丈夫かをチェック
  let needSort = false;
  for(let i = arr.length - 1; i > 0 && !needSort; --i) {
    const aUndef = arr[i-1] === undefined, bUndef = arr[i] === undefined;
    // 値抜けやundefinedは、比較関数にかかわらず場所が固定
    if(aUndef || bUndef) {
      const aEmpty = aUndef && !hasOwn.call(arr, i - 1);
      const bEmpty = bUndef && !hasOwn.call(arr, i);
      // まずは抜けているものを判定
      if(aEmpty) {
        needSort = !bEmpty;
        continue;
      }
      if(bEmpty) continue;
      // 値があるけどundefined
      if(aUndef) {
        needSort = !bUndef;
      }
      continue;
    }
    if(compare(arr[i-1], arr[i]) > 0) needSort = true;
  }
  if(!needSort) return arr;
  return arr.concat().sort(compare);
};

export { sortOrOriginal };
