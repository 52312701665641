// ソート用のリンク

import { FC, memo, ReactNode, useMemo } from 'react';
import { useViewData, generateSearchQuery } from '../../../lib/common';

const RawSortLink: FC<{
  title: ReactNode;
  sortKey: string;
  defaultDesc?: boolean;
}> = ({title, sortKey, defaultDesc}) => {
  const q = (useViewData() as {q: {[x: string]: string | null | undefined}}).q;
  const s = q.s || '';
  const arr = s.split(' ');
  const currentSort = arr[0];
  const isDesc = arr[1] === 'desc';
  const isActive = currentSort === sortKey;
  const newUrl = useMemo(() => {
    const newDesc = isActive ? !isDesc : defaultDesc;
    const directionStr = newDesc ? ' desc' : ' asc';
    return '/customers?' + generateSearchQuery({...q, s: sortKey + directionStr});
  }, [defaultDesc, isActive, isDesc, q, sortKey]);

  // Hooks定義完了

  return(
    <a href={newUrl}>
      {title}
      { isActive && (isDesc ? ' ▼' : ' ▲') }
    </a>
  );
};

const SortLink = memo(RawSortLink);

export { SortLink };
