// ジェブ補修部分のフォーム

import { FC, memo, useCallback, useState } from 'react';
import { dateToString, generateUniqueKey } from '../../lib/common';
import headingStyles from '../headings.module.scss';
import { JebRepair } from './repairs/JebRepair';

const RawJebRepairs: FC<{
  repairs: readonly Jeb.Repair[];
  dispatch(changes: Partial<Jeb.Coating>): void;
}> = ({repairs, dispatch}) => {
  const [newPlaceholder, setNewPlaceholder] = useState<NestedBase | null>(
    () => ({ newId: generateUniqueKey() })
  );
  const handleAdd = useCallback(() => {
    dispatch({repairs: [{newId: generateUniqueKey(), record_date: dateToString(new Date)}]});
  }, [dispatch]);
  const internalDispatch = useCallback((changes: Partial<Jeb.Coating>) => {
    if(newPlaceholder && changes.repairs && changes.repairs.some(
      item => item.newId === newPlaceholder.newId
    )) {
      setNewPlaceholder(null);
    }
    dispatch(changes);
  }, [dispatch, newPlaceholder]);

  // Hooks定義完了

  const availableRepairs = ((): readonly Jeb.Repair[] => {
    const filtered = repairs.filter(item => !item._destroy);
    if(newPlaceholder) filtered.push(newPlaceholder);
    return filtered;
  })();

  return(
    <>
      <h2 className={headingStyles.jebHeading}>
        補修記録
      </h2>
      <table className="table table-striped">
        <tbody>
          {
            availableRepairs.map(item => (
              <JebRepair key={item.id || item.newId}
                repair={item}
                jebCoatingDispatch={internalDispatch}
                required={!newPlaceholder || newPlaceholder.newId !== item.newId}
              />
            ))
          }
        </tbody>
      </table>
      <div className="row">
        <div className="col-12 text-center mb-3">
          <button className="btn btn-primary" type="button"
            onClick={handleAdd}
          >
            補修記録を追加
          </button>
        </div>
      </div>
    </>
  );
};

const JebRepairs = memo(RawJebRepairs);

export { JebRepairs };
