// 郵便番号と住所をセットで生成する枠
// Controlled、Uncontrolledの両対応で作る

import { ChangeEvent, FC, memo, ReactNode, useCallback, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { FormColumn } from './FormColumn';
import classNames from 'classnames';
import { MemoizedIcon, zipAddrIcon, addrZipIcon, useUncontrollable, loadDeferredPromise, useUniqueKey, useMutableCallback } from '../lib/common';

interface PostalInputProps {
  postalValue?: string;
  defaultPostalValue?: string;
  postalName?: string;
  postalLabel?: ReactNode;
  postalRequired?: boolean;
  postalDisabled?: boolean;
  postalReadOnly?: boolean;
  onChangePostal?: (postal: string) => unknown;
  addressValue?: string;
  defaultAddressValue?: string;
  addressName?: string;
  addressLabel?: ReactNode;
  addressRequired?: boolean;
  addressDisabled?: boolean;
  addressReadOnly?: boolean;
  onChangeAddress?: (postal: string) => unknown;
  addressError?: string;
}

const RawPostalAddressInput: FC<PostalInputProps> = ({
  // 郵便番号側
  postalValue, defaultPostalValue, postalName, postalReadOnly,
  postalLabel, postalRequired, postalDisabled, onChangePostal,
  // 住所側
  addressValue, defaultAddressValue, addressName, addressError,
  addressLabel, addressRequired, addressDisabled, onChangeAddress,
  addressReadOnly
}) => {

  const [internalPostal, setPostal] = useUncontrollable(
    postalValue, defaultPostalValue || '', onChangePostal
  );

  const [internalAddress, setAddress] = useUncontrollable(
    addressValue, defaultAddressValue || '', onChangeAddress
  );
  const postalId = useUniqueKey();
  const addressId = useUniqueKey();

  const handleChangePostal = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPostal(e.target.value);
    },
    [setPostal]
  );
  const handleChangeAddress = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAddress(e.target.value);
    },
    [setAddress]
  );

  const mutableSetAddress = useMutableCallback((val: string) => {
    setAddress(val);
    // setAddressError('');
  });

  // エラー処理
  const [postalError, setPostalError] = useState('');

  const handleBlurPostal = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setPostalError(value && !/^\d{3}-?\d{4}$/.test(value) ? '郵便番号の形式が正しくありません。' : '');
  }, []);

  const postalClass = classNames('form-control', postalError && 'is-invalid');

  const handleClickZipToAddress = useCallback(() => {
    if(!internalPostal || postalError) return;
    loadDeferredPromise
      .then(({zipToAddress}) => zipToAddress(internalPostal))
      .then(mutableSetAddress)
      .catch((err: {message: string}) => {
        if(err.message.indexOf('timed out') !== -1) {
          alert('郵便番号情報サーバに接続できませんでした。');
        } else {
          alert('住所の取得に失敗しました。');
        }
      });
  }, [internalPostal, postalError, mutableSetAddress]);

  const mutableSetZip = useMutableCallback((zip: string) => {
    setPostal(zip);
    setPostalError('');
  });

  const handleClickAddressToZip = useCallback(() => {
    if(!internalAddress) return;
    loadDeferredPromise
      .then(({addressToZip}) => {
        return addressToZip(internalAddress);
      })
      .then(mutableSetZip)
      .catch(error => {
        if(error.cancelled) return;
        alert('郵便番号の取得に失敗しました。');
      });
  }, [internalAddress, mutableSetZip]);
  const addressClass = classNames('form-control', addressError && 'is-invalid');


  // Hooks定義完了

  // TODO: inputの別立て化（ボタン付きなのでややこしいかも）

  return (
    <Row>
      <FormColumn controlId={postalId} label={postalLabel}
        sm={4}
      >
        <div className="input-group">
          <input type="text" className={postalClass}
            value={internalPostal}
            pattern="\d{3}-?\d{4}" maxLength={8}
            onChange={handleChangePostal}
            id={postalId}
            name={postalName}
            required={postalRequired}
            disabled={postalDisabled}
            onBlur={handleBlurPostal}
            readOnly={postalReadOnly}
          />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="button"
              onClick={handleClickZipToAddress}
              disabled={addressDisabled || addressReadOnly}
            >
              <MemoizedIcon icon={zipAddrIcon} />
            </button>
          </div>
          {
            postalError && (
              <div className="invalid-feedback">{postalError}</div>
            )
          }
        </div>

      </FormColumn>
      <FormColumn controlId={addressId} label={addressLabel}
        sm={8}
      >
        <div className="input-group">
          <div className="input-group-prepend">
            <button className="btn btn-secondary" type="button"
              onClick={handleClickAddressToZip}
              disabled={postalDisabled || postalReadOnly}
            >
              <MemoizedIcon icon={addrZipIcon} />
            </button>
          </div>
          <input type="text" className={addressClass}
            value={internalAddress}
            onChange={handleChangeAddress}
            id={addressId}
            name={addressName}
            required={addressRequired}
            disabled={addressDisabled}
            readOnly={addressReadOnly}
          />
          {
            addressError && (
              <div className="invalid-feedback">{addressError}</div>
            )
          }
        </div>
      </FormColumn>
    </Row>
  );
};

RawPostalAddressInput.defaultProps = {
  postalLabel: '郵便番号',
  addressLabel: '住所'
};

const PostalAddressInput = memo(RawPostalAddressInput);

export { PostalAddressInput };
