// モバイルでの案件表示

import { FC, memo } from 'react';
import { CustomerInList } from './customer_with_coating_date';
import { JebProcessLabel } from './JebProcessLabel';
import { formatCoatingDate } from './format_coating_date';

const RawNarrowItem: FC<{customer: CustomerInList}> = ({customer}) => {
  const editUrl = '/customers/' + customer.id + '/edit';
  const jebCoating = customer.jeb_coating;

  return(
    <div className="mobile-item" data-link-to={editUrl}>
      <JebProcessLabel id={jebCoating?.jeb_process_id} /> {customer.name}
      <p><strong>施工先:</strong> {customer.new_address}</p>
      <p>
        <strong>電話番号:</strong> {customer.telephone1}
        <br className="d-md-none" />{ ' ' }
        <strong>施工日:</strong> {formatCoatingDate(customer.coating_date)}
      </p>
      <p>
        <strong>依頼業者:</strong> {jebCoating ? 'ジェブ' : '未定'}
        <br className="d-md-none" />{ ' ' }
        <strong>情報共有:</strong> {customer.company_account_ids?.length ? 'あり' : 'なし'  }
      </p>
    </div>
  );
};

const NarrowItem = memo(RawNarrowItem);

export { NarrowItem };
