// ワックスの有無を入れる部分の行

import { ChangeEvent, FC, memo } from 'react';
import { humanColumnName } from '../../../lib/common';
import { FormColumn, ButtonRadios } from '../../../react-components';

const waxList: ButtonRadioItems = [['true', 'あり'], ['false', 'なし']];

const RawOrderWaxRow: FC<{
  dispatch(changes: Partial<Jeb.Order>): void;
  required?: boolean;
  value?: boolean | undefined;
  readOnly?: boolean;
}> = ({value, dispatch, required, readOnly}) => {
  const activeValue = value == undefined ? '' : (value + '');
  // 外でメモ化するので、useCallbackは省略
  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void =
    (e) => {
      dispatch({with_wax: e.target.value === 'true'});
    };

  // Hooks定義完了

  return(
    <div className="row">
      <FormColumn xs={6} label={humanColumnName('Jeb::Order', 'with_wax')}>
        <div>
          <ButtonRadios list={waxList} required={required && !readOnly}
            value={activeValue} onChange={handleChange}
            readOnly={readOnly}
          />
        </div>
      </FormColumn>
    </div>
  );
};

const OrderWaxRow = memo(RawOrderWaxRow);

export { OrderWaxRow };
