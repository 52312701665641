// リレーションの1行分のコンポーネント

import { FC, memo } from 'react';
import { RelatedTableButton } from '../RelatedTableButton';
import { WbrByUnderscore } from '../WbrByUnderscore';
import { DummyComponent } from '../DummyComponent';
import { Reflection } from '../types';

const RawReflectionRow: FC<{
  reflection: Reflection;
  onRequestTable: (name: string) => void;
}> = ({reflection, onRequestTable}) => {
  const reflectionType = reflection.reflection_type.replace('Reflection', '');
  return(
    <tr className={`reflection-${reflectionType}`}>
      <td>
        <WbrByUnderscore text={reflection.name} />
      </td>
      <td>{reflectionType}</td>
      <td>
        {reflection.class_name}
        <RelatedTableButton tableName={reflection.class_name}
          onRequestTable={onRequestTable}
        />
      </td>
      <td>
        <WbrByUnderscore text={reflection.foreign_key} />
      </td>
    </tr>
  );
};

const ReflectionRow = process.env.NODE_ENV === 'production' ?
  DummyComponent:
  memo(RawReflectionRow);

export { ReflectionRow };
