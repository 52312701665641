// フォームを引き回す値のコンテキスト

import { createContext } from 'react';
import { CustomerErrorReplacer } from '../customers/react-components/CustomerForm';
import { noop } from '../lib/common';

interface CustomerValuesContextType{
  dispatch(changes: Partial<Customer>): void;
  errors: CustomerError;
  errorDispatch(changes: CustomerError | CustomerErrorReplacer): void;
  sending: boolean;
  setSending(state: boolean): void;
}

const CustomerValuesContext = createContext<CustomerValuesContextType>({
  dispatch: noop,
  errors: {},
  errorDispatch: noop,
  sending: false,
  setSending: noop
});

export { CustomerValuesContext };
