// Turbolinksのタイミングに合わせてアンマウントするReactDOM.render

import ReactDOM from 'react-dom';
import { oneBeforeTransition } from './one_before_transition';

const renderReactWithTurbolinks = (
  container: HTMLElement,
  reactElement: React.ReactElement,
  onUnmounted? : () => unknown
): void => {
  ReactDOM.render(reactElement, container);

  // 後始末処理
  oneBeforeTransition(() => {
    ReactDOM.unmountComponentAtNode(container);
    onUnmounted && onUnmounted();
  });
};

export { renderReactWithTurbolinks };
