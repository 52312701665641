// イベントオブジェクトから数値を回収する（空文字列の場合はnullに変換）

type ChangeEventObj = {
  target: {
    value: string;
  };
}

const pickNumberFromEvent: (e: ChangeEventObj) => (number | null) = e => {
  const value = e.target.value;
  if(value === '') return null;
  return +value;
};

export { pickNumberFromEvent };
