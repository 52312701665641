// 従業員選択用ドロップダウン

import { ChangeEvent, FC, memo, useMemo } from 'react';
import { useViewData } from '../../lib/common';
import { Options } from '../../react-components';

const RawJebEmployeeDropdown: FC<{
  value?: number | string | null;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
}> = ({value, onChange, required}) => {
  // TODO: 無効になった従業員まわりの処理
  // とりあえずは全件表示で進める
  const viewData = useViewData() as {jeb_employees: Jeb.Employee[]};
  const rawEmployees = viewData.jeb_employees;
  // あとあと出し分けることを考えて、ここで1つずつ生成しておく
  const list: DropdownList = useMemo(() => {
    return ([['', '']] as DropdownList).concat(rawEmployees.map(item => [item.id + '', item.name]));
  }, [rawEmployees]);

  const realValue = value === null ? undefined : value;

  // Hooks定義完了

  return(
    <select className="form-control" value={realValue} onChange={onChange}
      required={required}
    >
      <Options list={list} />
    </select>
  );
};

const JebEmployeeDropdown = memo(RawJebEmployeeDropdown);

export { JebEmployeeDropdown };
