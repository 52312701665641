// .col-**-nから<input>まで一貫して作成するコンポーネント

import { FC, InputHTMLAttributes, memo, ReactNode } from 'react';

interface FormColumnedInputProps extends ColProps, InputHTMLAttributes<HTMLInputElement>{
  label?: ReactNode;
  error?: string | null;
}

import { ColProps } from 'react-bootstrap/Col';
import { useUniqueKey } from '../lib/common';
import { FormColumn } from './FormColumn';
import classNames from 'classnames';

const RawFormColumnedInput: FC<FormColumnedInputProps> = (props) => {
  const {xs, sm, md, lg, xl, label, error, ...inputProps} = props;
  const uniqueId = useUniqueKey();
  const realId = props.id || uniqueId;
  const realClass = classNames(
    'form-control', props.className, error && 'is-invalid'
  );

  return(
    <FormColumn
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} label={label}
      controlId={realId}
    >
      <input type="text"
        {...inputProps}
        className={realClass}
        id={realId}
      />
      {
        error && (
          <div className="invalid-feedback">
            {error}
          </div>
        )
      }
    </FormColumn>
  );
};

const FormColumnedInput = memo(RawFormColumnedInput);

export { FormColumnedInput };
