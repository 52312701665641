import { FC } from 'react';
import { useFixedParamCallback, noop, MemoizedIcon } from '../../lib/common';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const SuperClassInfo: FC<{
  superclass: string;
  onRequestTable: (name: string) => void;
}> = ({superclass, onRequestTable}) => {

  const handleClick = useFixedParamCallback(onRequestTable || noop, superclass);

  // Hooks定義完了

  if(superclass === 'ApplicationRecord') return null;
  if(superclass === 'MasterRecord') {
    return <small>（マスター）</small>;
  }
  // STIの場合
  return (
    <span>
      {` < ${superclass} `}
      <button className="btn btn-outline-secondary" type="button"
        title="親クラスを表示"
        onClick={handleClick}
      >
        <MemoizedIcon icon={faAngleLeft} rotation={90} />
      </button>
    </span>
  );
};

export { SuperClassInfo };
