// ジェブ用データ部分のフォーム

import { FC, useCallback } from 'react';
// import { JebPreEstimateProgresses } from '../../../../customers_common/jeb/JebPreEstimateProgresses';
import { JebFiles } from '../../../../customers_common/jeb/JebFiles';
// import { JebEstimate } from '../../../../customers_common/jeb/JebEstimate';
// import { JebBusinessTalks } from '../../../../customers_common/jeb/JebBusinessTalks';
import { JebOrderForm } from '../../../../customers_common/jeb/JebOrderForm';
import { JebCoatingCoating } from '../../../../customers_common/jeb/JebCoatingCoating';
import { useViewData, masterValues } from '../../../../lib/common';
import { FormColumn } from '../../../../react-components/FormColumn';
import { JebEmployeesMultiSelect } from '../../../../customers_common/jeb/JebEmployeesMultiSelect';
import { MailSendButtonRow } from '../../../../customers_common/jeb/MailSendButtonRow';
import { JebRepairs } from '../../../../customers_common/jeb/JebRepairs';
// import { headerEstimate } from '../../../../customers_common/header_ids';
// import headingStyles from '../../../../customers_common/headings.module.scss';

const emptyArray: never[] = [];
const NON_SPACE_REGEXP = /\S/;

const rawProcesses = masterValues('Jeb::Process');
const processNamesById: {[x: number]: string} = {};
rawProcesses.forEach(process => processNamesById[process.id] = process.name);

const CompaniesJebCoatingForm: FC<{
  jebCoating: Jeb.Coating;
  dispatch(changes: Partial<Customer>): void;
  errors: CustomerError;
  companyAccountIds?: number[];
  setWillSend(changes: Partial<Customer>): void;
}> = ({jebCoating, dispatch, companyAccountIds, setWillSend}) => {
  const viewData = useViewData() as {
    is_admin: boolean;
    current_company_account_id: number;
    customer: Customer;
  };
  const isAdmin = viewData.is_admin;
  const companyAccountId = viewData.current_company_account_id;
  const jebCoatingDispatch =
    useCallback<(changes: Partial<Jeb.Coating>) => void>((changes) => {
      dispatch({jeb_coating: changes});
    }, [dispatch]);
  const handleChangeCompanyAccountIds = useCallback((company_account_ids: number[]) => {
    if(company_account_ids.indexOf(companyAccountId) === -1) {
      alert('自分の権限は削除できません。');
      // 全部削除しようとした場合だけ、自分のIDだけの状態に更新しておく
      if(company_account_ids.length === 0){
        dispatch({company_account_ids: [companyAccountId]});
      }
      return;
    }
    dispatch({company_account_ids});
  }, [companyAccountId, dispatch]);

  // Hooks定義完了

  /*
  const contractedMailError = (
    jebCoating.files && jebCoating.files.some(file => file.jeb_file_type_id === 300 && file.newId)
  ) ? 'ファイルが新規登録されています。いったん保存してからメール送信を行ってください。' : '';
  */

  const coatingMailError = (
    !NON_SPACE_REGEXP.test(jebCoating.coating_report || '') || !NON_SPACE_REGEXP.test(jebCoating.coating_area || '')
  ) ? '報告事項・施工箇所を入力してください。' : (
      jebCoating.files && jebCoating.files.some(file => file.jeb_file_type_id === 700 && file.newId)
    ) ? '施工画像が新規登録されています。いったん保存してからメール送信を行ってください。' : '';

  return (
    <>
      <h2>ジェブ案件のデータ</h2>
      <div className="row">
        <FormColumn md={4} label="プロセス">
          <p className="form-control-static">
            { processNamesById[jebCoating.jeb_process_id as number] }
          </p>
        </FormColumn>
        {
          isAdmin && (
            <FormColumn md={8} label="閲覧権限">
              <JebEmployeesMultiSelect useCompanyAccountId
                ids={companyAccountIds || emptyArray}
                onChange={handleChangeCompanyAccountIds}
                initialIds={viewData.customer.company_account_ids}
              />
            </FormColumn>
          )
        }
      </div>
      {
        /* いったん止める
          <JebFiles files={jebCoating.files || emptyArray}
            dispatch={jebCoatingDispatch}
            typeId={1}
          />
          <JebPreEstimateProgresses
            progresses={jebCoating.pre_estimate_progresses || emptyArray}
            jebCoatingDispatch={jebCoatingDispatch}
            memo={jebCoating.pre_estimate_memo}
            readOnly
          />
          <h2 className={headingStyles.polusHeading} id={headerEstimate}>
            見積プロセス（変更不可）
          </h2>
          <JebEstimate
            estimateFiles={jebCoating.estimate_files || emptyArray}
            dispatch={jebCoatingDispatch}
            readOnly
          />
          <JebBusinessTalks
            talks={jebCoating.business_talks || emptyArray}
            resultId={jebCoating.jeb_business_talk_result_id}
            memo={jebCoating.business_talk_memo}
            jebCoatingDispatch={jebCoatingDispatch}
          />
          <JebFiles files={jebCoating.files || emptyArray}
            dispatch={jebCoatingDispatch} typeId={300}
          />
          <MailSendButtonRow setWillSend={setWillSend}
            mailId={400} mailTypeName="申込書"
            mailDisabledReason={contractedMailError}
          />
        */
      }
      <JebOrderForm
        order={jebCoating.order}
        dispatch={jebCoatingDispatch}
        readOnly
      />
      <JebCoatingCoating
        coating={jebCoating}
        dispatch={jebCoatingDispatch}
        setWillSend={setWillSend}
      />
      <JebFiles files={jebCoating.files || emptyArray}
        dispatch={jebCoatingDispatch} typeId={700}
        title="施工画像などのアップロード"
      />
      <MailSendButtonRow setWillSend={setWillSend}
        mailId={700} mailTypeName="施工報告"
        mailDisabledReason={coatingMailError}
      />
      <JebRepairs repairs={jebCoating.repairs || emptyArray}
        dispatch={jebCoatingDispatch}
      />
    </>
  );
};

export { CompaniesJebCoatingForm };
