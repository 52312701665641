// 消費税計算をするベースとなる、補正なしの税込み額の計算

import { roundByFraction } from './round_by_fraction';

const taxedBase = (untaxed: number, tax_ratio: number): number => {
  const unrounded = untaxed * (1 + tax_ratio);
  // 丸めの基準ズレを避けるために、1/1000の位で事前に四捨五入
  // 1円単位の金額に対して1%単位の消費税なので、1/100未満の端数は計算誤差
  const mediate = roundByFraction(unrounded, 3);
  return Math.round(mediate);
};

export { taxedBase };
