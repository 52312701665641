// 顧客情報の登録・編集用フォーム

import { FC, useContext } from 'react';
import { telephoneInputType, useKeyValDispatch, humanColumnName, defaultValuePicker, identity } from '../lib/common';
import { CustomerValuesContext } from './CustomerValuesContext';
import { FormColumnedInput, FormColumn, CustomDatePicker, FormColumnedTextarea, PostalAddressInput } from '../react-components';

const CustomerColumnInput: FC<{
  customer: Customer;
  column: Exclude<keyof Customer, 'jeb_coating' | 'company_account_ids' | 'coating_estimate_sets' | 'inter_company_messages'>;
  label?: string;
  sm?: number;
  required?: boolean;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  pattern?: string;
}> = ({
  column, label, sm, required, type, placeholder, readOnly, pattern, customer
}) => {
  const {dispatch, errors} = useContext(CustomerValuesContext);

  const handleChange = useKeyValDispatch(dispatch, column, defaultValuePicker);
  const activeLabel = label || humanColumnName('Customer', column);

  // Hooks定義完了

  return(
    <FormColumnedInput sm={sm} label={activeLabel}
      value={customer[column] || ''}
      type={type}
      required={required}
      onChange={handleChange}
      error={errors[column]}
      placeholder={placeholder}
      readOnly={readOnly}
      maxLength={250}
      pattern={pattern}
    />
  );
};

const CustomerBaseData: FC<{
  readOnly?: boolean;
  customer: Customer;
}> = ({readOnly, customer}) => {
  const {dispatch, errors} = useContext(CustomerValuesContext);

  const handleChangeCurrentPostal: (val: string) => void =
    useKeyValDispatch(dispatch, 'current_postal', identity);
  const handleChangeCurrentAddress: (val: string) => void =
    useKeyValDispatch(dispatch, 'current_address', identity);
  const handleChangeNewPostal: (val: string) => void =
    useKeyValDispatch(dispatch, 'new_postal', identity);
  const handleChangeNewAddress: (val: string) => void =
    useKeyValDispatch(dispatch, 'new_address', identity);
  const handleChangeDeliveryDate: (val: string) => void =
    useKeyValDispatch(dispatch, 'delivery_date', identity);
  const handleChangeOccupancyDate: (val: string) => void =
    useKeyValDispatch(dispatch, 'occupancy_date', identity);
  const handleChangeBaseMemo = useKeyValDispatch(dispatch, 'base_memo', defaultValuePicker);

  // Hooks定義完了

  return(
    <>
      <div className="row">
        <CustomerColumnInput sm={6}
          customer={customer}
          column="name" required readOnly={readOnly} pattern=".*\S.*"
        />
        <CustomerColumnInput sm={6}
          customer={customer}
          column="yomi" placeholder="カタカナで入力"
          readOnly={readOnly}
        />
      </div>
      <div className="row">
        <CustomerColumnInput sm={6}
          customer={customer}
          column="telephone1" type={telephoneInputType}
          readOnly={readOnly}
          placeholder="0**-***-****"
        />
        <CustomerColumnInput sm={6}
          customer={customer}
          column="telephone2" type={telephoneInputType}
          readOnly={readOnly}
          placeholder="0**-***-****"
        />
      </div>
      <div className="row">
        <CustomerColumnInput sm={6}
          customer={customer}
          column="email" type="email"
          readOnly={readOnly}
        />
        <CustomerColumnInput sm={6}
          customer={customer}
          column="email2" type="email"
          readOnly={readOnly}
        />
      </div>
      <PostalAddressInput
        postalValue={customer.current_postal || ''}
        onChangePostal={handleChangeCurrentPostal}
        addressLabel="現住所"
        addressRequired={!readOnly}
        addressReadOnly={readOnly}
        postalReadOnly={readOnly}
        addressValue={customer.current_address|| ''}
        onChangeAddress={handleChangeCurrentAddress}
        addressError={errors.current_address}
      />
      <PostalAddressInput
        postalValue={customer.new_postal || ''}
        onChangePostal={handleChangeNewPostal}
        addressLabel="施工先住所"
        addressValue={customer.new_address|| ''}
        onChangeAddress={handleChangeNewAddress}
        addressError={errors.new_address}
        addressReadOnly={readOnly}
        postalReadOnly={readOnly}
      />
      <div className="row">
        <CustomerColumnInput sm={6}
          column="house_name" customer={customer}
          readOnly={readOnly}
        />
        <CustomerColumnInput sm={6}
          column="house_code" customer={customer}
          readOnly={readOnly}
        />
      </div>
      <div className="row">
        <FormColumn sm={4} xs={6} label="引渡日">
          <CustomDatePicker
            value={customer.delivery_date || null}
            onChange={handleChangeDeliveryDate}
            readOnly={readOnly}
          />
        </FormColumn>
        <FormColumn sm={4} xs={6} label="入居日">
          <CustomDatePicker
            value={customer.occupancy_date || null}
            onChange={handleChangeOccupancyDate}
            readOnly={readOnly}
          />
        </FormColumn>
        <CustomerColumnInput sm={4}
          column="contact_timing" customer={customer}
          placeholder="フリー入力"
          readOnly={readOnly}
        />
      </div>
      <div className="row">
        <FormColumnedTextarea xs={12} formLabel="備考"
          value={customer.base_memo || ''}
          onChange={handleChangeBaseMemo}
          readOnly={readOnly}
        />
      </div>
    </>
  );
};

export { CustomerBaseData };

