// 補修の有無と備考を入力するフォーム

import { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import { FormColumn, ButtonRadios, FormColumnedInput } from '../../../react-components';
import { useConvertedSetter, defaultValuePicker } from '../../../lib/common';

const list: ButtonRadioItems = [['false', 'なし'], ['true', 'あり']];


const RawRepairField: FC<{
  value: string | null | undefined;
  onChange: (value: string) => void;
  label: string | null;
}> = ({value, onChange, label}) => {
  const [isActive, setIsActive] = useState(value != null);
  const handleChangeRadio = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue =  e.target.value === 'true';
    // 空文字列をRails側でnullに変換して、DBをNULLで埋める
    if(!newValue) onChange('');
    setIsActive(newValue);
  }, [onChange]);
  const handleChangeText = useConvertedSetter(onChange, defaultValuePicker);

  // Hooks定義完了

  return(
    <div className="row">
      <FormColumn md={3} label={label}>
        <div>
          <ButtonRadios list={list}
            value={isActive + ''}
            onChange={handleChangeRadio}
          />
        </div>
      </FormColumn>
      <FormColumnedInput md={9} label="詳細"
        value={(isActive && value) || ''}
        required={isActive}
        disabled={!isActive}
        onChange={handleChangeText}
      />
    </div>
  );
};

const RepairField = memo(RawRepairField);

export { RepairField };
