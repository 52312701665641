// ジェブ案件のプロセス表示用ラベル

import { FC, memo, ReactElement } from 'react';
import { masterValues } from '../../../lib/common';

const rawProcesses = masterValues('Jeb::Process');
const processesById: {[x: number]: Jeb.Process} = {};
rawProcesses.forEach(item => processesById[item.id] = item);

// 数が限られるので、ノード自体をキャッシュする
const cache: {[x: number]: ReactElement} = {};

const RawJebProcessLabel: FC<{
  id: number | null | undefined;
}> = ({id}) => {
  // Hooks定義完了

  if(!id) return null;
  if(!cache[id]) {
    const item = processesById[id];
    cache[id] = (
      <span className="process-label" style={{backgroundColor: item.color}}>
        {item.name}
      </span>
    );
  }
  return cache[id];
};

const JebProcessLabel = memo(RawJebProcessLabel);

export { JebProcessLabel };
