// content-typeで固定的なサムネイルが生成されるものはそれを返す

import WORD_IMAGE from '../../images/file-icons/word.png';
import EXCEL_IMAGE from '../../images/file-icons/excel.png';
import PDF_IMAGE from '../../images/file-icons/pdf.png';

const TABLE: {[x: string]: string} = {
  'application/msword': WORD_IMAGE,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    WORD_IMAGE,
  'application/vnd.ms-excel': EXCEL_IMAGE,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': EXCEL_IMAGE,
  'application/pdf': PDF_IMAGE
};

const fixedThumbnail = (contentType: string): string | undefined => {
  return TABLE[contentType];
};

export { fixedThumbnail };

