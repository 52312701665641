// テーブルの<col>にスタイルを適用するコンポーネント

import { CSSProperties, FC, memo } from 'react';

const RawStyledTableCols: FC<{
  colStyles: readonly CSSProperties[];
}> = ({colStyles}) => {
  return (
    <colgroup>
      {
        // indexしかない
        colStyles.map((style, index) => (
          <col key={index} style={style} />
        ))
      }
    </colgroup>
  );
};

const StyledTableCols = memo(RawStyledTableCols);

export { StyledTableCols };
